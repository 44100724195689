<template>
  <div>
    <template v-if="loading || loadingZipCode">
      <b-overlay :show="true" rounded spinner-small>
        <b-form-input disabled />
      </b-overlay>
    </template>
    <base-select
      v-else
      v-model="selected"
      :show-place-holder="showPlaceHolder"
      :hide-search="hideSearch"
      :options="options"
      :disabled="disabled"
      :error="isFailValidation"
    ></base-select>
    <div v-show="isFailValidation" class="is-invalid-feedback-message">
      {{ textValidation }}
    </div>
  </div>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import singleSelectionMixin from "@/mixins/single-selection-mixin";
import commonPrefecturesService from "@/services/common/prefectures";

export default {
  name: "ProvinceSelection",
  mixins: [handleValidateMixin, singleSelectionMixin],
  props: {
    loadingZipCode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  created() {
    this.getListPrefectures();
  },

  methods: {
    async getListPrefectures() {
      this.loading = true;
      try {
        let response = await commonPrefecturesService.getList();
        this.options = [...response];
        this.loading = false;
      } catch (err) {
        this.fetchError = true;
      }
    },
  },
};
</script>

<style></style>
