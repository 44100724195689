import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "account-layout",
  name: "EdicAdminAccountLayout",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-account-layout-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/AccountLayout/Layout"
    ),
  redirect: { name: "EdicAdminAccountLayoutDetail" },
  children: [
    {
      path: "", // /admin/account-layout
      name: "EdicAdminAccountLayoutDetail",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-account-layout-detail" */ "@/views/EdicAdmin/Facilities/Manage/AccountLayout/AccountLayout"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
