<template>
  <div
    v-if="trackingProgressInstance"
    class="export__progressing__bar bg-white border rounded p-3"
  >
    <div class="d-block">
      <b>{{ trackingProgressInstance.title }}</b>
    </div>
    <div class="export__progressing__bar-wrapper">
      <div class="export__progressing__bar-progress__bar">
        <span class="percent">{{
          isError ? "100%" : currentProgressPercent
        }}</span>
        <div
          class="export__progressing__bar-progress__bar-progress__fill"
          :style="{
            width: isError ? '100%' : currentProgressPercent,
            background: bgColorProgressBar,
          }"
        ></div>
      </div>
    </div>

    <div class="d-block mt-2">
      <div
        class="d-flex w-100 justify-content-between flex-md-row flex-column g-5"
      >
        <div
          class="m-0 p-0 status__display"
          :style="{
            color: isError ? '#dc3545' : '#c7c7c7',
          }"
        >
          <p class="p-0 m-0 text-secondary">
            {{ $t("import-csv.step") }}: <b>{{ currentStep }}</b>
          </p>
          <div
            v-if="isError || isCompleted || isHavingValidation"
            class="mt-1"
            :class="{
              'is-invalid-feedback-message': isError || isHavingValidation,
              'success__progress font-weight-bold': isCompleted,
            }"
            style="font-size: 16px"
          >
            <p v-if="isError" class="p-0 m-0">{{ errorMsg }}</p>
            <p v-if="isHavingValidation" class="p-0 m-0">
              {{ $t("import-csv.fail_validation_import") }}
            </p>
            <p v-if="isCompleted" class="p-0 m-0">
              {{ $t("import-csv.completed_progress") }}
            </p>
          </div>
        </div>

        <div class="d-flex g-5 justify-content-end">
          <div>
            <div class="d-flex g-5">
              <div
                v-if="isError || isCompleted"
                class="d-flex cursor-pointer"
                @click="remove"
              >
                <Icon
                  v-b-tooltip.bottom="`${$t('export-csv.label_close')}`"
                  icon="ic:round-close"
                  height="24"
                  class="cursor-pointer"
                />
              </div>
            </div>
          </div>
          <Icon
            v-show="isError"
            icon="ic:round-warning"
            height="24"
            color="#dc3545"
          />
          <Icon
            v-show="isCompleted"
            icon="material-symbols:check-circle-rounded"
            height="24"
            color="#28a745"
          />

          <Icon
            v-show="inProgress && !isError"
            icon="svg-spinners:bars-rotate-fade"
            height="24"
            color="#000000"
          />
        </div>
      </div>
    </div>

    <div
      v-if="showBackRoute && backRoutePage && $route.name != backRoutePage"
      class="block mt-2"
    >
      <b-badge variant="primary" style="cursor: pointer" @click="handleClick">{{
        $t("import-csv.go_to_detail")
      }}</b-badge>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportCsvProgressingBar",
};
</script>

<script setup>
import { Icon } from "@iconify/vue2";
import { defineProps, defineEmits } from "vue";
import usImportCsvProgressingBar from "@/hooks/useImportCsvProgressingBar";
import useRouter from "@/hooks/useRouter";

const emits = defineEmits(["go-to-page"]);
const router = useRouter();

const props = defineProps({
  taskName: {
    type: String,
    default: "",
  },
  showBackRoute: {
    type: Boolean,
    default: false,
  },
});
const {
  trackingProgressInstance,
  isError,
  bgColorProgressBar,
  currentStep,
  isCompleted,
  inProgress,
  currentProgressPercent,
  errorMsg,
  remove,
  backRoutePage,
  isHavingValidation,
} = usImportCsvProgressingBar({
  task_name: props.taskName,
});

const handleClick = () => {
  emits("go-to-page");
  router.push({ name: backRoutePage.value });
};
</script>

<style lang="scss" scoped>
.success__progress {
  color: #28a745;
}
.status__display {
  font-size: 14px;
}
.export__progressing__bar {
  display: block;
  &-wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr min-content;
    align-items: center;
  }

  &-progress__bar {
    width: 100%;
    height: 20px;
    background-color: #586066;
    border-radius: 5px;
    position: relative;

    .percent {
      color: #ffffff;
      position: absolute;
      left: 50%;
      right: 50%;
      font-size: 14px;
      font-weight: bold;
      z-index: 1;
      width: auto;
      margin: 0;
      padding: 0;
      text-wrap: nowrap;
    }
    &-progress__fill {
      height: 100%;
      position: absolute;
      left: 0;
      height: 20px;
      border-radius: 5px;
      transition: all 0.8s ease-in-out;
    }
  }
  .download__url {
    display: block;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    a {
      color: inherit;
    }
  }
}
</style>
