<template>
  <div class="menu__item">
    <span class="menu_link text-primary-color" @click="handleRedirect(item)">
      {{ item.name }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ClientMenuItem",
  props: {
    item: Object,
    showBorder: Boolean,
  },
  methods: {
    scrollToId(id) {
      return window.scrollTo({
        top: document.querySelector(id).offsetTop,
        behavior: "smooth",
      });
    },
    handleRedirect(item) {
      const fullPath = this.$route.fullPath;
      if (!(item.url || false) || fullPath == item.url) {
        return;
      }
      if (item.customize_menu_flag) {
        return (window.location.href = item.url);
      }
      this.$router.push(item.url);
    },
  },
};
</script>

<style scoped lang="scss">
.menu__item {
  display: inline-block;
  position: relative;

  label {
    margin: 0;
    display: inline-block;
    font-weight: bold;
    padding: 3px 10px;
    letter-spacing: 0.8px;
  }

  .menu_link {
    margin: 0;
    display: inline-block;
    font-weight: bold;
    padding: 3px 10px;
    letter-spacing: 0.8px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 20%;
    width: 1px;
    border-left: 2px solid var(--primary-color);
    height: 60%;
  }
}
</style>
