// action

// mutations
export const SET_MANAGEMENT_PERMISSION_VIEW = "SET_PERMISSION_VIEW";
export const REFRESH_MANAGEMENT_PERMISSION_VIEW =
  "SET_FRESH_MANAGEMENT_PERMISSION_VIEW";
export const SET_MANAGEMENT_VIEW_FLAG = "SET_MANAGEMENT_VIEW_FLAG";

// getters
export const GET_FACILITY_MENU_ID = "GET_FACILITY_MENU_ID";
export const IS_MANAGEMENT_CAN_BE_VIEWED = "IS_MANAGEMENT_CAN_BE_VIEWED";
export const IS_MANAGEMENT_CAN_BE_EDITABLE = "IS_MANAGEMENT_CAN_BE_EDITABLE";
