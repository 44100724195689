import {
  SET_ADD_PROGRESS_EXPORT_TO_STACK,
  SET_REFRESH_TO_EMPTY_ALL_STACK,
  SET_FILTER_TASK_EXPORT,
  SET_ERROR_FOR_ALL_PROCESSING_EXPORT,
  SET_PROCESS,
} from "./types";

export default {
  // add to stack
  [SET_ADD_PROGRESS_EXPORT_TO_STACK]: (state, progressObject) => {
    state.export_progressing.push(progressObject);
  },
  [SET_REFRESH_TO_EMPTY_ALL_STACK]: (state) => {
    state.export_progressing = [];
  },
  [SET_FILTER_TASK_EXPORT]: (state, task_name) => {
    state.export_progressing = state.export_progressing.filter(
      (item) => item.task_name != task_name
    );
  },
  [SET_ERROR_FOR_ALL_PROCESSING_EXPORT]: (state) => {
    console.log("run here please");
    state.export_progressing = state.export_progressing.map((item) => {
      item.error = true;
      return item;
    });
  },
  [SET_PROCESS]: (state, payload) => {
    const { task_name, response } = payload;
    state.export_progressing = state.export_progressing.map((item) => {
      if (item.task_name == task_name) {
        if (response.url_download && response.is_completed) {
          console.log("start time out");
          setTimeout(() => item.remove(), 5 * 60 * 1000);
        }
        item.handleResponse(response);
      }
      return item;
    });
  },
};
