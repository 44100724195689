/* eslint-disable */
import {
  ref,
  computed,
  toRef,
  onBeforeMount,
  watch,
  provide,
  getCurrentInstance,
  onBeforeUnmount,
  onUnmounted,
} from "vue";
import store from "@/store";
import { GET_PERMISSION_TREE_MENU } from "@/store/modules/AuthManage/types";
import {
  SET_MANAGEMENT_PERMISSION_VIEW,
  REFRESH_MANAGEMENT_PERMISSION_VIEW,
} from "@/store/modules/ManagePermissionScreen/types";
import vueRouter from "@/router";
import { eventName, provideDataType } from "./main";
// import useRecordNotFound from '../useRecordNotFound';
import useRedirectUnauthorized from "@/hooks/useRedirectUnauthorized";

export default function useInitializePermission() {
  // instance app vue
  const instanceService = getCurrentInstance();

  // redirect401
  // const { redirect404 } = useRecordNotFound();
  const { redirect401 } = useRedirectUnauthorized();

  // get route
  const $route = toRef(vueRouter.app, "$route");

  // define data
  const view_flag = ref("");
  const edit_flag = ref(1);
  const menu_id = ref("");
  const id = ref("");
  const management_check_permission = ref(false);
  const currentRouteName = ref("");
  const not_found = ref(false);

  // ---------computed ------------
  const getPermissionTreeMenu = computed(
    () => store.getters[`AuthManage/${GET_PERMISSION_TREE_MENU}`]
  );
  const isManagementCanBeViewed = computed(() => Boolean(view_flag.value));
  const isManagementCanBeEditable = computed(() => Boolean(edit_flag.value));
  const notFound = computed(() => Boolean(not_found.value));
  const isUsingPermission = computed(() => management_check_permission.value);
  const manageIsDisabledEditing = computed(() => {
    if (management_check_permission.value) {
      return isManagementCanBeEditable ? false : true;
    }
    return null;
  });

  const manageCanCreateEditRemove = computed(() => {
    if (management_check_permission.value) {
      return Boolean(edit_flag.value);
    }
    return null;
  });

  // provide
  provide(
    "manageIsDisabledEditing",
    computed(() => manageIsDisabledEditing.value)
  );
  provide(
    "manageCanCreateEditRemove",
    computed(() => manageCanCreateEditRemove.value)
  );
  provide(
    "isUsingPermission",
    computed(() => isUsingPermission.value)
  );

  // --- lifecycle ---
  // created()

  // register listeners event bus
  // instanceService.proxy.$eventBus.$on(eventName.management_not_allow_to_access, notAllowToAccessPage)
  // instanceService.proxy.$eventBus.$on(eventName.management_not_found, pageNotFound)

  watch(
    () => $route.value,
    () => {
      refreshPermissionState();
      initialize();
    }
  );

  // ----------- logic methods ----------------

  const setManagementPermissionView = (...args) =>
    store.commit(
      `ManagePermissionScreen/${SET_MANAGEMENT_PERMISSION_VIEW}`,
      ...args
    );
  const refreshManagementPermissionView = () =>
    store.commit(
      `ManagePermissionScreen/${REFRESH_MANAGEMENT_PERMISSION_VIEW}`
    );

  const returnItemMenu = (listMenu, fullPath) => {
    return listMenu.reduce((findItem, curItem) => {
      if (findItem) return findItem;
      if (
        !/^\/manage\/$/.test(fullPath) &&
        curItem.url != "/manage/" &&
        fullPath.includes(curItem.url)
      ) {
        return curItem;
      }
      if (curItem.children.length)
        return returnItemMenu(curItem.children, fullPath);
    }, null);
  };

  // init data
  const initialize = (url = null) => {
    // get current path
    const path = url || $route.value.path;

    if (getPermissionTreeMenu.value.length) {
      let findItemMenu = returnItemMenu(getPermissionTreeMenu.value, path);
      if (findItemMenu) {
        let {
          menu_id: menuId,
          view_flag: viewFlag,
          edit_flag: editFlag,
          facility_menu_id: mstId,
        } = findItemMenu;
        view_flag.value = viewFlag;
        edit_flag.value = editFlag;
        menu_id.value = menuId;
        id.value = mstId;
        management_check_permission.value = true;

        // save store
        setManagementPermissionView({
          id: mstId,
          menu_id: menuId,
          view_flag: viewFlag,
          edit_flag: editFlag,
        });
      } else {
        not_found.value = true;
        redirect401();
        // redirect404();
        return;
      }
    }

    currentRouteName.value = $route.name;
  };

  // refresh state
  const refreshPermissionState = () => {
    edit_flag.value = 1;
    view_flag.value = 1;
    menu_id.value = "";
    id.value = "";
    not_found.value = "";
    management_check_permission.value = false;

    // refresh store
    refreshManagementPermissionView();
  };

  const notAllowToAccessPage = () => {
    view_flag.value = false;
  };

  const pageNotFound = () => {
    not_found.value = true;
  };

  // initialize event
  instanceService.proxy.$eventBus.$on(
    eventName.management_not_allow_to_access,
    notAllowToAccessPage
  );
  instanceService.proxy.$eventBus.$on(
    eventName.management_not_found,
    pageNotFound
  );

  initialize();

  onUnmounted(() => {
    refreshManagementPermissionView();
  });

  return {
    isManagementCanBeViewed,
    isManagementCanBeEditable,
    notFound,
    notAllowToAccessPage,
    pageNotFound,
  };
}
