<template>
  <div>
    <li
      v-if="getListManageExportingTask.length"
      class="nav-item cursor-pointer"
      @click="$modal.show('tracking__exporting__task')"
    >
      <div class="d-flex g-5 align-items-center">
        <Icon
          v-show="!areAllProgressesDone"
          icon="svg-spinners:bars-rotate-fade"
          height="24"
        />
        <Icon
          v-show="areAllProgressesDone"
          icon="material-symbols:check-circle-rounded"
          color="#28a745"
          height="24"
        />
        <span>{{ $t("export-csv.label_export_csv_modal") }}</span>
      </div>
    </li>

    <base-modal-dialog
      name="tracking__exporting__task"
      scrollable
      height="auto"
      width="700"
      click-to-close
    >
      <b-card class="m-0">
        <template #header>
          <h4 class="m-0 font-weight-bold">
            {{ $t("export-csv.label_export_csv_modal") }}
          </h4>
        </template>
        <b-card-text class="mt-2">
          <div
            v-for="(item, index) in getListManageExportingTask"
            :key="item.task_name"
            class="progress__item__display"
          >
            <span class="progress__item__order">{{ index + 1 }}</span>
            <div class="progress__item__space">
              <div></div>
            </div>
            <export-progressing-bar
              class="w-100 border-0"
              :task-name="item.task_name"
            ></export-progressing-bar>
          </div>
        </b-card-text>
      </b-card>
    </base-modal-dialog>
  </div>
</template>

<script setup>
import { computed, watch } from "vue";
import Store from "@/store";
import { Icon } from "@iconify/vue2";
import {
  GET_LIST_PROGRESS_MANAGEMENT_STACK,
  GET_ALL_PROGRESS_DONE,
} from "@/store/modules/ExportProgressing/types";
import ExportProgressingBar from "@/components/ExportProgressing/ExportProgressingBar.vue";
import { VueInstanceProxy } from "@/hooks/useVueInstance";

const instanceVue = VueInstanceProxy();

const getListManageExportingTask = computed(
  () => Store.getters[`ExportProgressing/${GET_LIST_PROGRESS_MANAGEMENT_STACK}`]
);

const areAllProgressesDone = computed(
  () => Store.getters[`ExportProgressing/${GET_ALL_PROGRESS_DONE}`]
);

watch(
  () => getListManageExportingTask.value,
  () => {
    if (!getListManageExportingTask.value.length) {
      instanceVue.$modal.hide("tracking__exporting__task");
    }
  }
);
</script>

<style lang="scss" scoped>
.progress__item__display {
  display: grid;
  grid-template-columns: min-content 40px 1fr;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 8px;
  }
}

.progress__item__order {
  border-radius: 50%;
  color: white;
  font-weight: bold;
  height: 30px;
  width: 30px;
  background: #50bed7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress__item__space {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  div {
    width: 100%;
    height: 4px;
    background-color: #50bed7;
  }
}
</style>
