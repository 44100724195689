import instanceService from "./instanceService";

export const bffServiceInstance = new instanceService(
  process.env.VUE_APP_BFF_HTTP,
  "Manage"
);
export const bffServiceInstanceManage = new instanceService(
  process.env.VUE_APP_BFF_HTTP,
  "Manage"
);
export const bffServiceInstanceClient = new instanceService(
  process.env.VUE_APP_BFF_HTTP,
  "Member"
);
export const bffServiceInstanceAdmin = new instanceService(
  process.env.VUE_APP_BFF_HTTP,
  "Admin"
);
export const requestBinInstance = new instanceService(
  process.env.VUE_APP_BIN_REQ
);
export const iconifyService = new instanceService("https://api.iconify.design");
