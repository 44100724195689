import {
  SET_PROCESS_EXPORT_CSV_FILE,
  SET_REFRESH_IMPORT_CSV_FILE,
} from "./types";

export default {
  [SET_PROCESS_EXPORT_CSV_FILE]: (state, payload) => {
    state.exportCsv = {
      ...state.exportCsv,
      ...payload,
    };
  },
  [SET_REFRESH_IMPORT_CSV_FILE]: (state) => {
    state.importCsv = {
      file: null,
      loading: false,
      csv_content: {
        data: [],
        meta: {},
        errors: [],
      },
      validation: {},
      validation_source: null,
      error: false,
      success: false,
      total_insert: 0,
      total_updated: 0,
    };
    return state;
  },
};
