import Storage from "vue-ls";

const options = {
  namespace: "",
  name: "ls",
  storage: "local",
};

export const CachingComponent = {
  install(Vue) {
    Vue.use(Storage, options);

    // get cache
    Vue.prototype.$getCache = function (name = "") {
      return this.$ls.get(name);
    };

    // set cache
    Vue.prototype.$setCache = function (...args) {
      // minutes
      this.$ls.set(...args);
    };
  },
};

const { ls } = Storage.useStorage(options);

export const caching = {
  getCache(name) {
    return ls.get(name);
  },
  setCache(...args) {
    ls.set(...args);
  },
};
