<template>
  <div>
    <template v-if="loading || loadingZipCode">
      <b-overlay :show="true" rounded spinner-small>
        <b-form-input disabled />
      </b-overlay>
    </template>
    <template v-else>
      <base-select
        v-model="selected"
        :show-place-holder="showPlaceHolder"
        :hide-search="prefCode ? hideSearch : true"
        :options="options"
        :disabled="disabled"
        :error="isFailValidation"
      ></base-select>
    </template>
    <div v-show="isFailValidation" class="is-invalid-feedback-message">
      {{ textValidation }}
    </div>
  </div>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import singleSelectionMixin from "@/mixins/single-selection-mixin";
import commonCityService from "@/services/common/city";

export default {
  name: "CitySelection",
  mixins: [handleValidateMixin, singleSelectionMixin],
  props: {
    prefCode: {
      type: [String, Number],
      default: "",
    },
    loadingZipCode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  watch: {
    prefCode() {
      if (this.prefCode) {
        this.getListCity();
      } else {
        this.options = [];
        this.$emit("input", "");
      }
    },
  },
  created() {
    if (this.prefCode) {
      this.getListCity();
    }
  },
  methods: {
    async getListCity() {
      this.loading = true;
      try {
        let response = await commonCityService.getList({
          pref_code: this.prefCode,
        });
        this.options = [...response];
        this.loading = false;
        // select option follow text option
        if (this.textRecord) {
          let find = this.options.find((item) => item.text === this.textRecord);
          this.$emit("input", find?.id || "");
          return;
        }
        let find = this.options.find((item) => item.id == this.selected);
        this.$emit("input", find?.id || "");
      } catch (err) {
        this.loading = false;
        this.fetchError = true;
      }
    },
  },
};
</script>

<style></style>
