import {
  GET_LIST_ORGANIZATION_ACCOUNT,
  GET_ORGANIZATION_INSTANCE,
  GET_IS_STORE_ORGANIZATION_NEXT_LOGIN,
  GET_ID_ORGANIZATION_NEXT_LOGIN,
  GET_ORGANIZATION_NAME,
} from "./types";

export default {
  [GET_LIST_ORGANIZATION_ACCOUNT](state) {
    return state.list_oragnizations_access;
  },

  [GET_ORGANIZATION_INSTANCE]: (state) => state.organization_info,

  [GET_IS_STORE_ORGANIZATION_NEXT_LOGIN]: (state) => {
    if (
      !state.save_for_next_login.save ||
      !state.save_for_next_login.id_organization
    ) {
      return false;
    } else {
      return true;
    }
  },

  [GET_ID_ORGANIZATION_NEXT_LOGIN]: (state) => {
    return state.save_for_next_login.id_organization;
  },

  [GET_ORGANIZATION_NAME]: (state) => {
    return state.organization_info?.name;
  },
};
