<template>
  <div>
    <template v-if="loading">
      <b-overlay :show="loading" rounded spinner-small>
        <b-form-input disabled />
      </b-overlay>
    </template>
    <base-select
      v-else
      v-model="selected"
      :show-place-holder="showPlaceHolder"
      :hide-search="hideSearch"
      :options="options"
      :disabled="disabled"
      :error="isFailValidation"
      :error-fetch="fetchError"
    ></base-select>
    <div
      v-show="isFailValidation && showTextValidation"
      class="is-invalid-feedback-message"
    >
      {{ textValidation }}
    </div>
  </div>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import singleSelectionMixin from "@/mixins/single-selection-mixin";
import { formatConstantToArray } from "@/helpers/common";

export default {
  name: "BaseSingleSelection",
  mixins: [handleValidateMixin, singleSelectionMixin],
  props: {
    usingFetchApi: {
      type: Boolean,
      default: false,
    },
    serviceApi: {
      type: Function,
      default: null,
    },
    params: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showTextValidation: {
      type: Boolean,
      default: true,
    },
    loadFromConstant: {
      type: Boolean,
      default: false,
    },
    constant: {
      type: [Object, Array],
      default: null,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  created() {
    this.execute();
  },
  methods: {
    execute() {
      if (this.usingFetchApi) {
        this.loadApi();
      } else {
        if (this.loadFromConstant) {
          this.options = formatConstantToArray({ ...this.constant });
        } else {
          this.options = this.optionsInput;
        }
      }
    },
    async loadApi() {
      this.loading = true;
      try {
        let response = await this.serviceApi(...this.params);
        this.options = response;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.fetchError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
