<template>
  <div class="d-block">
    <slot :disabled-input="disabledInput"></slot>
  </div>
</template>

<script>
export default {
  name: "BaseManagePermissionEditView",

  computed: {
    disabledInput() {
      return !this.$isCanEdit;
    },
  },
};
</script>
