// actions
export const ACT_CLIENT_LOGIN_ACCOUNT = "ACT_CLIENT_LOGIN_ACCOUNT";
export const ACT_CLIENT_LOGOUT = "ACT_CLIENT_LOGOUT";
export const ACT_CLIENT_GET_ACCOUNT_INFO = "ACT_CLIENT_GET_ACCOUNT_INFO";
export const ACT_FORCE_FRESH_AUTH = "ACT_FORCE_FRESH_AUTH";
export const ACT_SYNC_CLIENT_AUTH_INFO = "ACT_SYNC_CLIENT_AUTH_INFO";
export const ACT_FORCE_GET_SYNC_CLIENT_INFO = "ACT_FORCE_GET_SYNC_CLIENT_INFO";

// mutations
export const SET_CLIENT_USER_NAME = "SET_CLIENT_USER_NAME";
export const SET_CLIENT_TOKEN = "SET_CLIENT_TOKEN";
export const SET_CLIENT_IS_AUTH = "SET_CLIENT_IS_AUTH";
export const SET_CLIENT_REFRESH_AUTH = "SET_CLIENT_REFRESH_AUTH";
export const SET_CLIENT_ACCOUNT_INFO = "SET_CLIENT_ACCOUNT_INFO";
export const SET_CLIENT_EXPIRED_AT = "SET_CLIENT_EXPIRED_AT";
export const SET_CLIENT_PERMISSION_MENU = "SET_CLIENT_PERMISSION_MENU";
export const SET_CLIENT_LOGIN_ORGAIZATION = "SET_CLIENT_LOGIN_ORGAIZATION";
export const SET_CLIENT_MEMBER_TYPES = "SET_CLIENT_MEMBER_TYPES";
export const SET_CLIENT_MENU_HEADER = "SET_CLIENT_MENU_HEADER";
export const SET_CLIENT_MEMBER_CARD_DESIGNS = "SET_CLIENT_MEMBER_CARD_DESIGNS";
export const SET_CLIENT_MEMBER_TYPE_RENEWABLE_FLAG =
  "SET_CLIENT_MEMBER_TYPE_RENEWABLE_FLAG";

// getters
export const GET_CLIENT_USER_NAME = "GET_CLIENT_USER_NAME";
export const GET_CLIENT_AUTH_TOKEN = "GET_CLIENT_AUTH_TOKEN";
export const GET_CLIENT_ACCOUNT_IS_AUTH = "GET_CLIENT_ACCOUNT_IS_AUTH";
export const GET_CLIENT_ACCOUNT_ID = "GET_CLIENT_ACCOUNT_ID";
export const GET_CLIENT_PERMISSION_MENU = "GET_CLIENT_PERMISSION_MENU";
export const GET_CLIENT_ACCOUNT_CODE = "GET_CLIENT_ACCOUNT_CODE";
export const GET_CLIENT_ACCOUNT_INFO = "GET_CLIENT_ACCOUNT_INFO";
export const GET_CLIENT_LOGIN_ORGANIZATION = "GET_CLIENT_LOGIN_ORGANIZATION";
export const GET_CLIENT_MEMBER_TYPES = "GET_CLIENT_MEMBER_TYPES";
export const GET_CLIENT_MEMBER_TYPE_INFO = "GET_CLIENT_MEMBER_TYPE_INFO";
export const GET_CLIENT_MENU_HEADER = "GET_CLIENT_MENU_HEADER";
export const GET_CLIENT_MEMBER_CARD_DESIGN = "GET_CLIENT_MEMBER_CARD_DESIGN";
export const GET_CLIENT_LIST_PERMISSION_MENU =
  "GET_CLIENT_LIST_PERMISSION_MENU";
export const GET_CLIENT_ALL_PERMISSION_MENU = "GET_CLIENT_ALL_PERMISSION_MENU";
export const GET_PROFILE_INCOMPLETE_FLAG = "GET_PROFILE_INCOMPLETE_FLAG";
export const GET_PROCESS_INFO_IS_DONE = "GET_PROCESS_INFO_IS_DONE";
export const GET_MEMBER_TYPE_RENEWABLE_FLAG = "GET_MEMBER_TYPE_RENEWABLE_FLAG";
export const GET_CLASS_TYPE = "GET_CLASS_TYPE";
export const GET_CLIENT_CORPORATE_CODE = "GET_CLIENT_CORPORATE_CODE";
