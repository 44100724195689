import i18n from "@/plugins/i18n";

export default {
  install(Vue) {
    // error system alert
    Vue.mixin({
      methods: {
        $errorCanNotRegister(message = null) {
          Vue.prototype.$eventBus.$emit("update-alert-system", {
            error: true,
            message: message ? message : i18n.t("event.can_not_register"),
          });
        },
        $errorSystem(message = null) {
          Vue.prototype.$eventBus.$emit("update-alert-system", {
            error: true,
            message: message ? message : i18n.t("panel.label_system_error"),
          });
        },
        $errorValidation(message = null) {
          Vue.prototype.$eventBus.$emit("update-alert-system", {
            error: true,
            message: message || i18n.t("panel.label_failed_validation"),
          });
        },
        $alertSuccess(message) {
          Vue.prototype.$eventBus.$emit("update-alert-system", {
            error: false,
            message: message,
          });
        },
      },
    });
  },
};
