import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "apply-conditions",
  name: "EdicAdminFacilityApplyConditionManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-master-apply-condition-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/ApplyCondition/LayoutView"
    ),
  redirect: { name: "EdicAdminFacilityApplyConditionSearch" },
  children: [
    {
      path: "", // /admin/apply-conditions
      name: "EdicAdminFacilityApplyConditionSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-master-apply-condition-searching-view" */ "@/views/EdicAdmin/Facilities/Manage/ApplyCondition/Search.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create", // /admin/apply-conditions/create
      name: "EdicAdminFacilityApplyConditionCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-master-apply-condition-create-view" */ "@/views/EdicAdmin/Facilities/Manage/ApplyCondition/Create.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id", //  /admin/apply-conditions/edit/1
      name: "EdicAdminFacilityApplyConditionEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-master-apply-condition-edit-view" */ "@/views/EdicAdmin/Facilities/Manage/ApplyCondition/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
