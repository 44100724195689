import {
  GET_ACCOUNT_INFO,
  GET_AUTH_TOKEN,
  GET_ACCOUNT_IS_AUTH,
  GET_ACCOUNT_FULLNAME,
} from "./types";
import moment from "moment";

export default {
  [GET_AUTH_TOKEN](state) {
    return state.token;
  },
  [GET_ACCOUNT_IS_AUTH]: (state) => {
    let statusLogin = state.accountLogin.isAuth,
      token = state.token,
      validExpiredAt = true;

    let pointNowTime = moment(),
      expiredAt = moment(parseInt(state.expired_at)).subtract(5, "minutes");

    if (!state.expired_at || pointNowTime.isSameOrAfter(expiredAt)) {
      validExpiredAt = false;
    }

    if (token && statusLogin == "success" && validExpiredAt) {
      return true;
    }
    return false;
  },
  [GET_ACCOUNT_INFO](state) {
    return state.accountLogin.info;
  },
  [GET_ACCOUNT_FULLNAME]: (state) => {
    if (state.accountLogin.info) {
      return (
        state.accountLogin?.info?.last_name +
        " " +
        state.accountLogin?.info?.first_name
      );
    }
    return "";
  },
};
