import vueRouter from "@/router";

export default function useRedirectUnauthorized() {
  const $router = vueRouter.app.$router;
  const $route = vueRouter.app.$route;

  function redirect401() {
    let routePath = $route.path;

    if (/^\/admin/.test(routePath)) {
      $router.replace({
        name: "AdminPageUnauthorized",
      });
      return;
    } else if (/^\/manage/.test(routePath)) {
      $router.replace({
        name: "MananagePageUnauthorized",
      });
      return;
    } else {
      $router.replace({
        name: "ClientPageUnauthorized",
      });
    }
  }
  return {
    redirect401,
  };
}
