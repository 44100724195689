import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "content-cms",
  name: "EdicAdminFacilityContentCmsManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-content-cms-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/ContentCms/Layout"
    ),
  redirect: { name: "EdicAdminFacilityContentCmsSearch" },
  children: [
    {
      path: "", // /admin/plan
      name: "EdicAdminFacilityContentCmsSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-content-cms-search" */ "@/views/EdicAdmin/Facilities/Manage/ContentCms/Search"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create", // /admin/plan/create
      name: "EdicAdminFacilityContentCmsCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-content-cms-create" */ "@/views/EdicAdmin/Facilities/Manage/ContentCms/Create"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id", // /admin/plan/edit/1
      name: "EdicAdminFacilityContentCmsEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-content-cms-edit" */ "@/views/EdicAdmin/Facilities/Manage/ContentCms/Edit"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
