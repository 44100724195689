import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  detail: {
    basic_info: {},
    extend_info: {},
  },
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
