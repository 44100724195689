<template>
  <div class="d-flex flex-row">
    <span class="pr-3 common__label__input">〒</span>
    <div class="w-50 pr-3">
      <b-input
        v-model="data"
        v-mask="'###-####'"
        maxlength="8"
        :disabled="disabled || loading"
        :class="{ 'is-invalid': isFailValidation }"
        type="tel"
      ></b-input>
      <span
        v-error-input-text="{ validation: validationResult, keyName: keyName }"
      ></span>
    </div>
    <b-overlay
      :show="loading"
      rounded
      opacity="0.6"
      spinner-small
      class="d-inline-block"
    >
      <b-button
        :disabled="disabled"
        :class="btnBgColor"
        @click="evtGetZipCode"
        >{{ $t("organization.address_auto_fill") }}</b-button
      >
    </b-overlay>
  </div>
</template>
<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";

export default {
  name: "ZipcodeInput",
  mixins: [handleValidateMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      description: "Input value",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnBgColor: {
      type: String,
      default: "background-secondary-color",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    async evtGetZipCode() {
      this.$emit("zipcode-click", true);
    },
  },
};
</script>
