<template>
  <b-row
    class="core__input__group__wrapper"
    style="flex-direction: row"
    :class="layoutBaseInputGroup"
  >
    <b-col
      v-if="showLabel"
      :xl="gridTitleInput.xl"
      :lg="gridTitleInput.lg"
      :md="gridTitleInput.md"
      :sm="gridTitleInput.sm"
      class="label__wrapper"
      :cols="gridTitleInput.sx"
    >
      <slot name="label">
        <label
          :class="[
            'm-0 core__input__group__wrapper-title__input',
            addClassLabel,
          ]"
        >
          <div>
            <span
              :class="{ 'font-weight-bold': isLabelBold }"
              v-html="title"
            ></span>
            <span
              v-if="required"
              class="text-danger text-nowrap pl-1 font-weight-bold"
              >{{ textRequired }}</span
            >
          </div>
          <div v-if="subTitle" class="font-weight-normal">{{ subTitle }}</div>
        </label>
      </slot>
    </b-col>
    <b-col
      :xl="gridInputContent.xl"
      :lg="gridInputContent.lg"
      :md="gridInputContent.md"
      :sm="gridInputContent.sm"
      class="wrapper__input"
      :cols="gridInputContent.sx"
      :class="classInput"
    >
      <div class="form__item__control__input">
        <slot name="input" :input-fail-validate="isInputFailValidation">
          <div :class="addClassCustom" class="d-block w-100">
            <!-- text-input -->
            <b-form-input
              v-if="input"
              v-model="data"
              v-show-password="{
                inputType: propsInput.type,
                showPassword: showPassword,
              }"
              v-mask="`${conditionMask}`"
              :class="{
                'is-invalid':
                  validationResult && validationResult.hasOwnProperty(keyName),
              }"
              trim
              v-bind="propsInput"
              :placeholder="placeholder || $t('panel.label_place_holder')"
              v-on="$listeners"
            />
            <div
              v-if="propsInput.type == 'password'"
              ref="ShowPasswordIcon"
              class="eye-icon_parent"
              @click="showPassword = !showPassword"
            >
              <!-- <i class="fas fa-eye eye-icon_child"></i> -->
              <span
                class="iconify eye-icon_child"
                data-icon="material-symbols:visibility-rounded"
                data-inline="false"
                style="color: #888888"
              ></span>
            </div>
          </div>
        </slot>
      </div>

      <div
        v-if="showTextValidation"
        v-error-input-text="{ validation: validationResult, keyName: keyName }"
      ></div>
      <div v-if="listKeyName && validationResult" class="text-danger">
        <template v-for="(itemKeyName, index) in listKeyName">
          <span
            v-if="
              Object.prototype.hasOwnProperty.call(
                validationResult,
                itemKeyName
              )
            "
            :key="index"
            class="is-invalid-feedback-message"
          >
            {{ validationResult[itemKeyName] }}
          </span>
        </template>
      </div>
      <div>
        <slot name="hideText">
          <span
            v-if="hintText && showHintText"
            class="core__input__group__wrapper-hint__text"
            v-html="hintText"
          ></span>
        </slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import isEmpty from "lodash/isEmpty";
// import { Icon } from '@iconify/vue2';

export default {
  name: "BaseInputGroup",
  mixins: [handleValidateMixin],
  props: {
    classTitle: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      type: [String, Number],
    },
    // add * behind label of required input
    required: {
      type: Boolean,
      default: false,
    },
    // title of input
    title: {
      type: String,
      default: "",
    },
    // if want show text-input
    input: {
      type: Boolean,
      default: true,
    },
    // prop of input such as maxlength, required, ...
    propsInput: {
      type: Object,
      default: function () {
        return {};
      },
    },
    hintText: {
      type: String,
      default: "",
    },
    labelTop: {
      type: Boolean,
      default: false,
    },
    labelMiddleCenter: {
      type: Boolean,
      default: false,
    },
    addClassCustom: {
      type: String,
      default: "",
    },
    addClassFormInput: {
      type: String,
      default: "",
    },
    textRequired: {
      type: String,
      default: "*",
    },
    subTitle: {
      type: String,
      default: "",
    },
    conditionMask: {
      type: String,
      default: "",
    },
    listKeyName: {
      type: Array,
      default: function () {
        return [];
      },
    },
    gridTitle: {
      type: Object,
      default: function () {
        return {};
      },
    },
    gridInput: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    classCustomPosition: {
      type: String,
      default: "justify-content-center",
    },
    classCustomPadding: {
      type: Boolean,
      default: false,
    },
    disabledInput: Boolean,
    placeholder: {
      type: String,
      default: "",
    },
    addClassLabel: {
      type: String,
      default: "",
    },
    showTextValidation: {
      type: Boolean,
      default: true,
    },
    showHintText: {
      type: Boolean,
      default: true,
    },
    isLabelBold: {
      type: Boolean,
      default: true,
    },
    classInput: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    isInputFailValidation() {
      return !isEmpty(this.validationResult) && this.isFailValidation;
    },
    gridTitleInput() {
      let { xl = 3, lg = 3, md = 4, sm = 12, sx = 12 } = this.gridTitle;
      return { xl, lg, md, sm, sx };
    },
    gridInputContent() {
      let { xl = 6, lg = 6, md = 7, sm = 12, sx = 12 } = this.gridInput;
      return { xl, lg, md, sm, sx };
    },
    customTitleClass() {
      let defaultClass = ["d-flex"];
      return [...defaultClass, ...this.classTitle];
    },
    layoutBaseInputGroup() {
      let centerLabelAndInput = "";
      if (!this.labelTop) {
        centerLabelAndInput = this.labelMiddleCenter
          ? "align-items-center"
          : "";
      }
      return `${this.classCustomPosition} ${centerLabelAndInput}`;
    },
  },
  watch: {
    showPassword() {
      if (this.showPassword) {
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.add("fa-eye-slash");
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.remove("fa-eye");
      } else {
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.add("fa-eye");
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.remove("fa-eye-slash");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.core__input__group__wrapper {
  box-sizing: border-box;
  line-height: 1.5;
  vertical-align: top;
  flex-flow: row wrap;

  .label__wrapper {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    // white-space: nowrap;
    text-align: left;
    vertical-align: middle;
  }

  &-title__input {
    margin: 0;
    font-size: 16px;
    color: #303030;
    min-height: 40px;
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  .wrapper__input {
    display: flex;
    flex-direction: column;
  }

  .form__item__control__input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 40px;
    div:first-child {
      width: 100%;
    }
  }

  &-hint__text {
    color: #8993a4;
    font-size: 14px;
  }

  margin-bottom: 5px;

  @media screen and (max-width: 900px) {
    margin-bottom: 10px;
  }
}

.eye-icon_parent {
  position: absolute;
  right: 10px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
