import { onBeforeUnmount } from "vue";

export default function ({
  elementWatcher,
  callback,
  callbackOut = () => {},
  once = true,
  option = {
    threshold: 1.0,
    rootMargin: "0px",
    root: null,
  },
}) {
  const observeInstance = new IntersectionObserver(([entry]) => {
    const isIntersecting = entry.isIntersecting || false;
    if (isIntersecting) {
      callback(elementWatcher);
      once && observeInstance.unobserve(elementWatcher);
    } else {
      callbackOut(elementWatcher);
    }
  }, option);

  observeInstance.observe(elementWatcher);

  onBeforeUnmount(() => {
    observeInstance.disconnect();
  });
  return observeInstance;
}
