import Store from "@/store";
import { ACT_FORCE_FRESH_AUTH } from "@/store/modules/AuthManage/types";
import Router from "@/router";
import { checkAuthManageLifeTime } from "@/helpers/authenticate";

export default function authManage({ to, next }) {
  let isAuthManage = checkAuthManageLifeTime();
  // not authenticated
  if (!isAuthManage) {
    let { path } = to;
    // remove store
    Store.dispatch(`AuthManage/${ACT_FORCE_FRESH_AUTH}`);
    setTimeout(() => {
      Router.push({ name: "ManageLogin", query: { redirect: path } });
    }, 500);
  } else {
    // authenticate is TRUE
    if (to.name === "ManageLogin") {
      //
      return Router.push({ name: "ManageHome" });
    }
    return next();
  }
}
