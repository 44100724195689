import i18n from "@/plugins/i18n";

export const adminMenu = [
  // {
  //   to: { name: 'Overview' },
  //   icon: 'nc-icon nc-chart-pie-35',
  //   title: 'dashboard'
  // },
  // {
  //   to: { name: 'UserProfile'},
  //   icon: 'nc-icon nc-circle-09',
  //   title: 'user profile'
  // },
  {
    to: { name: "PlanManage" },
    icon: "nc-icon nc-notes",
    title: i18n.t("plan.title_page"),
  },
  {
    to: { name: "ContractPlanManage" },
    icon: "nc-icon nc-single-copy-04",
    title: i18n.t("contract-plan.title_page"),
  },
  {
    to: { name: "MasterMenuManage" },
    icon: "nc-icon nc-bullet-list-67",
    title: i18n.t("master-menu.title_page"),
  },
  {
    to: { name: "PaymentAgencyManage" },
    icon: "nc-icon nc-money-coins",
    title: i18n.t("payment-agency.title_page"),
  },
  {
    to: { name: "FacilitiesManage" },
    icon: "nc-icon nc-bank",
    title: i18n.t("facility.title_page"),
  },
  {
    to: { name: "AccountCmsGroupManage" },
    icon: "nc-icon nc-settings-gear-64",
    title: i18n.t("account-cms-group.title_page"),
  },
  {
    to: { name: "EventCmsManage" },
    icon: "nc-icon nc-notification-70",
    title: i18n.t("event-cms.title_page"),
  },
  {
    to: { name: "MemberTypeManage" },
    icon: "nc-icon nc-single-02",
    title: i18n.t("member-type.title_page"),
  },
  {
    to: { name: "ContentCmsManage" },
    icon: "nc-icon nc-paper-2",
    title: i18n.t("content-cms.title_page"),
  },
];

export const manageMenu = [
  {
    to: { name: "OrganizationManage" },
    icon: "nc-icon nc-istanbul",
    title: i18n.t("organization.title_page"),
  },
  {
    to: { name: "AccountManage" },
    icon: "nc-icon nc-circle-09",
    title: i18n.t("account.title_page"),
  },
  {
    to: { name: "MemberManage" },
    icon: "nc-icon nc-single-02",
    title: i18n.t("member.title_page"),
  },
  {
    to: { name: "MailTemplateManage" },
    icon: "nc-icon nc-email-83",
    title: i18n.t("mail-template.title_page"),
  },
  {
    to: { name: "EventManage" },
    icon: "nc-icon nc-paper-2",
    title: i18n.t("manage-event.title_page"),
  },
];

export const edicAdminMenu = [
  {
    to: { name: "EdicFacilitiesManage" },
    icon: "nc-icon nc-bank",
    title: i18n.t("facility.title_page"),
  },
  {
    to: { name: "EdicAdminMasterMenuManage" },
    icon: "nc-icon nc-bullet-list-67",
    title: i18n.t("master-menu.title_page"),
  },
  {
    to: { name: "EdicAdminPaymentAgencyManage" },
    icon: "nc-icon nc-money-coins",
    title: i18n.t("payment-agency.title_page"),
  },
  {
    to: { name: "EdicPlanManage" },
    icon: "nc-icon nc-notes",
    title: i18n.t("plan.title_page"),
    children: [
      {
        to: { name: "EdicContractPlanManage" },
        icon: "nc-icon nc-single-copy-04",
        title: i18n.t("contract-plan.title_page"),
      },
    ],
  },

  {
    to: { name: "EdicContractPlanManage" },
    icon: "nc-icon nc-single-copy-04",
    title: i18n.t("contract-plan.title_page"),
  },
];

export const facilityMenu = [
  {
    to: { name: "EdicAdminFacilityProfile" },
    icon: "nc-icon nc-bank",
    title: i18n.t("facility.title_page"),
  },
  {
    to: { name: "EdicAdminFacilityAccountCmsManage" },
    icon: "nc-icon nc-credit-card",
    title: i18n.t("facility.label_account_cms"),
  },
  {
    to: { name: "EdicAdminFacilityCardBuilderManage" },
    icon: "nc-icon nc-badge",
    title: i18n.t("facility.card_builder_title"),
  },
  {
    to: { name: "EdicAdminFacilityMemberTypeSearch" },
    icon: "nc-icon nc-single-02",
    title: i18n.t("member-type.title_page"),
  },
  {
    to: { name: "EdicAdminFacilityEventCmsManage" },
    icon: "nc-icon nc-notification-70",
    title: i18n.t("event-cms.title_page"),
  },
  {
    to: { name: "EdicAdminFacilityContentCmsManage" },
    icon: "nc-icon nc-paper-2",
    title: i18n.t("content-cms.title_page"),
  },
  {
    to: { name: "EdicAdminFacilityAccountCmsGroupManage" },
    icon: "nc-icon nc-settings-gear-64",
    title: i18n.t("account-cms-group.title_page"),
  },
  {
    to: { name: "EdicAdminFacilityFunctionMenuManage" },
    icon: "nc-icon nc-bullet-list-67",
    title: i18n.t("functional-menu.title_page"),
  },
  {
    to: { name: "StorageManageCreateEdit" },
    icon: "nc-icon nc-cloud-upload-94",
    title: i18n.t("storage-manage.title"),
  },
  {
    to: { name: "EdicAdminFacilityPaymentMethodManage" },
    icon: "nc-icon nc-backpack",
    title: i18n.t("payment-method.title_page"),
  },
  {
    to: { name: "EdicAdminAccountLayoutDetail" },
    icon: "nc-icon nc-grid-45",
    title: i18n.t("facility.account_layout_title"),
  },
  {
    to: { name: "EdicAdminFacilityApplyConditionManage" },
    icon: "nc-icon nc-preferences-circle-rotate",
    title: i18n.t("apply-condition.title_page"),
  },
  {
    to: { name: "EdicAdminFacilityPaymentPlanFeeConditionManage" },
    icon: "nc-icon nc-vector",
    title: i18n.t("payment-plan-fee-condition.title_page"),
  },
  {
    to: { name: "EdicAdminFacilityPaymentAdjustmentConditionManage" },
    icon: "nc-icon nc-ruler-pencil",
    title: i18n.t("payment-adjustment-condition.title_page"),
  },
  {
    to: { name: "EdicAdminFacilityResourceAccessConditionManage" },
    icon: "nc-icon nc-cctv",
    title: i18n.t("resource-access-condition.title_page"),
  },
];
