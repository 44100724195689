import {
  GET_CLIENT_USER_NAME,
  GET_CLIENT_AUTH_TOKEN,
  GET_CLIENT_ACCOUNT_IS_AUTH,
  GET_CLIENT_ACCOUNT_ID,
  GET_CLIENT_PERMISSION_MENU,
  GET_CLIENT_ACCOUNT_CODE,
  GET_CLIENT_ACCOUNT_INFO,
  GET_CLIENT_LOGIN_ORGANIZATION,
  GET_CLIENT_MEMBER_TYPES,
  GET_CLIENT_MEMBER_TYPE_INFO,
  GET_CLIENT_MENU_HEADER,
  GET_CLIENT_MEMBER_CARD_DESIGN,
  GET_CLIENT_LIST_PERMISSION_MENU,
  GET_CLIENT_ALL_PERMISSION_MENU,
  GET_PROFILE_INCOMPLETE_FLAG,
  GET_PROCESS_INFO_IS_DONE,
  GET_MEMBER_TYPE_RENEWABLE_FLAG,
  GET_CLASS_TYPE,
  GET_CLIENT_CORPORATE_CODE,
} from "./types";
import moment from "moment";
import _ from "lodash";

export default {
  [GET_CLIENT_USER_NAME](state) {
    return state.clientProfile.name;
  },
  [GET_CLIENT_AUTH_TOKEN](state) {
    return state.clientToken;
  },
  [GET_CLIENT_ACCOUNT_IS_AUTH]: (state) => {
    let statusLogin = state.clientAccountLogin.isAuth,
      token = state.clientToken,
      validExpiredAt = true;
    let pointNowTime = moment(),
      expiredAt = moment(parseInt(state.clientExpiredAt)).subtract(
        5,
        "minutes"
      );

    if (!state.clientExpiredAt || pointNowTime.isSameOrAfter(expiredAt)) {
      validExpiredAt = false;
    }

    if (token && statusLogin == "success" && validExpiredAt) {
      return true;
    }
    return false;
  },
  [GET_CLIENT_ACCOUNT_ID]: (state) => {
    if (!_.isEmpty(state.clientAccountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.clientAccountLogin.info,
        "account_id"
      )
        ? state.clientAccountLogin.info.account_id
        : "";
    }
    return "";
  },
  [GET_CLIENT_PERMISSION_MENU]: (state) => state.clientPermissionMenu,
  [GET_CLIENT_ACCOUNT_CODE]: (state) => {
    if (!_.isEmpty(state.clientAccountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.clientAccountLogin.info,
        "account_code"
      )
        ? state.clientAccountLogin.info.account_code
        : "";
    }
    return "";
  },
  [GET_CLIENT_ACCOUNT_INFO]: (state) => {
    if (!_.isEmpty(state.clientAccountLogin.info)) {
      return state.clientAccountLogin.info;
    }
    return {};
  },
  [GET_PROFILE_INCOMPLETE_FLAG]: (state) => {
    if (!_.isEmpty(state.clientAccountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.clientAccountLogin.info,
        "profile_incomplete_flag"
      )
        ? state.clientAccountLogin.info.profile_incomplete_flag
        : 0;
    }
    return 0;
  },
  [GET_CLIENT_LOGIN_ORGANIZATION]: (state) => state.login_organization,
  [GET_CLIENT_MEMBER_TYPES]: (state) =>
    state.member_types.map((item) => item.member_type_code),
  [GET_CLIENT_MEMBER_TYPE_INFO]: (state) => state.member_types,
  [GET_CLIENT_MENU_HEADER]: (state) => state.client_menu_header,
  [GET_CLIENT_MEMBER_CARD_DESIGN]: (state) => state.member_card_designs,
  [GET_CLIENT_LIST_PERMISSION_MENU]: (state) => state.permission_menu,
  [GET_CLIENT_ALL_PERMISSION_MENU]: (state) => [
    ...state.client_menu_header,
    ...state.clientPermissionMenu,
  ],
  [GET_PROCESS_INFO_IS_DONE]: (state) => state.process_get_info_is_done,
  [GET_MEMBER_TYPE_RENEWABLE_FLAG]: (state) =>
    state.clientAccountLogin.info?.member_type_renewable_flag || false,
  [GET_CLASS_TYPE]: (state) =>
    state.clientAccountLogin.info?.class_type || null,
  [GET_CLIENT_CORPORATE_CODE]: (state) =>
    state.clientAccountLogin.info?.corporate_code || "",
};
