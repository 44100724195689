import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "contract-plans",
  name: "EdicContractPlanManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-contract-plan-layout-view" */ "@/views/EdicAdmin/ContractPlans/LayoutView.vue"
    ),
  redirect: { name: "EdicContractPlansSearch" },
  children: [
    {
      path: "",
      name: "EdicContractPlansSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-contract-plan-searching-view" */ "@/views/EdicAdmin/ContractPlans/Search.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create",
      name: "EdicContractPlansCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-contract-plan-create-view" */ "@/views/EdicAdmin/ContractPlans/Create.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id",
      name: "EdicContractPlansEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-contract-plan-edit-view" */ "@/views/EdicAdmin/ContractPlans/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
