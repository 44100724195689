import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "master-category-customizations",
  name: "MasterCategoryCustomizationManage",
  component: () =>
    import(
      /* webpackChunkName: "master-category-customization-manage-layout-view" */ "@/views/Manage/MasterCategoryCustomization/LayoutView.vue"
    ),
  redirect: { name: "MasterCategoryCustomizationSearch" },
  children: [
    {
      path: "",
      name: "MasterCategoryCustomizationSearch",
      component: () =>
        import(
          /* webpackChunkName: "master-category-customization-manage-searching-view" */ "@/views/Manage/MasterCategoryCustomization/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/payment-plans/create
      name: "MasterCategoryCustomizationCreate",
      component: () =>
        import(
          /* webpackChunkName: "master-category-customization-manage-create-view" */ "@/views/Manage/MasterCategoryCustomization/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/payment-plans/edit/1
      name: "MasterCategoryCustomizationEdit",
      component: () =>
        import(
          /* webpackChunkName: "master-category-customization-manage-edit-view" */ "@/views/Manage/MasterCategoryCustomization/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
