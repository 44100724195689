import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "master-menu",
  name: "EdicAdminMasterMenuManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-master-menu-layout-view" */ "@/views/EdicAdmin/MasterMenu/LayoutView.vue"
    ),
  redirect: { name: "EdicAdminMasterMenuSearch" },
  children: [
    {
      path: "",
      name: "EdicAdminMasterMenuSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-master-menu-searching-view" */ "@/views/EdicAdmin/MasterMenu/Search.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create",
      name: "EdicAdminMasterMenuCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-master-menu-create-view" */ "@/views/EdicAdmin/MasterMenu/Create.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id",
      name: "EdicAdminMasterMenuEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-master-menu-edit-view" */ "@/views/EdicAdmin/MasterMenu/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
