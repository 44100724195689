import {
  GET_FACILITY_MANAGEMENT_INFO,
  GET_FACILITY_MANAGEMENT_CODE,
  GET_FACILITY_MANAGEMENT_ID,
  GET_FACILITY_MENU_OVERLAY,
  GET_IS_FACILITY_EXISTS,
  GET_FACILITY_IS_USING_APP,
  GET_FINANCIAL_BALANCE_TYPE,
  GET_FINANCIAL_BALANCE_DATE,
} from "./types";
import { isEmpty } from "lodash";

export default {
  [GET_FACILITY_MANAGEMENT_INFO]: (state) => state,
  [GET_FACILITY_MANAGEMENT_CODE]: (state) => state.facility?.code || "",
  [GET_FACILITY_MANAGEMENT_ID]: (state) => state.facility?.id || "",
  [GET_FACILITY_MENU_OVERLAY]: (state) => state.is_menu_overlay,
  [GET_IS_FACILITY_EXISTS]: (state) => !isEmpty(state.facility),
  [GET_FACILITY_IS_USING_APP]: (state) => state.app?.status,
  [GET_FINANCIAL_BALANCE_TYPE]: (state) =>
    state.facility_settings?.financial_balance_type || null,
  [GET_FINANCIAL_BALANCE_DATE]: (state) =>
    state.facility_settings?.financial_balance_date || {},
};
