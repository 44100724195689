import { render, staticRenderFns } from "./ClientJbbfCategoryButton.vue?vue&type=template&id=5be43344&scoped=true&"
import script from "./ClientJbbfCategoryButton.vue?vue&type=script&lang=js&"
export * from "./ClientJbbfCategoryButton.vue?vue&type=script&lang=js&"
import style0 from "./ClientJbbfCategoryButton.vue?vue&type=style&index=0&id=5be43344&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5be43344",
  null
  
)

export default component.exports