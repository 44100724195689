import {
  GET_SEARCH_FORM,
  GET_SEARCH_FIELDS,
  GET_BASIC_INFO_FORM,
  GET_EXTEND_INFO_FORM,
  GET_PROCESS_EXPORT_CSV_FILE,
  GET_PROCESS_IMPORT_CSV_FILE,
  GET_IMPORT_CSV_CONTENT,
} from "./types";

export default {
  [GET_SEARCH_FORM]: (state) => state.search.form,
  [GET_SEARCH_FIELDS]: (state) => state.search.search_fields,
  [GET_BASIC_INFO_FORM]: (state) => state.detail.basic_info,
  [GET_EXTEND_INFO_FORM]: (state) => state.detail.extend_info,
  [GET_PROCESS_EXPORT_CSV_FILE]: (state) => state.exportCsv,
  [GET_PROCESS_IMPORT_CSV_FILE]: (state) => state.importCsv,
  [GET_IMPORT_CSV_CONTENT]: (state) => state.importCsv.csv_content,
};
