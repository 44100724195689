import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "payment-agencies",
  name: "EdicAdminPaymentAgencyManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-payment-agency-layout-view" */ "@/views/EdicAdmin/PaymentAgency/LayoutView.vue"
    ),
  redirect: { name: "EdicAdminPaymentAgencySearch" },
  children: [
    {
      path: "",
      name: "EdicAdminPaymentAgencySearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-payment-agency-searching-view" */ "@/views/EdicAdmin/PaymentAgency/Search.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create",
      name: "EdicAdminPaymentAgencyCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-payment-agency-create-view" */ "@/views/EdicAdmin/PaymentAgency/Create.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id",
      name: "EdicAdminPaymentAgencyEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-payment-agency-edit-view" */ "@/views/EdicAdmin/PaymentAgency/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
