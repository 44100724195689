// ACTION
export const ACT_RELATED_OPTIONS = "ACT_GET_RELATED_OPTIONS";
export const ACT_GET_ACCOUNT_CMS_GROUP = "ACT_GET_ACCOUNT_CMS_GROUP";

// SET mutations
export const SET_RELATED_OPTIONS = "SET_RELATED_OPTIONS";
export const SET_LIST_ACCOUNT_CMS_GROUP = "SET_LIST_ACCOUNT_CMS_GROUP";

// GETS
export const GET_RELATED_OPTIONS = "GET_PATH_SEARCH";
export const GET_LIST_ACCOUNT_CMS_GROUP = "GET_LIST_ACCOUNT_CMS_GROUP";
