// import domain from '@/middlewares/manage/domain';
import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "access-template-conditions",
  name: "AccessTemplateCondition",
  component: () =>
    import(
      /* webpackChunkName: "access-template-manage-layout-view" */ "@/views/Manage/AccessTemplate/LayoutView.vue"
    ),
  redirect: { name: "AccessTemplateSearch" },
  children: [
    {
      path: "",
      name: "AccessTemplateSearch",
      component: () =>
        import(
          /* webpackChunkName: "access-template-manage-searching-view" */ "@/views/Manage/AccessTemplate/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create",
      name: "AccessTemplateCreate",
      component: () =>
        import(
          /* webpackChunkName: "access-template-manage-create-view" */ "@/views/Manage/AccessTemplate/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id",
      name: "AccessTemplateEdit",
      component: () =>
        import(
          /* webpackChunkName: "access-template-manage-edit-view" */ "@/views/Manage/AccessTemplate/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
