/* eslint-disable */ 
import { inject, computed } from 'vue';

export default function useComponentPermission() {
  const manageCanCreateEditRemove = inject('manageCanCreateEditRemove', null);
  const isUsingPermission = inject('isUsingPermission', null)

  const isUsingPermissionManagement = computed(() => {
    if (isUsingPermission != null) {
      return isUsingPermission.value
    }
    return false;
  });

  const managementCanCreateEditRemove = computed(() => {
    if (manageCanCreateEditRemove != null && typeof manageCanCreateEditRemove.value === 'boolean') {
        return manageCanCreateEditRemove.value
    }
    return true;
  })

  return {
    isUsingPermissionManagement,
    managementCanCreateEditRemove
  }
}