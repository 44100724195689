import { ref, onMounted, onBeforeUnmount, watch } from "vue";

export default function useHandleScreenSize(callback) {
  let windowWidth = ref(window.innerWidth);

  const setWidthScreen = () => {
    windowWidth.value = window.innerWidth;
  };
  onMounted(() => {
    window.addEventListener("resize", setWidthScreen);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", setWidthScreen);
  });

  watch(
    () => windowWidth.value,
    () => {
      callback && callback(windowWidth.value);
    }
  );
  return {
    windowWidth,
  };
}
