<template>
  <client-jbbf-base-card use-simple-card card-class="h-100">
    <div class="d-flex flex-column message__after__acction-content">
      <div class="message">
        <span
          >ここには成功メッセージが表示されます。ここには成功メッセージが表示されます。ここには成功メッセージが表示されます。ここには成功メッセージが表示されます。</span
        >
      </div>
      <div class="form mt-3 mb-2">
        <b-form-group id="fieldset-1" label="フィールド1" label-for="input-1">
          <b-form-input
            id="input-1"
            trim
            class="border-secondary"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="fieldset-2" label="フィールド2" label-for="input-2">
          <b-form-input
            id="input-2"
            trim
            class="border-secondary"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="fieldset-3" label="フィールド3" label-for="input-3">
          <b-form-input
            id="input-3"
            trim
            class="border-secondary"
          ></b-form-input>
        </b-form-group>
        <button class="btn btn-danger mt-1 w-100 text-center">
          <span class="text-center">{{ $t("common.label_go_back") }}</span>
        </button>
      </div>
      <button class="btn btn-danger mt-1 d-flex">
        <span class="w-50 text-left">
          <i aria-hidden="true" class="fas fa-arrow-left"></i>
        </span>
        <span class="w-50 text-left">{{ $t("common.label_go_back") }}</span>
      </button>
    </div>
  </client-jbbf-base-card>
</template>

<script>
export default {
  name: "ClientJbbfMessageAfterAction",
};
</script>
<style scoped lang="scss">
.message__after__acction-content {
  color: #7a7a7a;
  font-weight: 400;
  padding: 3px;
}
</style>
