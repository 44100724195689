var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"justify-content-center core__input__group__wrapper mt-2 mb-2"},[_c('b-col',{staticClass:"d-flex",class:{ 'align-items-center': _vm.labelMiddleCenter },attrs:{"xl":"2","lg":"3","md":"4","sm":"12","sx":"12"}},[_vm._t("label",function(){return [_c('label',{staticClass:"m-0 core__input__group__wrapper-title__input"},[_vm._t("title",function(){return [_c('span',{staticClass:"label__text",domProps:{"innerHTML":_vm._s(_vm.title)}})]},{"title":_vm.title}),(_vm.subTitle)?_c('div',{staticClass:"font-weight-normal"},[_vm._v(_vm._s(_vm.subTitle))]):_vm._e()],2),(_vm.required)?_c('span',{staticClass:"text-danger text-nowrap pl-1 font-weight-bold"},[_vm._v(_vm._s(_vm.textRequired))]):_vm._e()]})],2),_c('b-col',{attrs:{"xl":"10","lg":"9","md":"8","sm":"12","sx":"12"}},[_vm._t("input",function(){return [_c('div',{class:_vm.addClassCustom},[(_vm.input)?_c('b-form-input',_vm._g(_vm._b({class:{
            'is-invalid':
              _vm.validationResult && _vm.validationResult.hasOwnProperty(_vm.keyName),
          },attrs:{"trim":""},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}},'b-form-input',_vm.propsInput,false),_vm.$listeners)):_vm._e()],1)]},{"inputFailValidate":_vm.isInputFailValidation}),_c('div',{directives:[{name:"error-input-text",rawName:"v-error-input-text",value:({ validationResult: _vm.validationResult, keyName: _vm.keyName }),expression:"{ validationResult, keyName: keyName }"}]}),(_vm.listKeyName && _vm.validationResult)?_c('div',{staticClass:"text-danger"},[_vm._l((_vm.listKeyName),function(itemKeyName,index){return [(
            Object.prototype.hasOwnProperty.call(
              _vm.validationResult,
              itemKeyName
            )
          )?_c('span',{key:index,staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.validationResult[itemKeyName])+" ")]):_vm._e()]})],2):_vm._e(),_c('div',[_vm._t("hideText",function(){return [(_vm.hintText)?_c('span',{staticClass:"core__input__group__wrapper-hint__text"},[_vm._v(_vm._s(_vm.hintText))]):_vm._e()]})],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }