import Store from "@/store";
import {
  GET_VALID_DOMAIN_FACILITY,
  ACT_CHECK_VALID_DOMAIN_FACILITY,
  GET_FACILITY_INFO,
} from "@/store/modules/Facility/types";

export default async function domain({ next }) {
  let isValidDomain = Store.getters[`Facility/${GET_VALID_DOMAIN_FACILITY}`];
  //let hostname = location.hostname.split('.'); // example: facility.local.env => ['facility', 'local', 'env']
  if (!isValidDomain) {
    // false
    try {
      await Store.dispatch(`Facility/${ACT_CHECK_VALID_DOMAIN_FACILITY}`, {
        url: location.hostname,
      });
      isValidDomain = Store.getters[`Facility/${GET_VALID_DOMAIN_FACILITY}`];
      let facilityInfo = Store.getters[`Facility/${GET_FACILITY_INFO}`];
      // set title browser and logo image
      document.querySelector("title").textContent = facilityInfo.name;
      document.querySelector("link[rel*='icon']").href =
        facilityInfo.logo_image_path ? facilityInfo.logo_image_path : "";
      if (!isValidDomain) {
        return next({ path: "/manage/404" });
      }
      return next();
    } catch (err) {
      return next({ path: "/manage/404" });
    }
  } else {
    let facilityInfo = Store.getters[`Facility/${GET_FACILITY_INFO}`];
    document.querySelector("title").textContent = facilityInfo.name;
    return next();
  }
}
