<template>
  <div class="d-block">
    <div v-if="loadingCntCms" class="d-block">
      <base-card class="content__list__form__search__wrapper">
        <base-input-group v-for="item in 3" :key="item">
          <template #label>
            <div class="d-block w-100">
              <b-skeleton></b-skeleton>
            </div>
          </template>
          <template #input>
            <b-skeleton type="input"></b-skeleton>
          </template>
        </base-input-group>
      </base-card>
    </div>
    <div v-else class="d-block">
      <base-card
        use-simple-card
        class="content__list__form__search__wrapper"
        :title="titleForm"
        title-classes="font-weight-bold h2 text-secondary"
      >
        <div class="content__list__form__search__wrapper-content">
          <div class="d-block">
            <form>
              <slot></slot>
              <slot name="btn-action">
                <div
                  class="content__list__form__search__wrapper-content-action__btn"
                >
                  <b-button
                    class="default-btn background-primary-color"
                    @click="$emit('search')"
                    >{{ $t("panel.label_search") }}</b-button
                  >
                  <b-button
                    class="default-btn background-neutral-300"
                    @click="$emit('reset')"
                    >{{ $t("panel.label_clear") }}</b-button
                  >
                </div>
              </slot>
            </form>
          </div>
        </div>
      </base-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "ClientJbbfFormSearch",
  props: {
    loadingCntCms: {
      type: Boolean,
      default: true,
    },
    titleForm: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped lang="scss">
.content__list__form__search__wrapper {
  &-content {
    padding: 5px;

    &-action__btn {
      margin-top: 10px;
      display: flex;
      gap: 10px;
      justify-content: center;
      width: 100%;
      align-items: center;

      @media screen and (max-width: 900px) {
        button {
          flex: 1;
        }
      }
    }
  }
}
</style>
