import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "corporates",
  name: "CorporateManage",
  component: () =>
    import(
      /* webpackChunkName: "corporate-manage-layout-view" */ "@/views/Manage/Corporate/LayoutView.vue"
    ),
  redirect: { name: "CorporateSearch" },
  children: [
    {
      path: "",
      name: "CorporateSearch",
      component: () =>
        import(
          /* webpackChunkName: "corporate-manage-searching-view" */ "@/views/Manage/Corporate/FilterSearching.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/corporate/create
      name: "CorporateCreate",
      component: () =>
        import(
          /* webpackChunkName: "corporate-manage-create-view" */ "@/views/Manage/Corporate/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/corporate/edit/1
      name: "CorporateEdit",
      component: () =>
        import(
          /* webpackChunkName: "corporate-manage-edit-view" */ "@/views/Manage/Corporate/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "detail/:id", // /manage/corporate/detail/1
      name: "CorporateDetail",
      component: () =>
        import(
          /* webpackChunkName: "corporate-manage-detail-view" */ "@/views/Manage/Corporate/Detail.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
