<template>
  <div class="d-block">
    <!-- <div class="" -->
    <!-- <slot></slot> -->
    <template v-if="notFound">
      <NotFoundComponent />
    </template>
    <template v-else>
      <template v-if="isManagementCanBeViewed">
        <slot
          :is-can-view="isManagementCanBeViewed"
          :is-can-edit="isManagementCanBeEditable"
        >
        </slot>
      </template>
      <template v-else>
        <PermissionDenyComponent />
      </template>
    </template>
  </div>
</template>

<script>
import PermissionDenyComponent from "@/components/Errors/PermissionDenyComponent.vue";
import NotFoundComponent from "@/components/Errors/NotFoundComponent.vue";
// import useRedirectUnauthorized from '@/hooks/useRedirectUnauthorized';
// import { GET_PERMISSION_TREE_MENU } from '@/store/modules/AuthManage/types';
// import { SET_MANAGEMENT_PERMISSION_VIEW, REFRESH_MANAGEMENT_PERMISSION_VIEW } from '@/store/modules/ManagePermissionScreen/types';
// import { mapGetters, mapMutations } from 'vuex';
// import { computed } from 'vue';
import useMainManagementPermission from "@/hooks/useManagementPermission/main";

// function returnItemMenu(listMenu, fullPath) {
//   return listMenu.reduce((findItem, curItem) => {
//     if (findItem) return findItem;
//     if (!/^\/manage\/$/.test(fullPath) && fullPath.includes(curItem.url) && (!curItem.children || !curItem.children.length)) {
//       return curItem;
//     }
//     if (curItem.children.length) return returnItemMenu(curItem.children, fullPath);
//   }, null)
// }

// EVENT_NAME
// const EVENT_NAME = {
//   management_not_allow_to_access: 'management_not_allow_to_access',
//   management_not_found: 'management_not_found'
// }

export default {
  name: "BaseManagePermissionView",
  components: {
    NotFoundComponent,
    PermissionDenyComponent,
  },
  setup() {
    // let { redirect401 } = useRedirectUnauthorized();
    let { isManagementCanBeViewed, isManagementCanBeEditable, notFound } =
      useMainManagementPermission({
        type: "initialize",
      });
    // if (!isManagementCanBeViewed.value) {
    //   redirect401();
    // }
    return {
      isManagementCanBeViewed,
      isManagementCanBeEditable,
      notFound,
    };
  },

  /*
  provide() {
    return {
      'manageIsDisabledEditing': computed(() => this.manageIsDisabledEditing),
      'manageCanCreateEditRemove': computed(() => this.manageCanCreateEditRemove),
      'isUsingPermission': computed(() => this.isUsingPermission),
    }
  },
  computed: {
    ...mapGetters('AuthManage', {
      getPermissionTreeMenu: GET_PERMISSION_TREE_MENU
    }),
    'isManagementCanBeViewed'() {
      return Boolean(this.view_flag);
    },
    'isManagementCanBeEditable'() {
      return Boolean(this.edit_flag);
    },
    'notFound'() {
      return Boolean(this.not_found);
    },

    isUsingPermission() {
      return this.management_check_permission;
    },

    manageIsDisabledEditing() {
      if (this.management_check_permission) {
        return this.isManagementCanBeEditable ? false : true; 
        // 
      }
      return null;
    },

    // management can create new or edit by getting the permissions
    manageCanCreateEditRemove() {
      if (this.management_check_permission) {
        return Boolean(this.edit_flag); // true ==> can create new, false ==> can not
      }
      return null;
    }
  },

  data() {
    return {
      view_flag: 1,
      edit_flag: 1,
      menu_id: '',
      id: '',
      management_check_permission: false, // if finding a menu ==> true, else false
      currentRouteName: '',
      not_found: false,
    }
  },

  beforeMount() {
    this.initialize();
  },

  // updated() {
  //   this.refreshPermissionState();
  //   this.initialize();
  // },

  watch: {
    '$route'() {
      this.refreshPermissionState();
      this.initialize();
    }
  },

  // beforeRouteLeave(to, from, next) {
  //   console.log('before route update');
  //   this.refreshPermissionState();
  //   this.initialize(to.path);
  //   next();
  // },

  created() {
    // not allow to access this page
    this.$eventBus.$on(EVENT_NAME.management_not_allow_to_access, this.notAllowToAccess);
    this.$eventBus.$on(EVENT_NAME.management_not_found, () => this.not_found = true);
  },

  methods: {
    ...mapMutations('ManagePermissionScreen', {
      setManagementPermissionView: SET_MANAGEMENT_PERMISSION_VIEW,
      refreshManagementPermissionView: REFRESH_MANAGEMENT_PERMISSION_VIEW
    }),
    initialize(url = null) {
      const path = url || this.$route.path;
      console.log('initialize', path)

      if (this.getPermissionTreeMenu.length) {
        let findItemMenu = returnItemMenu(this.getPermissionTreeMenu, path);
        if (findItemMenu) {
          let { menu_id, view_flag, edit_flag, id } = findItemMenu;
          this.view_flag = view_flag;
          this.edit_flag = edit_flag;
          this.menu_id = menu_id;
          this.id = id;
          this.management_check_permission = true;

          // save store
          this.setManagementPermissionView({
            id,
            menu_id,
            view_flag,
            edit_flag
          })
        } else {
          this.not_found = true;
        }
      }

      this.currentRouteName = this.$route.name;
    },
    refreshPermissionState() {
      this.edit_flag = 1;
      this.view_flag = 1;
      this.menu_id = '';
      this.id = '';
      this.not_found = false;
      this.management_check_permission = false;
      // refresh store
      this.refreshManagementPermissionView()
    },
    notAllowToAccess() {
      this.view_flag = false;
    }
  }
  */
};
</script>
