<template>
  <div
    v-click-outside="handleClickoutside"
    class="position-relative d-inline-block"
  >
    <slot :open-pick-month="openPickMonth">
      <button type="button" @click="openPickMonth">
        <i class="fa-solid fa-calendar"></i>
      </button>
    </slot>
    <transition name="fade">
      <div ref="MonthPickerElement" class="monthly__picker__wrapper">
        <!-- <month-picker
          :clearable="true"
          lang="ja"
          :show-year="true"
          :editable-year="true"
          date-format="YYYY/MM"
          @input="handleChangeMonth"
          @clear="handleClear"
          @change-year="handleChangeYear"
        ></month-picker> -->
        <vue-monthly-picker
          ref="VueMonthlyPicker"
          :value="monthYear"
          date-format="YYYY/MM"
          :month-labels="monthLabels"
          @selected="handleSelectedMonthVueMonthly"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
// import { MonthPicker } from "vue-month-picker";
import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";

export default {
  name: "BaseInputMonthlyPicker",
  components: {
    // MonthPicker,
    VueMonthlyPicker,
  },
  mixins: [handleValidateMixin],
  props: {
    value: {
      type: String,
      default: "",
    },
    alwaysDirective: {
      type: String,
      default: "",
      description: "Only 2 params: up | down",
    },
  },
  data() {
    return {
      openPick: false,
    };
  },
  computed: {
    monthYear: {
      get() {
        return moment(this.value, "YYYY/MM");
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    monthLabels() {
      return [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ];
    },

    // old lib: vue-month-picker
    currentYear() {
      let date = new Date();
      return date.getFullYear();
    },
    // old lib: vue-month-picker
    currentMonth() {
      return new Date().getMonth();
    },

    // old lib: vue-month-picker
    pickMonthData: {
      get() {
        let momentTime = moment(this.value, "YYYY/MM");

        if (momentTime.isValid()) {
          return {
            month: parseInt(momentTime.format("M")),
            year: parseInt(momentTime.format("YYYY")),
          };
        }
        return {
          month: this.currentMonth,
          yearh: this.currentYear,
        };
      },
    },
  },
  watch: {
    openPick() {
      if (this.openPick) {
        this.$emit("popoverWillShow");
      } else {
        this.$emit("popoverWillHide");
      }
    },
  },
  mounted() {
    const width = this.$el
      .querySelector(".monthly__picker__wrapper")
      .getBoundingClientRect().width;
    this.$refs.MonthPickerElement.style.left = `-${width - 20}px`;
    // this.changeUpDown()
    // window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleClickoutside() {
      this.$refs.MonthPickerElement.classList.remove("active");
      this.openPick = false;
    },
    // old lib: vue-month-picker
    handleSelectMonth(month) {
      if (month) {
        this.$emit("input", month.format("YYYY/MM"));
      } else {
        this.$emit("input", "");
      }
    },

    // old lib: vue-month-picker
    openPickMonth() {
      this.openPick = !this.openPick;
      if (this.openPick) {
        this.$el.querySelector(".date-popover").style.display = "block";
        this.$el.querySelector(".month-year-display").style.display = "none";
      }
      this.handleDisplayY();
    },

    // old lib: vue-month-picker
    handleChangeMonth(date) {
      let pickMoment = moment(
        `${date.year}/${date.monthIndex}`,
        "YYYY/MM"
      ).format("YYYY/MM");
      this.$emit("input", pickMoment);
      this.openPick = false;
      this.$refs.MonthPickerElement.classList.remove("active");
    },
    // old lib: vue-month-picker
    handleClear() {
      this.$emit("input", "");
      this.openPick = false;
    },

    closeOpenPick() {
      this.openPick = false;
    },
    // old lib: vue-month-picker
    handleChangeYear(yearOutput) {
      if (moment(this.value, "YYYY/MM").isValid()) {
        this.$emit(
          "input",
          moment(this.value, "YYYY/MM").year(yearOutput).format("YYYY/MM")
        );
      } else {
        this.$emit("input", "");
      }
    },
    handleDisplayY() {
      this.$refs.MonthPickerElement.classList.toggle("active");
      this.changeUpDown();
    },
    handleResize() {
      if (this.openPick) {
        this.changeUpDown();
      }
    },
    changeUpDown() {
      const monthPickerRect =
        this.$refs.MonthPickerElement.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const showUp = () => {
        this.$refs.MonthPickerElement.classList.add("showUp");
        this.$el.querySelector(".date-popover").style.top = "auto";
        this.$el.querySelector(".date-popover").style.bottom = "100%";
      };

      const showDown = () => {
        this.$refs.MonthPickerElement.classList.remove("showUp");
        this.$el.querySelector(".date-popover").style.top = null;
        this.$el.querySelector(".date-popover").style.bottom = null;
      };
      //
      if (this.alwaysDirective) {
        if (this.alwaysDirective == "up") {
          showUp();
        }
        return;
      }
      if (monthPickerRect.bottom > bodyRect.bottom) {
        showUp();
      } else {
        showDown();
      }
    },
    handleSelectedMonthVueMonthly(data) {
      this.$emit("input", data.format("YYYY/MM"));
      this.openPick = false;
      this.$refs.MonthPickerElement.classList.remove("active");
    },
  },
};
</script>

<style lang="scss">
.month-picker__month {
  padding: 0.25rem 0.15rem;
}
.month-picker__container {
  z-index: 10;
  width: auto;
  min-width: 250px;
}

.month-picker__year {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    font-size: 2rem;
  }
}

.monthly__picker__wrapper {
  z-index: 900;
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  padding: 5px;
  margin: 0;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.showUp {
    top: auto;
    bottom: 100%;
  }
}
</style>
