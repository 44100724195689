/* eslint-disable */
import {
  dependencyOperatorType,
  typeCmsComponent,
  extendInputType,
} from "@/constants/commons";
import difference from "lodash/difference";
import { checkLogicalFuncNameAndExecute } from "./logicalFunction";

export const autoPassWithKeyName = [
  "name",
  "name_kana",
  "location",
  "document",
  "event_attended",
  "event_participant",
  "invoice_history",
  "profile_image",
];

export const autoPassInputType = [
  extendInputType.name.value,
  extendInputType.name_kana.value,
  extendInputType.location.value,
];

// getCompare value ----- we will check the key default component first --->
export const trackKeyException = (
  type,
  component_code = "",
  input_type = ""
) => {
  if (type == typeCmsComponent.default.value) {
    return autoPassWithKeyName.includes(component_code);
  } else {
    return autoPassInputType.includes(input_type);
  }
};

export const trackOperatorValue = (originalData, operator_type, dataValue) => {
  switch (operator_type) {
    // >=
    case dependencyOperatorType.more.value: {
      if (dataValue instanceof Array) return false;
      if (originalData == "" || originalData == null) return false;
      return originalData >= dataValue;
    }
    // <=
    case dependencyOperatorType.less.value: {
      if (dataValue instanceof Array) return false;
      if (originalData == "" || originalData == null) return false;
      return originalData <= dataValue;
    }

    // ===
    case dependencyOperatorType.equal.value: {
      if (dataValue instanceof Array) {
        if (originalData instanceof Array == false) {
          return (
            difference(
              [...dataValue].map((item) => item + ""),
              [originalData].map((item) => item + "")
            ).length == false
          );
        } else {
          if (originalData.length != dataValue.length) return false;
          let dif = difference(dataValue, originalData);
          return dif.length == false; // TRUE ==> it's still DIFFERENCE , FALSE ==> that's ok
        }
      }
      return originalData == dataValue;
    }

    // IN
    case dependencyOperatorType.contain.value: {
      if (dataValue instanceof Array) {
        if (originalData instanceof Array) {
          return originalData.some((item) => dataValue.includes(item));
        } else {
          if (originalData !== "" && originalData !== null) {
            return [originalData]
              .map(String)
              .some((item) => dataValue.map(String).includes(item));
          }
          return false;
        }
      }
      return false;
    }

    // NOT IN
    case dependencyOperatorType.except.value: {
      if (dataValue instanceof Array) {
        if (originalData instanceof Array) {
          if (originalData.length) {
            return originalData.every((item) => !dataValue.includes(item));
          } else {
            return false;
          }
        } else {
          if (
            typeof originalData != "undefined" &&
            originalData !== "" &&
            originalData !== null
          ) {
            return [originalData]
              .map(String)
              .every((item) => !dataValue.map(String).includes(item));
          }
          return false;
        }
      }
      return false;
    }

    default:
      return false;
  }
};

// generate Condition
export const generateCondition = (
  dataObservable,
  {
    dependency_account_cms_component_code,
    dependency_account_cms_extend_component_id,
    value,
    operator_type,
    type,
    input_type,
    logical_function = "",
  },
  { basic_info, extend_info }
) => {
  // if condition is a specify logical function
  if (logical_function) {
    return checkLogicalFuncNameAndExecute(logical_function, {
      basic_info,
      extend_info,
      value_compare: value,
      operator_type,
    });
  }
  // if is location
  if(input_type == "location") {
    if(dataObservable[dependency_account_cms_extend_component_id]) {
      const dataNeedToCompare = dataObservable[dependency_account_cms_extend_component_id].value.pref_code;
      return trackOperatorValue(dataNeedToCompare, operator_type, value);
    }
    return false;
  }
  let dataNeedToCompare =
    type == typeCmsComponent.default.value
      ? dataObservable[dependency_account_cms_component_code]
      : dataObservable[dependency_account_cms_extend_component_id].value;

  let trackKeyExceptionVal = trackKeyException(
    type,
    dependency_account_cms_component_code,
    input_type
  );

  if (trackKeyExceptionVal) return true;

  return trackOperatorValue(dataNeedToCompare, operator_type, value);
};

// generatePattern
export const generatePattern = (
  dependency_patterns,
  value,
  defaultReturn = false
) => {
  if (dependency_patterns.length > 0) {
    return [...dependency_patterns]
      .map((pattern) => {
        if (pattern.conditions.length) {
          return pattern.conditions
            .map((condition) => {
              let dataObservable =
                condition.type == typeCmsComponent.default.value
                  ? value.basic_info
                  : value.extend_info;
              return generateCondition(
                dataObservable,
                { ...condition },
                { ...value }
              );
            })
            .every((item) => item == true);
        }
        return false;
      })
      .some((item) => item == true);
  } else {
    return defaultReturn;
  }
};
