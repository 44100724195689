import {
  ACT_LOGIN_ACCOUNT,
  ACT_LOGOUT,
  SET_TOKEN,
  SET_REFRESH_AUTH,
  SET_ACCOUNT_INFO,
  SET_EXPIRED_AT,
  SET_IS_AUTH,
  ACT_FORCE_FRESH_AUTH,
} from "./types";
import storage from "@/helpers/storageManage";
import authservice from "@/services/auth/auth_admin";
import {
  ApplicationAdminStorageToken,
  ApplicationAdminIsAuth,
  ApplicationAdminTokenExpiredAt,
  ApplicationAdminAccountInfo,
} from "@/plugins/setting";
import moment from "moment";

export default {
  [ACT_LOGIN_ACCOUNT]: async ({ commit }, payload) => {
    let response = await authservice.login(payload);
    let {
      access_token,
      account_code,
      account_id,
      expires_in,
      system_admin_flag,
      first_name,
      last_name,
    } = response;

    let expiredAt = moment().add(expires_in, "minutes").valueOf(); // miliseconds

    // commit
    commit(SET_TOKEN, access_token);
    commit(SET_ACCOUNT_INFO, {
      account_code,
      account_id,
      first_name,
      last_name,
    });
    commit(SET_EXPIRED_AT, expiredAt);
    commit(SET_IS_AUTH, "success");
    //
    storage.setStorage({
      [ApplicationAdminStorageToken]: access_token,
      [ApplicationAdminIsAuth]: "success",
      [ApplicationAdminTokenExpiredAt]: expiredAt,
      [ApplicationAdminAccountInfo]: JSON.stringify({
        account_id,
        account_code,
        system_admin_flag,
        first_name,
        last_name,
      }),
    });
  },
  [ACT_LOGOUT]: async ({ dispatch }) => {
    await authservice.logout();
    dispatch(ACT_FORCE_FRESH_AUTH);
  },

  [ACT_FORCE_FRESH_AUTH]: async ({ commit }) => {
    storage.removeStorage(ApplicationAdminStorageToken);
    storage.removeStorage(ApplicationAdminIsAuth);
    storage.removeStorage(ApplicationAdminTokenExpiredAt);
    storage.removeStorage(ApplicationAdminAccountInfo);

    commit(SET_REFRESH_AUTH);
  },
};
