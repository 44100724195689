import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "payment-plans/:payment_plan_code/payment-plan-subscribers",
  name: "PaymentPlanSubscriberManage",
  component: () =>
    import(
      /* webpackChunkName: "payment-plan-subscriber-manage-layout-view" */ "@/views/Manage/PaymentPlanSubscriber/LayoutView.vue"
    ),
  redirect: { name: "PaymentPlanSubscriberSearch" },
  children: [
    {
      path: "",
      name: "PaymentPlanSubscriberSearch",
      component: () =>
        import(
          /* webpackChunkName: "payment-plan-subscriber-manage-searching-view" */ "@/views/Manage/PaymentPlanSubscriber/FilterSearching.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/payment-plan-subscribers/create
      name: "PaymentPlanSubscriberCreate",
      component: () =>
        import(
          /* webpackChunkName: "payment-plan-manage-subscriber-create-view" */ "@/views/Manage/PaymentPlanSubscriber/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/payment-plan-subscribers/edit/1
      name: "PaymentPlanSubscriberEdit",
      component: () =>
        import(
          /* webpackChunkName: "payment-plan-subscriber-manage-edit-view" */ "@/views/Manage/PaymentPlanSubscriber/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
