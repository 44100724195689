import {
  GET_LIST_PROGRESS_STACK,
  GET_LIST_PROGRESS_MANAGEMENT_STACK,
  GET_PROGRESSING_TRACKING_ITEM,
  GET_ALL_PROGRESS_DONE,
} from "./types";

export default {
  [GET_LIST_PROGRESS_STACK]: (state) => state.export_progressing,
  // get list management_export
  [GET_LIST_PROGRESS_MANAGEMENT_STACK]: (state) =>
    state.export_progressing.filter((item) => !item.is_admin),
  [GET_PROGRESSING_TRACKING_ITEM]: (state) => (task_name) => {
    return (
      state.export_progressing.find((item) => item.task_name == task_name) ||
      null
    );
  },
  [GET_ALL_PROGRESS_DONE]: (state) =>
    state.export_progressing.every((item) => item.is_completed),
};
