// manage storage name
export const ApplicationManageStorageToken = "app.jbbf-project.manage-token";
export const ApplicationManageTokenExpiredAt =
  "app.jbbf-project.manage-token-expired-at";
export const ApplicationManageIsAuth = "app.jbbf-project.manage-is-auth";
export const ApplicationManageAccountInfo =
  "app.jbbf-project.manage-account-info";
export const ApplicationManageProfile = "app.jbbf-project.manage-profile";

export const ApplicationStorageToken = "app.jbbf-project.token";
export const ApplicationIsAuth = "app.jbbf-project.isAuth";
export const ApplicationManageSaveOrganizationFlag =
  "app.jbbf-project.save-organization-flag";
export const ApplicationManageSaveOrganizationId =
  "app.jbbf-project.save-organization-id";
export const ApplicationStorageUserDataName = "app.yor-project.userData";
export const ApplicationExpiredAt = "app.jbbf-project.expired-at";
export const ApplicationLang = "app.yor-project.language";
export const ApplicationPermissions = "app.yor-project.permissions";
export const ApplicationStartLifeTime = "app.yor-project.start-life-time";
export const ApplicationEndLifeTime = "app.yor-project.end-life-time";
export const MaxLifeTimeMinutes = 2; // minutes
export const AppSecondsSinceLastAccess =
  "app.yor-project.seconds-since-last-access";
export const AppEndTimeActivity = "app.yor-project.end-time-activity";

// client storage name
export const ApplicationClientStorageToken = "app.jbbf-project.client-token";
export const ApplicationClientTokenExpiredAt =
  "app.jbbf-project.client-token-expired-at";
export const ApplicationClientIsAuth = "app.jbbf-project.client-is-auth";
export const ApplicationClientAccountInfo =
  "app.jbbf-project.client-account-info";
export const ApplicationClientPaymentInfo =
  "app.jbbf-project.client-payment-info";
export const ApplicationClientAccountTemp =
  "app.jbbf-project.client-account-temp";
export const ApplicationClientCorporateCode =
  "app.jbbf-project.client-corporate-code";

// admin storage name
export const ApplicationAdminStorageToken = "app.jbbf-project.admin-token";
export const ApplicationAdminTokenExpiredAt =
  "app.jbbf-project.admin-token-expired-at";
export const ApplicationAdminIsAuth = "app.jbbf-project.admin-is-auth";
export const ApplicationAdminAccountInfo =
  "app.jbbf-project.admin-account-info";
