// import domain from '@/middlewares/manage/domain';
import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "mail-template-setting",
  name: "",
  component: () =>
    import(
      /* webpackChunkName: "mail-template-setting-manage-layout-view" */ "@/views/Manage/MailTemplateSetting/LayoutView.vue"
    ),
  redirect: { name: "MailTemplateSearch" },
  children: [
    {
      path: "", // /manage/mail-template-setting
      name: "MailTemplateSetting",
      component: () =>
        import(
          /* webpackChunkName: "mail-template-setting-manage-setting-view" */ "@/views/Manage/MailTemplateSetting/Setting.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
