import {
  SET_FACILITY_MANAGEMENT_INFO,
  SET_REFRESH_FACILITY_MANAGEMENT_INFO,
  SET_FACILITY_MENU_OVERLAY,
} from "./types";

export default {
  [SET_REFRESH_FACILITY_MANAGEMENT_INFO]: (state) => {
    state.account = null;
    state.app = null;
    state.facility = null;
    state.app_registry_new_member = 0;
    state.menu = null;
    state.payment = null;
  },
  [SET_FACILITY_MANAGEMENT_INFO]: (state, payload) => {
    const {
      account,
      app,
      facility,
      app_registry_new_member,
      menu,
      payment,
      facility_settings,
    } = payload;

    state.account = account;
    state.app = app;
    state.facility = facility;
    state.app_registry_new_member = app_registry_new_member;
    state.menu = menu;
    state.payment = payment;
    state.facility_settings = facility_settings;
  },
  [SET_FACILITY_MENU_OVERLAY]: (state, payload) => {
    state.is_menu_overlay = payload;
  },
};
