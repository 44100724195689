import { getCurrentInstance } from "vue";

export const VueInstance = () => {
  const _instance = getCurrentInstance();
  return _instance;
};

export const VueInstanceProxy = () => {
  const _instance = getCurrentInstance();
  return _instance.proxy;
};
