// actions
export const ACT_GET_LIST_ORGANIZATION_ACCOUNT =
  "ACT_GET_LIST_ORGANIZATION_ACCOUNT";
export const ACT_GET_ORGANIZATION_INSTANCE = "ACT_GET_ORGANIZATION_INSTANCE";
export const ACT_SET_REFRESH_DEFAULT = "ACT_SET_REFRESH_DEFAULT";
// mutations
export const SET_LIST_ORGANIZATION_ACCOUNT = "SET_LIST_ORGANIZATION_ACCOUNT";
export const SET_SAVE_ORGANIZATION_INSTANCE_NEXT_LOGIN =
  "SET_SAVE_ORGANIZATION_INSTANCE_NEXT_LOGIN";
export const SET_ORGANIZATION_INSTANCE = "SET_ORGANIZATION_INSTANCE";

// getters
export const GET_LIST_ORGANIZATION_ACCOUNT = "GET_USER_NAME";
export const GET_ORGANIZATION_INSTANCE = "GET_ORGANIZATION_INSTANCE";
export const GET_IS_STORE_ORGANIZATION_NEXT_LOGIN =
  "GET_IS_STORE_ORGANIZATION_NEXT_LOGIN";
export const GET_ID_ORGANIZATION_NEXT_LOGIN = "GET_ID_ORGANIZATION_NEXT_LOGIN";
export const GET_ORGANIZATION_NAME = "GET_ORGANIZATION_NAME";
