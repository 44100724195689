<template>
  <div class="d-block">
    <div
      class="select__file__button__wrapper d-flex g-5 w-100"
      :class="{
        'border-validation-error ': isFailValidation,
        not__error: !isFailValidation,
      }"
      @click="selectFileHandler"
    >
      <span
        :class="
          'select__file__button__wrapper-select__button text-white ' +
          btnBgColor
        "
        >{{ $t("panel.label_browse_file") }}</span
      >
      <span class="select__file__button__wrapper-file__name text-dark">{{
        displayFileName
      }}</span>
    </div>

    <!-- <b-form-file 
      ref="selectMultipleFile"
      multiple
      :accept="accept"
      @change="pickFile"
      :disabled="disabled"
      :state="!isFailValidation"
      class="d-none"
      :browse-text="$t('panel.label_browse_file')"
      :placeholder="$t('panel.label_please_choose_file')"
    /> -->

    <input
      ref="selectMultipleFile"
      type="file"
      multiple
      :accept="accept"
      class="d-none"
      :disabled="disabled"
      @change="pickFile"
    />
    <div
      v-if="isFailValidation"
      class="d-block my-2 is-invalid-feedback-message"
    >
      {{ textValidation }}
    </div>

    <!-- Maximum total file size -->
    <div
      class="d-none"
      :class="{
        'd-block is-invalid-feedback-message':
          Object.prototype.hasOwnProperty.call(
            $validation,
            nameComponentValidation
          ),
      }"
    >
      {{ $validation[nameComponentValidation] }}
    </div>
    <!-- hint text -->
    <div class="d-block hint__text">
      <div v-html="hintText"></div>
    </div>

    <div class="display__file__name__wrapper mt-2">
      <template v-if="listFiles.length">
        <template v-for="(itemFile, index) in listFiles">
          <div v-if="itemFile" :key="index">
            <div
              class="d-block mb-1"
              :class="{
                'border border-danger rounded pl-2 pr-2':
                  Object.prototype.hasOwnProperty.call(
                    validationResult,
                    `${keyName}.${index}.file`
                  ),
              }"
            >
              <div class="d-block">
                <div class="display__file__name__wrapper-file__item">
                  <span
                    class="display__file__name__wrapper-file__item-file__name text-primary"
                  >
                    {{ itemFile.file_name }}
                  </span>
                  <span
                    v-if="!disabled"
                    class="display__file__name__wrapper-file__item-remove__file text-danger"
                    @click="removeItemFile(index)"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="
                Object.prototype.hasOwnProperty.call(
                  validationResult,
                  `${keyName}.${index}.file`
                )
              "
              class="is-invalid-feedback-message pl-2 pr-2 pb-2"
            >
              {{ validationResult[`${keyName}.${index}.file`] }}
            </div>
          </div>
        </template>
      </template>

      <template v-if="fileUrls.length">
        <div v-for="itemUrl in fileUrls" :key="itemUrl.id" class="d-block mb-1">
          <div
            v-if="!itemUrl.is_deleted"
            class="display__file__name__wrapper-file__item"
          >
            <span
              class="display__file__name__wrapper-file__item-file__name text-primary"
            >
              <a
                v-review-file-storage-signurl="{
                  url: itemUrl.file_path_url,
                  review_storage_type: reviewStorageType,
                }"
                class="text-primary"
                :href="itemUrl.file_path_url"
                :download="itemUrl.file_name"
                target="_blank"
              >
                {{ itemUrl.file_name }}
              </a>
              <!-- @click.prevent="openLink($event, itemUrl.file_path_url)" -->
            </span>
            <span
              v-if="!disabled"
              class="display__file__name__wrapper-file__item-remove__file text-danger"
              @click="removeExistFile(itemUrl)"
            >
              <i class="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import { autoDownloadFromUrl } from "@/helpers/common";
import { btnBgColor } from "@/constants/settingPropsLayout";
import cloneDeep from "lodash/cloneDeep";

const defaultFile = {
  id: null,
  file: null,
  is_deleted: false,
  file_name: "",
};

export default {
  name: "BaseMultipleInputFile",
  mixins: [handleValidateMixin],
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    accept: {
      type: String,
      default:
        ".jpg, .png, .xlsx, .xls, .docx, .doc, .pptx, .ppt, .txt, .pdf, .zip, .rar",
    },
    compareSize: {
      type: Boolean,
      default: false,
    },
    fileUrls: {
      type: Array,
      default: function () {
        return [];
      },
    },
    hintText: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    btnBgColor: btnBgColor,
    reviewStorageType: {
      type: String,
      validator(val) {
        return ["manage", "client"].includes(val.trim());
      },
      default: "manage",
    },
  },

  data() {
    return {
      isHavingErrorMaxSizeFile: false,
      uidComponent: null,
    };
  },

  computed: {
    listFiles: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    displayFileName() {
      return this.$t("panel.label_please_choose_one_file");
    },
    totalFileSizeIsUploaded() {
      return this.listFiles
        .filter((item) => item && item.file)
        .reduce((cur, item) => cur + item.file.size, 0);
    },
    nameComponentValidation() {
      return `base_multiple_input_file_${this.uidComponent}`;
    },
  },

  created() {
    this.uidComponent = this._uid;
  },

  methods: {
    download(event, itemUrl) {
      event.preventDefault();

      const anchor = document.createElement("a");
      anchor.href = itemUrl.file_path_url;
      anchor.download = itemUrl.file_name;

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);
    },
    pickFile(e) {
      if (e.target.files.length) {
        let files = e.target.files;
        // refresh
        this.filterFileOverSize(files);

        this.pushFile(files);
      }
    },

    pushFile(files) {
      let validExtension = this.accept
        .split(", ")
        .map((item) => item.toLowerCase().replace(".", ""));

      const checkValidExt = (mime) => {
        if (!validExtension.length) return true;
        return validExtension.includes(mime.toLowerCase());
      };

      for (let itemFile of files) {
        let extension = itemFile.name.split(".").pop();
        if (
          itemFile.size <= this.$maxUploadFileSize.byte &&
          checkValidExt(extension)
        ) {
          this.listFiles.push({
            ...defaultFile,
            file: itemFile,
            file_name: itemFile.name,
          });
        }
      }
    },

    selectFileHandler() {
      // fresh file
      this.$refs.selectMultipleFile.value = [];
      this.$refs.selectMultipleFile.click();
    },

    downloadFile(itemFile) {
      autoDownloadFromUrl(itemFile.file_path_url, itemFile.file_name);
    },

    removeItemFile(index) {
      let find = this.listFiles[index];
      if (!find.id) {
        this.listFiles.splice(index, 1, null);

        // this.listFiles = this.listFiles.filter((item, idx) => idx != index);

        // if (Object.prototype.hasOwnProperty.call(this.listFiles, `${this.keyName}.${index}.file` )) {
        //   this.$emit('remove-validation-key', `${this.keyName}.${index}.file`)
        // }
      }
    },

    removeExistFile(itemFile) {
      this.$emit("remove-exists-item", itemFile.id);
    },

    filterFileOverSize(listFiles) {
      let files = Array.from(listFiles);
      let checkFlagOverSize = files.some(
        (item) => item.size > this.$maxUploadFileSize.byte
      );
      if (checkFlagOverSize) {
        let cloneValidation = cloneDeep(this.$validation);
        cloneValidation[this.nameComponentValidation] = this.$t(
          "panel.label_maximum_file_size",
          { size: this.$maxUploadFileSize.mb }
        );
        this.$setValidation({ ...cloneValidation });
      } else {
        let cloneValidation = cloneDeep(this.$validation);
        delete cloneValidation[this.nameComponentValidation];
        this.$setValidation({ ...cloneValidation });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.review__img__wrapper {
  display: block;
  position: relative;
  height: 150px;
  width: 150px;

  .remove__icon {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
  }
}
.select__file__button__wrapper {
  display: flex;
  gap: 5px;
  min-width: 0;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);

  &.not__error {
    border-color: #ced4da;
  }

  &-select__button,
  &-file__name {
    display: flex;
    height: 100%;
    padding: 8px 12px;
  }

  &-select__button {
    text-align: center;
    border-right: 1px solid #ced4da;
    word-break: keep-all;
  }

  &-file__name {
    width: 80%;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
.display__file__name__wrapper {
  display: block;
  width: 100%;

  &-file__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin: 5px 0;

    &-file__name {
      width: 90%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-remove__file {
      cursor: pointer;
    }
  }
}
</style>
