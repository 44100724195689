import store from "@/store";
import TheLoginLayout from "@/layouts/TheLoginLayout";
import facilities from "./facility";
import plan from "./plan";
import contractPlan from "./contract-plan";
import masterMenu from "./master-menu";
import paymentAgencies from "./payment-agencies";
import facilityManagement from "./FacilityManagement";
import { checkAuthAdminLifeTime } from "@/helpers/authenticate";
import { auth as authAdmin } from "@/middlewares/admin";
import Unauthorized from "@/views/Errors/401.vue";
import NotFound from "@/views/Errors/404.vue";

export default [
  {
    path: "/admin",
    component: () => import("@/layouts/TheContent"),
    beforeEnter: (to, from, next) => {
      document.querySelector("title").textContent = "EDICシステムリニューアル";
      /* must call `next` */
      store.commit("AuthManage/SET_IS_SCREEN_MANAGE", false);
      if (
        (process.env.VUE_APP_ADMIN_URL &&
          window.location.host == process.env.VUE_APP_ADMIN_URL) ||
        /^localhost/.test(window.location.host)
      )
        next();
      else next({ path: "/" });
    },
    children: [
      {
        path: "login",
        name: "EdicAdminLogin",
        component: TheLoginLayout,
        beforeEnter: (to, from, next) => {
          let isAuthManage = checkAuthAdminLifeTime();
          if (isAuthManage) {
            next({ name: "EdicOverView" });
          }
          next();
        },
      },
      {
        path: "/",
        name: "EdicAdminHome",
        component: () => import("@/layouts/TheEdicAdminDashboard"),
        redirect: { name: "EdicOverView" },
        children: [
          {
            path: "overview",
            name: "EdicOverView",
            component: () => import("@/layouts/TheEdicAdminGlobalContent"),
            meta: {
              middleware: [authAdmin],
            },
          },
          facilities,
          plan,
          contractPlan,
          masterMenu,
          paymentAgencies,
          facilityManagement,
          {
            path: "test",
            component: () =>
              import("@/views/EdicAdmin/PickIcons/PickIconWorkFlow"),
          },
          {
            path: "unauthorized",
            name: "AdminPageUnauthorized",
            component: Unauthorized,
          },
        ],
      },
      {
        path: ":pathMatch(.*)*",
        name: "AdminPageNotFound",
        component: NotFound,
      },
    ],
  },
];
