<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <b-col cols="12">
          <slot name="header-content-view">
            <p class="h3 font-weight-bold title__page">{{ titleScreen }}</p>
          </slot>

          <!-- base alert system -->
          <base-alert-system
            v-if="useAlertSystem && alert.show"
            :message="alert.message"
            :error="alert.error"
            @hide-alert="
              () => {
                alert.show = false;
              }
            "
          />

          <!-- wrap it -->
          <slot>
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </slot>

          <slot name="footer-content-view"></slot>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_FACILITY_INFO } from "@/store/modules/Facility/types";
import { GET_FACILITY_MENU_ID } from "@/store/modules/ManagePermissionScreen/types";
import { GET_PERMISSION_MENU } from "@/store/modules/AuthManage/types";
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "BaseAdminContentView",
  props: {
    useAlertSystem: {
      type: Boolean,
      default: true,
    },
    titlePage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      alert: {
        show: false,
        error: false,
        message: "",
      },
      titleScreen: "",
    };
  },
  computed: {
    ...mapGetters("Facility", {
      facilityInfo: GET_FACILITY_INFO,
    }),
    ...mapGetters("ManagePermissionScreen", {
      facilityMenuId: GET_FACILITY_MENU_ID,
    }),
    ...mapGetters("AuthManage", {
      permissionMenu: GET_PERMISSION_MENU,
    }),
    titlePageMenu() {
      return (
        this.permissionMenu.find(
          (el) => el.facility_menu_id == this.facilityMenuId
        )?.title || ""
      );
    },
  },
  watch: {
    titlePage() {
      this.setTitlePage();
    },
    titlePageMenu() {
      this.setTitlePage();
    },
  },
  mounted() {
    this.$eventBus.$on("update-alert-system", this.handleAlertSystem);
    this.setTitlePage();
  },
  methods: {
    handleAlertSystem({ error, message }) {
      this.alert = {
        show: true,
        error: error,
        message: message,
      };
    },
    setTitlePage() {
      this.titleScreen = isEmpty(this.titlePage)
        ? this.titlePageMenu
        : this.titlePage;
      document.title = this.titleScreen || this.facilityInfo?.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.title__page {
  padding: 0;
  margin: 0.8rem 0;
}
</style>
