import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "hosts",
  name: "HostManage",
  component: () =>
    import(
      /* webpackChunkName: "host-layout-view" */ "@/views/Manage/Host/LayoutView.vue"
    ),
  redirect: { name: "HostSearch" },
  children: [
    {
      path: "",
      name: "HostSearch",
      component: () =>
        import(
          /* webpackChunkName: "host-searching-view" */ "@/views/Manage/Host/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/hosts/create
      name: "HostCreate",
      component: () =>
        import(
          /* webpackChunkName: "host-create-view" */ "@/views/Manage/Host/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/hosts/edit/1
      name: "HostEdit",
      component: () =>
        import(
          /* webpackChunkName: "host-edit-view" */ "@/views/Manage/Host/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
