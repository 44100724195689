export default {
  bind: function (el) {
    let isDown = false;
    let startX;
    let scrollLeft;

    el.addEventListener("mousedown", (e) => {
      isDown = true;
      startX = e.pageX - el.offsetLeft;
      scrollLeft = el.scrollLeft;
    });
    el.addEventListener("mouseleave", () => {
      isDown = false;
    });
    el.addEventListener("mouseup", () => {
      isDown = false;
    });
    el.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - el.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      el.scrollLeft = scrollLeft - walk;
    });
  },
};
