export default {
  props: {
    value: {
      type: null,
      description: "Value return by v-model",
    },
    validationResult: {
      type: Object,
      description:
        "It is an object validation from backend laravel when get response with status 422",
      default: function () {
        return {};
      },
    },
    keyName: {
      type: String,
      description: "Key name as a input name",
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorValidation: {
      type: Boolean,
      default: false,
    },
    errorFetch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isFailValidation() {
      return Object.prototype.hasOwnProperty.call(
        this.validationResult,
        this.keyName
      );
    },
    textValidation() {
      if (this.isFailValidation) {
        return this.validationResult[this.keyName];
      }
      return "";
    },
    isFailValidationCheckboxState() {
      if (
        Object.prototype.hasOwnProperty.call(
          this.validationResult,
          this.keyName
        )
      ) {
        return !this.isFailValidation;
      }
      return null;
    },
  },
};
