import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "functional-menu",
  name: "EdicAdminFacilityFunctionMenuManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-functional-menu-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/FunctionalMenu/Layout"
    ),
  redirect: { name: "EdicAdminFacilitySettingFunctionalMenu" },
  children: [
    {
      path: "setting-functional-management", // /admin/facility/manage/functional-menu/setting-functional-management
      name: "EdicAdminFacilitySettingFunctionalMenu",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-functional-menu-functional-management" */ "@/views/EdicAdmin/Facilities/Manage/FunctionalMenu/SettingFunctionalMenu"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
