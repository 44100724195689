var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group d-flex flex-column",class:{
    'input-group': _vm.hasIcon,
    'input-group-focus': _vm.focused,
  }},[_vm._t("label",function(){return [(_vm.label)?_c('div',{staticClass:"control-label d-block",class:{ 'font-weight-bold': _vm.bold }},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('label',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e()]}),_c('div',{staticClass:"d-flex w-100 align-items-center position-relative",staticStyle:{"gap":"10px"}},[_vm._t("addonLeft",function(){return [(_vm.addonLeftIcon)?_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_c('i',{class:_vm.addonLeftIcon})])]):_vm._e()]}),_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({directives:[{name:"show-password",rawName:"v-show-password",value:({
          inputType: _vm.$attrs.type,
          showPassword: _vm.showPassword,
        }),expression:"{\n          inputType: $attrs.type,\n          showPassword: showPassword,\n        }"}],staticClass:"form-control",class:{
          'is-invalid':
            _vm.failInput ||
            Object.prototype.hasOwnProperty.call(_vm.validationResult, _vm.keyName),
        },attrs:{"aria-describedby":"addon-right addon-left","placeholder":_vm.$t('common.label_placeholder_text')},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners)),(_vm.$attrs.type == 'password')?_c('span',{ref:"ShowPasswordIcon",staticClass:"eye-icon_parent",on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('i',{staticClass:"fas fa-eye eye-icon_child"})]):_vm._e()]}),_vm._t("addonRight",function(){return [(_vm.addonRightIcon)?_c('span',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text"},[_c('i',{class:_vm.addonRightIcon})])]):_vm._e()]})],2),_vm._t("errorInput",function(){return [_c('div',{staticClass:"d-block mt-1 is-invalid-feedback-message"},[_c('span',{directives:[{name:"error-input-text",rawName:"v-error-input-text",value:({ validation: _vm.validationResult, keyName: _vm.keyName }),expression:"{ validation: validationResult, keyName }"}]})])]}),_vm._t("helperText")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }