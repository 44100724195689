var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 text-wrap"},[_c('div',{staticClass:"d-flex align-items-center align-content-between"},[_c('div',{staticClass:"btn-choose-file"},[_c('b-button',{attrs:{"disabled":_vm.disabled},on:{"click":_vm.selectFileHandler}},[_vm._v(_vm._s(_vm.$t("panel.label_browse_file")))])],1),_c('div',{staticClass:"display-name"},[(_vm.displayFileName)?_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"mb-0 pr-0 pl-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex g-5 justify-content-between"},[_c('div',{staticClass:"file-name"},[_c('a',{attrs:{"href":_vm.sourceFilePath,"download":"","target":"_blank"}},[_vm._v(_vm._s(_vm.displayFileName))])]),(!_vm.disabled)?_c('div',{staticClass:"text-danger cursor-pointer",on:{"click":_vm.removeFile}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])])],1)],1):_vm._e()],1)]),_c('b-form-file',{ref:"selectFileRef",staticClass:"d-none",attrs:{"value":_vm.value,"accept":_vm.accept,"disabled":_vm.disabled,"state":!_vm.isFailValidation,"browse-text":_vm.$t('panel.label_browse_file'),"placeholder":_vm.$t('panel.label_please_choose_one_file')},on:{"change":_vm.pickFile}}),(
      !(
        _vm.$validation[_vm.validateFileSizeName] ||
        _vm.$validation[_vm.validateFileExtension]
      )
    )?_c('div',{directives:[{name:"error-input-text",rawName:"v-error-input-text",value:({
      validation: _vm.$validation,
      keyName: _vm.keyName,
    }),expression:"{\n      validation: $validation,\n      keyName: keyName,\n    }"}],staticClass:"is-invalid-feedback-message"}):_vm._e(),_c('div',[_c('span',{directives:[{name:"error-input-text",rawName:"v-error-input-text",value:({
        validation: _vm.$validation,
        keyName: _vm.validateFileSizeName,
      }),expression:"{\n        validation: $validation,\n        keyName: validateFileSizeName,\n      }"}]}),_c('span',{directives:[{name:"error-input-text",rawName:"v-error-input-text",value:({
        validation: _vm.$validation,
        keyName: _vm.validateFileExtension,
      }),expression:"{\n        validation: $validation,\n        keyName: validateFileExtension,\n      }"}]})]),_c('div',{staticClass:"d-block hint__text"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.hintText)}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }