import { SET_RELATED_OPTIONS, SET_LIST_ACCOUNT_CMS_GROUP } from "./types";

export default {
  [SET_RELATED_OPTIONS]: (state, payload) => {
    state.related_options = payload;
  },
  [SET_LIST_ACCOUNT_CMS_GROUP]: (state, payload) => {
    state.account_cms_groups = payload;
  },
};
