import Store from "@/store";
import { GET_VALIDATION, SET_VALIDATION } from "@/store/types";

// import { Store } from "vuex";

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $validation() {
          return Store.getters[GET_VALIDATION];
        },
      },
      methods: {
        $refreshValidation() {
          Store.commit(SET_VALIDATION, {});
        },
        $setValidation(data) {
          Store.commit(SET_VALIDATION, data);
        },
        $mergeValidation(newValidationMerge) {
          let currentValidation = { ...this.$validation };
          let newValidation = {
            ...currentValidation,
            ...newValidationMerge,
          };
          this.$setValidation(newValidation);
        },
      },
    });
  },
};
