<template>
  <div class="d-block">
    <indicator-page v-if="loadingDomain" />
    <div v-if="!loadingDomain && errorDomain">
      <error-page />
    </div>
    <div
      v-if="!loadingDomain && !errorDomain"
      class="container__wrapper__custom"
      :class="{
        'nav-open': $sidebar.showSidebar,
        loading__page: loadingPage,
      }"
    >
      <transition name="fade">
        <indicator-page v-show="loadingPage"></indicator-page>
      </transition>
      <base-notifications></base-notifications>
      <transition name="fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
import eventBus from "@/helpers/eventBus";
import $ from "jquery";
import useCheckingValidDomain from "./hooks/useCheckingValidDomain";

export default {
  name: "App",
  components: {
    IndicatorPage: () => import("./components/Indicator/IndicatorPage.vue"),
    ErrorPage: () => import("./views/Errors/404.vue"),
  },
  setup() {
    let { loading: loadingDomain, error: errorDomain } =
      useCheckingValidDomain();
    return {
      loadingDomain,
      errorDomain,
    };
  },
  data() {
    return {
      loadingPage: false,
    };
  },

  computed: {
    $isLoadingPage() {
      return this.loadingPage;
    },
  },
  watch: {
    loadingPage() {
      this.$emit("loading-page", this.loadingPage);
    },
  },
  mounted() {
    eventBus.$on("asyncComponentLoading", this.start);
    eventBus.$on("asyncComponentLoaded", this.stop);

    window.addEventListener("scroll", this.fixSelect2PluginWithFixedTop);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.fixSelect2PluginWithFixedTop);
  },

  methods: {
    start() {
      this.loadingPage = true;
    },
    stop() {
      this.loadingPage = false;
    },
    fixSelect2PluginWithFixedTop() {
      function debounce(func, wait) {
        let timeout;
        return function (...args) {
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(this, args), wait);
        };
      }

      // Create a debounced function reference
      const debouncedLog = debounce(() => {
        const fixedHeader = document.getElementById(
          "edic__main__top__navbar__wrapper"
        );
        if (fixedHeader) {
          let element = document.querySelector(".select2-container--open");
          if (element) {
            let select = element
              .closest(".select2")
              .closest(".select2").previousSibling;
            $(select).select2("close");
          }
        }
      }, 200);

      debouncedLog();
    },
  },
};
</script>

<style lang="scss" scoped>
.container__wrapper__custom {
  // min-height: 100vh;
  // height: 100%;
  position: relative;
  // min-height: -webkit-fill-available;

  &.loading__page {
    height: 100vh;
    overflow: hidden;
  }
}
.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }
  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
