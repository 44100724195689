// actions
export const ACT_EXPORT_CSV_EVENT_PARTICIPANT =
  "ACT_EXPORT_CSV_EVENT_PARTICIPANT";

// mutations
export const SET_PROCESS_EXPORT_CSV_FILE = "SET_PROCESS_EXPORT_CSV_FILE";

// getters
export const GET_PROCESS_EXPORT_CSV_FILE = "GET_PROCESS_EXPORT_CSV_FILE";

export const ACT_IMPORT_CSV = "ACT_IMPORT_CSV";

export const SET_REFRESH_IMPORT_CSV_FILE = "SET_REFRESH_IMPORT_CSV_FILE";

export const GET_IMPORT_CSV_CONTENT = "GET_IMPORT_CSV_CONTENT";
