import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "payment-adjustment",
  name: "PaymentAdjustmentManage",
  component: () =>
    import(
      /* webpackChunkName: "payment-adjustment-manage-layout-view" */ "@/views/Manage/PaymentAdjustment/LayoutView.vue"
    ),
  redirect: { name: "PaymentAdjustmentSearch" },
  children: [
    {
      path: "", // /manage/payment-adjustment
      name: "PaymentAdjustmentSearch",
      component: () =>
        import(
          /* webpackChunkName: "payment-adjustment-manage-searching-view" */ "@/views/Manage/PaymentAdjustment/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/payment-adjustment/create
      name: "PaymentAdjustmentCreate",
      component: () =>
        import(
          /* webpackChunkName: "payment-adjustment-manage-create-view" */ "@/views/Manage/PaymentAdjustment/Create.vue"
        ),
      props: { default: true },
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/payment-adjustment/edit/1
      name: "PaymentAdjustmentEdit",
      component: () =>
        import(
          /* webpackChunkName: "payment-adjustment-manage-edit-view" */ "@/views/Manage/PaymentAdjustment/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
