<template>
  <div class="permission__deny__component">
    <div class="permission__deny__component-content">
      <h2
        class="permission__deny__component-content-title text-danger font-weight-bold"
      >
        {{ $t("validation.access_denied") }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "PermissionDenyComponent",
};
</script>

<style scoped lang="scss">
.permission__deny__component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 60vh;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70%;

    &-straight {
      width: 50%;
      height: 1px;
      border: 1px solid #000;
    }
  }
}
</style>
