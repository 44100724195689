import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "payment-adjustment-conditions",
  name: "EdicAdminFacilityPaymentAdjustmentConditionManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-payment-adjustment-condition-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/PaymentAdjustmentCondition/LayoutView"
    ),
  redirect: { name: "EdicAdminFacilityPaymentAdjustmentConditionSearch" },
  children: [
    {
      path: "", // /admin/payment-adjustment-conditions
      name: "EdicAdminFacilityPaymentAdjustmentConditionSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-payment-adjustment-condition-searching-view" */ "@/views/EdicAdmin/Facilities/Manage/PaymentAdjustmentCondition/Search.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create", // /admin/payment-adjustment-conditions/create
      name: "EdicAdminFacilityPaymentAdjustmentConditionCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-payment-adjustment-condition-create-view" */ "@/views/EdicAdmin/Facilities/Manage/PaymentAdjustmentCondition/Create.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id", //  /admin/payment-adjustment-conditions/edit/1
      name: "EdicAdminFacilityPaymentAdjustmentConditionEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-payment-adjustment-condition-edit-view" */ "@/views/EdicAdmin/Facilities/Manage/PaymentAdjustmentCondition/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
