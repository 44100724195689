import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  facility: null,
  app: null,
  payment: null,
  menu: null,
  app_registry_new_member: 0,
  account: null,
  is_menu_overlay: false,
  facility_settings: {},
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
