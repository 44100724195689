export default {
  bind: function (el, binding) {
    let { min = 0, max = 255 } = binding.value;

    el.handleInput = el.handleChange = (event) => {
      const input = event.target;
      if (input.value) {
        if (input.value < min) {
          input.value = min;
        }
        if (input.value > max) {
          input.value = max;
        }
        if (Number(input.value) === 0) {
          input.value = 0;
        }
      }
      // else {
      //   console.log('down')

      //   input.value = '';
      // }
    };

    el.addEventListener("input", el.handleInput);
    el.addEventListener("change", el.handleChange);
    el.addEventListener("blur", el.handleChange);
  },
};
