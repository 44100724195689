<template>
  <div>
    <!-- zip_code -->
    <base-input-group :title="zipcodeDefaultLabel" :required="requiredZipcode">
      <template #input>
        <zipcode-input
          v-model="data.zipcode"
          :disabled="disabled || disabledAll"
          :loading="loading"
          :key-name="keyNameZipcode"
          :validation-result="validationResult"
          @zipcode-click="getZipCodeInfo"
        ></zipcode-input>
      </template>
    </base-input-group>

    <!-- pref_code -->
    <base-input-group
      :title="precodeDefaultLabel"
      :required="requiredPrefcode"
      :error-validation="errorValidation"
    >
      <template #input>
        <div class="col-xl-6 col-sm-12 p-0">
          <province-selection
            v-model="data.pref_code"
            :map-text-record="true"
            :text-record="zipCodeInfo.pref_name || ''"
            :disabled="disabled || disabledAll"
            :loading-zip-code="loading"
            :validation-result="validationResult"
            key-name="pref_code"
          >
          </province-selection>
        </div>
      </template>
    </base-input-group>

    <!-- city_code -->
    <base-input-group
      :title="citycodeDefaultLabel"
      :required="requiredCitycode"
      :error-validation="errorValidation"
    >
      <template #input>
        <div class="col-xl-6 col-sm-12 p-0">
          <city-selection
            v-model="data.city_code"
            :pref-code="data.pref_code"
            :map-text-record="true"
            :text-record="zipCodeInfo.city_name || ''"
            :disabled="disabled || disabledAll"
            :loading-zip-code="loading"
            :validation-result="validationResult"
            key-name="city_code"
          >
          </city-selection>
        </div>
      </template>
    </base-input-group>

    <!-- address -->
    <base-input-group
      v-model="data.address"
      :title="$t('panel.label_common_address')"
      :required="requiredAddress"
      :key-name="keyNameAddress"
      :validation-result="validationResult"
      :label-middle-center="false"
      :props-input="{
        maxlength: 255,
        disabled: disabledAll,
      }"
      :error-validation="errorValidation"
      :disabled-input="disabled"
    />

    <!-- building name -->
    <base-input-group
      v-model="data.building_name"
      :required="requiredBuildingName"
      :title="$t('panel.label_common_building_address')"
      :key-name="keyNameBuildingName"
      :validation-result="validationResult"
      :props-input="{
        maxlength: 255,
        disabled: disabledAll,
      }"
      :error-validation="errorValidation"
    />
  </div>
</template>
<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import ZipcodeInput from "./ZipcodeInput.vue";
import ProvinceSelection from "@/components/Selections/ProvinceSelection.vue";
import CitySelection from "@/components/Selections/CitySelection.vue";
import _ from "lodash";
import commonZipcodeService from "@/services/common/zipcode";

class zipcodePrefCity {
  constructor() {
    this.zipcode = "";
    this.pref_code = "";
    this.city_code = "";
    this.address = "";
    this.building_name = "";
  }
}

export default {
  name: "ZipcodePrefCityInput",
  components: {
    ZipcodeInput,
    ProvinceSelection,
    CitySelection,
  },
  mixins: [handleValidateMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default: function () {
        return new zipcodePrefCity();
      },
      description: "zipcode prefectures  city",
    },
    zipcodeLabel: {
      type: String,
      default: "",
    },
    prefcodeLabel: {
      type: String,
      default: "",
    },
    citycodeLabel: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledAll: {
      type: Boolean,
      default: false,
    },
    keyNamePrefix: {
      type: String,
      default: "",
    },
    requiredZipcode: {
      type: Boolean,
      default: false,
    },
    requiredPrefcode: {
      type: Boolean,
      default: false,
    },
    requiredCitycode: {
      type: Boolean,
      default: false,
    },
    requiredAddress: {
      type: Boolean,
      default: false,
    },
    requiredBuildingName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zipCodeInfo: {
        pref_name: "",
        city_name: "",
        area_name: "",
      },
      loading: false,
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    zipcodeDefaultLabel() {
      return this.zipcodeLabel || this.$t("organization.zipcode");
    },
    precodeDefaultLabel() {
      return this.prefcodeLabel || this.$t("organization.pref_code");
    },
    citycodeDefaultLabel() {
      return this.citycodeLabel || this.$t("organization.city_code");
    },
    keyNameAddress() {
      return this.keyName ? this.keyName + ".address" : "address";
    },
    keyNameBuildingName() {
      return this.keyName ? this.keyName + ".building_name" : "building_name";
    },
    keyNameZipcode() {
      return this.keyNamePrefix ? this.keyNamePrefix + ".zipcode" : "zipcode";
    },
  },
  watch: {
    "data.pref_code"() {
      if (_.isEmpty(this.data.pref_code)) {
        this.resetInfoZipcode();
        this.resetData();
      }
    },
  },
  created() {
    let zipcodePrefCityInstance = new zipcodePrefCity();

    for (let prop in zipcodePrefCityInstance) {
      if (!Object.prototype.hasOwnProperty.call(this.value, prop)) {
        this.data[prop] = zipcodePrefCityInstance[prop];
      }
    }
  },
  methods: {
    // get data info from zipcode
    async getZipCodeInfo() {
      try {
        this.loading = true;
        this.resetData();
        this.resetInfoZipcode();
        let res = await commonZipcodeService.getInfo(this.data.zipcode);
        this.zipCodeInfo = res;
        this.data.address = this.zipCodeInfo.area_name;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    resetInfoZipcode() {
      this.zipCodeInfo = {
        pref_name: "",
        city_name: "",
        area_name: "",
      };
    },
    resetData() {
      this.data.pref_code = "";
      this.data.city_code = "";
      this.data.address = "";
    },
  },
};
</script>
<style></style>
