import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "facilities",
  name: "EdicFacilitiesManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facilities-layout-view" */ "@/views/EdicAdmin/Facilities/LayoutView.vue"
    ),
  redirect: { name: "EdicFacilitiesSearch" },
  children: [
    {
      path: "", // /new-admin/facilities
      name: "EdicFacilitiesSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facilities-searching-view" */ "@/views/EdicAdmin/Facilities/Search.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create", // /new-admin/facilities/create
      name: "EdicFacilitiesCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facilities-create-view" */ "@/views/EdicAdmin/Facilities/Create.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id", // /admin/plan/edit/1
      name: "EdicFacilitiesEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facilities-edit-view" */ "@/views/EdicAdmin/Facilities/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
