<template>
  <div class="d-block">
    <template v-if="$isCanEdit">
      <slot></slot>
    </template>
    <template v-else>
      <permission-deny-component />
    </template>
  </div>
</template>

<script>
export default {
  name: "BaseManagePermissionCreateView",
  components: {
    PermissionDenyComponent: () =>
      import("@/components/Errors/PermissionDenyComponent.vue"),
  },
};
</script>

<style></style>
