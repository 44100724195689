import { bffServiceInstance } from "@/plugins/api_service/services";

const prefix = "/admin/cities";

export default {
  getList: (query) =>
    bffServiceInstance.get(`${prefix}/get-select-list`, query),
  validateCode: (city_code) =>
    bffServiceInstance.post(`${prefix}/validate`, {}, { city_code }),
};
