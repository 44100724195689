import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "free-mails",
  name: "FreeMailTemplateManage",
  component: () =>
    import(
      /* webpackChunkName: "free-mail-template-manage-layout-view" */ "@/views/Manage/FreeMailTemplate/LayoutView.vue"
    ),
  redirect: { name: "FreeMailTemplateSearch" },
  children: [
    {
      path: "", // /manage/free-mails
      name: "FreeMailTemplateSearch",
      component: () =>
        import(
          /* webpackChunkName: "free-mail-template-manage-searching-view" */ "@/views/Manage/FreeMailTemplate/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/free-mails/create
      name: "FreeMailTemplateCreate",
      component: () =>
        import(
          /* webpackChunkName: "free-mail-template-manage-create-view" */ "@/views/Manage/FreeMailTemplate/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/free-mails/edit/1
      name: "FreeMailTemplateEdit",
      component: () =>
        import(
          /* webpackChunkName: "free-mail-template-manage-edit-view" */ "@/views/Manage/FreeMailTemplate/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
        disableScroll: true,
      },
    },
  ],
};
