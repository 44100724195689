import { nextTick, onMounted, ref, computed } from "vue";

export default function useCalculateAlertIncompleteNavbar() {
  const defaultPadding = 10;
  const heightHeaderMenu = ref(0);
  const heightNavbarTop = ref(0);
  const heightNavbarAlertIncomplete = ref(0);
  const heightAlertRenewExpiredDate = ref(0);

  const calculatePaddingTop = computed(
    () => heightHeaderMenu.value + heightNavbarTop.value
  );

  const calculateContentPrimaryPaddingTop = computed(() =>
    heightHeaderMenu.value > 0
      ? heightNavbarAlertIncomplete.value +
        heightAlertRenewExpiredDate.value +
        defaultPadding
      : heightNavbarAlertIncomplete.value +
        heightAlertRenewExpiredDate.value +
        defaultPadding
  );

  onMounted(() => {
    nextTick(() => {
      getHeightOfElements();
    });
  });

  const getHeightOfElements = () => {
    let elementHeaderMenu = document.getElementsByClassName(
        "menu__container__wrapper"
      )[0],
      elementTopNavbar = document.getElementsByClassName(
        "navbar__container__wrapper "
      )[0],
      elementAlertProfileNavbar = document.getElementsByClassName(
        "alert_profile_incomplete"
      )[0],
      elementAlertRenewExpiredDate = document.getElementsByClassName(
        "alert_nenew_expired_date"
      )[0];
    if (elementHeaderMenu) {
      heightHeaderMenu.value = elementHeaderMenu.offsetHeight;
    }
    if (elementTopNavbar) {
      heightNavbarTop.value = elementTopNavbar.offsetHeight;
    }
    if (elementAlertProfileNavbar) {
      heightNavbarAlertIncomplete.value =
        elementAlertProfileNavbar.offsetHeight;
    }
    if (elementAlertRenewExpiredDate) {
      heightAlertRenewExpiredDate.value =
        elementAlertRenewExpiredDate.offsetHeight;
    }
  };

  return {
    heightHeaderMenu,
    heightNavbarTop,
    calculatePaddingTop,
    calculateContentPrimaryPaddingTop,
    getHeightOfElements,
  };
}
