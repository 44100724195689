import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  exportCsv: {
    loading: false,
    error: false,
  },
  importCsv: {
    file: null,
    loading: false,
    csv_content: {
      data: [],
      meta: {},
      errors: [],
    },
    validation: {},
    validation_source: null,
    error: false,
    success: false,
    total_insert: 0,
    total_updated: 0,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
