<template>
  <nav
    class="navbar navbar-expand-lg d-lg-none"
    :class="!hideTopNav ? 'd-lg-block' : ''"
  >
    <div class="container-fluid">
      <div class="navbar-brand m-0 p-0">
        <ul class="navbar-nav d-lg-none">
          <the-import-progressing-stack />
          <the-export-progressing-stack />
        </ul>
      </div>
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <!-- <ul class="nav navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="dropdown">
              <i class="nc-icon nc-palette"></i>
            </a>
          </li>
          <base-dropdown tag="li">
            <template slot="title">
              <i class="nc-icon nc-planet"></i>
              <b class="caret"></b>
              <span class="notification">5</span>
            </template>
            <a class="dropdown-item" href="#">Notification 1</a>
            <a class="dropdown-item" href="#">Notification 2</a>
            <a class="dropdown-item" href="#">Notification 3</a>
            <a class="dropdown-item" href="#">Notification 4</a>
            <a class="dropdown-item" href="#">Another notification</a>
          </base-dropdown>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nc-icon nc-zoom-split"></i>
              <span class="d-lg-block">&nbsp;Search</span>
            </a>
          </li>
        </ul> -->
        <ul class="navbar-nav ml-auto">
          <!-- import progress -->
          <the-import-progressing-stack />
          <!-- export progress -->
          <the-export-progressing-stack />
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">
              Account
            </a>
          </li> -->
          <!-- <base-dropdown title="Processing">
            <a class="dropdown-item"></a>
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something</a>
            <div class="divider"></div>
            <a class="dropdown-item" href="#">Separated link</a>
          </base-dropdown> -->
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              Log out
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import {
  GET_PROCESS_EXPORT_CSV_FILE,
  GET_PROCESS_IMPORT_CSV_FILE,
} from "@/store/modules/ManageMember/types";
import { GET_LIST_PROGRESS_MANAGEMENT_STACK } from "@/store/modules/ExportProgressing/types";
import { GET_LIST_IMPORT_PROGRESS_MANAGEMENT_STACK } from "@/store/modules/ImportProgressing/types";
import TheExportProgressingStack from "./TheExportProgressingStack.vue";
import TheImportProgressingStack from "./TheImportProgressingStack.vue";

export default {
  name: "TheTopNavbar",
  components: {
    TheExportProgressingStack,
    TheImportProgressingStack,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapGetters("ExportProgressing", {
      listTaskExport: GET_LIST_PROGRESS_MANAGEMENT_STACK,
    }),
    ...mapGetters("ImportProgressing", {
      listTaskImport: GET_LIST_IMPORT_PROGRESS_MANAGEMENT_STACK,
    }),
    ...mapGetters("ManageMember", {
      manageMemberExportCsv: GET_PROCESS_EXPORT_CSV_FILE,
      manageMemberImportCsv: GET_PROCESS_IMPORT_CSV_FILE,
    }),
    isDowloadManageMemberCsv() {
      return this.manageMemberExportCsv.loading;
    },
    isImportingManageMemberCsv() {
      return this.manageMemberImportCsv.loading;
    },
    hideTopNav() {
      return !this.listTaskImport.length && !this.listTaskExport.length;
    },
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
<style></style>
