<template>
  <div class="w-100 text-wrap">
    <div class="d-flex align-items-center align-content-between">
      <div class="btn-choose-file">
        <b-button :disabled="disabled" @click="selectFileHandler">{{
          $t("panel.label_browse_file")
        }}</b-button>
      </div>
      <div class="display-name">
        <b-container v-if="displayFileName">
          <b-row>
            <b-col cols="12" class="mb-0 pr-0 pl-0">
              <div class="d-flex g-5 justify-content-between">
                <div class="file-name">
                  <a :href="sourceFilePath" download target="_blank">{{
                    displayFileName
                  }}</a>
                </div>
                <div
                  v-if="!disabled"
                  class="text-danger cursor-pointer"
                  @click="removeFile"
                >
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <b-form-file
      ref="selectFileRef"
      :value="value"
      :accept="accept"
      :disabled="disabled"
      :state="!isFailValidation"
      class="d-none"
      :browse-text="$t('panel.label_browse_file')"
      :placeholder="$t('panel.label_please_choose_one_file')"
      @change="pickFile"
    />
    <div
      v-if="
        !(
          $validation[validateFileSizeName] ||
          $validation[validateFileExtension]
        )
      "
      v-error-input-text="{
        validation: $validation,
        keyName: keyName,
      }"
      class="is-invalid-feedback-message"
    ></div>
    <div>
      <span
        v-error-input-text="{
          validation: $validation,
          keyName: validateFileSizeName,
        }"
      ></span>
      <span
        v-error-input-text="{
          validation: $validation,
          keyName: validateFileExtension,
        }"
      ></span>
    </div>
    <div class="d-block hint__text">
      <div v-html="hintText"></div>
    </div>
  </div>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import { btnBgColor } from "@/constants/settingPropsLayout";
import cloneDeep from "lodash/cloneDeep";
import { convertFileTypeToActualExtension } from "@/helpers/common";

export default {
  name: "BaseInputFileLite",
  mixins: [handleValidateMixin],
  props: {
    value: null,
    accept: {
      type: String,
      default: "*",
    },
    btnBgColor: btnBgColor,
    compareSize: {
      type: Boolean,
      default: false,
    },
    maxMb: {
      type: Number,
      default: 0,
    },
    fileName: {
      type: String,
      default: "",
    },
    labelInputFile: {
      type: String,
      default: "",
    },
    hintText: {
      type: String,
      default: "",
    },
    sourceFilePath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      file: null,
      uidComponent: null,
    };
  },
  computed: {
    displayFileName() {
      if (this.fileName) {
        return this.fileName;
      }
      return this.file?.name || "";
    },
    validateFileSizeName() {
      return `base_input_file_error_file_size_${this.uidComponent}`;
    },
    validateFileExtension() {
      return `base_input_file_error_file_extension_${this.uidComponent}`;
    },
  },

  created() {
    this.uidComponent = this._uid;
    if (this.value instanceof File) {
      this.file = this.value;
    }
  },

  methods: {
    pickFile(e) {
      this.removeFile();
      if (e.target.files.length) {
        const fileUpload = e.target.files[0];
        // refresh validate local
        this.formatValidateFile();

        //
        if (
          this.validateExtension(fileUpload) &&
          this.isValidFileSize(fileUpload)
        ) {
          this.file = fileUpload;
          // check file size

          if (this.file.size <= this.$maxUploadFileSize.gb.size) {
            this.$emit("input", this.file);
            this.$emit("update:file-name", this.file.name);
          } else {
            this.$emit("input", null);
            this.file = null;
            this.reviewImg = null;
          }
        }
        this.$refs.selectFileRef.reset();
      }
    },
    removeFile() {
      this.file = null;
      this.$emit("input", null);
      this.$emit("update:file-name", "");
      this.$emit("update:source-file-path", "");
    },

    selectFileHandler() {
      this.$refs.selectFileRef.$el.querySelector(".custom-file-input").click();
    },

    isValidFileSize(file) {
      if (file.size > this.$maxUploadFileSize.gb.size) {
        let cloneValidation = cloneDeep(this.$validation);

        cloneValidation[`${this.validateFileSizeName}`] = this.$t(
          "panel.label_upload_file_size_fail_size",
          {
            size: this.$maxUploadFileSize.gb.text,
            name: this.labelInputFile,
          }
        );
        this.$setValidation({
          ...cloneValidation,
        });
        return false;
      } else {
        let cloneValidation = cloneDeep(this.$validation);
        delete cloneValidation[`${this.validateFileSizeName}`];
        this.$setValidation({
          ...cloneValidation,
        });
        return true;
      }
    },

    validateExtension(file) {
      let extFile = convertFileTypeToActualExtension(file.type);
      if (this.accept === "*") {
        return true;
      } else {
        let extensions = this.accept
          .split(",")
          .map((item) => item.trim().replace(".", ""));
        let validExt = extensions.includes(extFile);
        if (!validExt) {
          let cloneValidation = cloneDeep(this.$validation);
          cloneValidation[`${this.validateFileExtension}`] = this.$t(
            "panel.validate_file_extension",
            {
              name: this.labelInputFile,
              extension: this.accept.replace(/\./g, ""),
            }
          );
          this.$setValidation({
            ...cloneValidation,
          });
        } else {
          let cloneValidation = cloneDeep(this.$validation);
          delete cloneValidation[`${this.validateFileExtension}`];
          this.$setValidation({
            ...cloneValidation,
          });
        }
        return validExt;
      }
    },

    formatValidateFile() {
      let cloneValidation = cloneDeep(this.$validation);
      delete cloneValidation[`${this.validateFileExtension}`];
      delete cloneValidation[`${this.validateFileSizeName}`];
      this.$setValidation({
        ...cloneValidation,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.review__img__wrapper {
  display: block;
  position: relative;
  height: 150px;
  width: 150px;

  .remove__icon {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
  }
}
.btn-choose-file {
  width: 130px;

  button:disabled {
    cursor: not-allowed;
  }
}
.display-name {
  width: calc(100% - 130px);
}
.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
