import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import storage from "@/helpers/storageManage";
import {
  ApplicationManageSaveOrganizationFlag,
  ApplicationManageSaveOrganizationId,
} from "@/plugins/setting";

const state = () => ({
  list_oragnizations_access: [],
  organization_info: null,
  save_for_next_login: {
    id_organization: storage.getStorage(ApplicationManageSaveOrganizationId)
      ? parseInt(storage.getStorage(ApplicationManageSaveOrganizationId))
      : null,
    save: storage.getStorage(ApplicationManageSaveOrganizationFlag),
  },
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
