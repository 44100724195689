<template>
  <div class="d-block">
    <base-input-group
      :title="displayName"
      :required="
        isRequired || requiredRelatedInput || displayAndRequiredRelatedInput
      "
      :label-middle-center="false"
      :class-custom-padding="true"
      :hint-text="remark"
      :validation-result="validationResult"
      :key-name="keyName"
      v-bind="$attrs"
    >
      <template #input>
        <div class="d-block w-100">
          <slot name="input"></slot>
        </div>
      </template>
    </base-input-group>
  </div>
</template>

<script>
import { accountCmsUseType } from "@/constants/commons";
import { generatePattern } from "@/helpers/dependencyInputComponent";

export default {
  name: "BaseManageAccountCmsInput",
  props: {
    displayName: {
      type: String,
      default: "",
    },
    basicInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    extendInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    useType: {
      type: Number,
      validator(value) {
        return [
          accountCmsUseType.required.value,
          accountCmsUseType.optional.value,
          accountCmsUseType.hide.value,
          accountCmsUseType.requiredRelated.value,
          accountCmsUseType.displayRelated.value,
          accountCmsUseType.displayAndRequiredRelated.value,
        ].includes(value);
      },
      default: null,
    },
    remark: {
      type: String,
      default: "",
    },
    validationResult: {
      type: Object,
      default: function () {
        return {};
      },
    },
    keyName: {
      type: String,
      default: "",
    },
    dependencyPatterns: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    isRequired() {
      return accountCmsUseType.required.value == this.useType;
    },
    requiredRelatedInput() {
      if (this.useType == accountCmsUseType.requiredRelated.value) {
        return generatePattern(this.dependencyPatterns, {
          basic_info: this.basicInfo,
          extend_info: this.extendInfo,
        });
      }
      return false;
    },
    displayAndRequiredRelatedInput() {
      if (this.useType == accountCmsUseType.displayAndRequiredRelated.value) {
        let validate = generatePattern(this.dependencyPatterns, {
          basic_info: this.basicInfo,
          extend_info: this.extendInfo,
        });
        if (!validate) {
          this.refreshData();
        }
        return validate;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
