import {
  GET_FACILITY_MENU_ID,
  IS_MANAGEMENT_CAN_BE_VIEWED,
  IS_MANAGEMENT_CAN_BE_EDITABLE,
} from "./types";

export default {
  // GET facility_menu_id
  [GET_FACILITY_MENU_ID]: (state) => state.management_permission_view.id,

  //  IS_MANAGEMENT_CAN_BE_VIEWED
  [IS_MANAGEMENT_CAN_BE_VIEWED]: (state) =>
    Boolean(state.management_permission_view.view_flag),

  // IS_MANAGEMENT_CAN_BE_EDITABLE
  [IS_MANAGEMENT_CAN_BE_EDITABLE]: (state) =>
    Boolean(state.management_permission_view.edit_flag),
};
