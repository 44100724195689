<template>
  <div
    class="form-group d-flex flex-column"
    :class="{
      'input-group': hasIcon,
      'input-group-focus': focused,
    }"
  >
    <slot name="label">
      <div
        v-if="label"
        class="control-label d-block"
        :class="{ 'font-weight-bold': bold }"
      >
        {{ label }}
        <label v-if="required" class="text-danger">*</label>
      </div>
    </slot>

    <!-- input form -->
    <div
      class="d-flex w-100 align-items-center position-relative"
      style="gap: 10px"
    >
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text">
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>

      <slot>
        <input
          v-show-password="{
            inputType: $attrs.type,
            showPassword: showPassword,
          }"
          :value="value"
          v-bind="$attrs"
          class="form-control"
          :class="{
            'is-invalid':
              failInput ||
              Object.prototype.hasOwnProperty.call(validationResult, keyName),
          }"
          aria-describedby="addon-right addon-left"
          :placeholder="$t('common.label_placeholder_text')"
          v-on="listeners"
        />
        <span
          v-if="$attrs.type == 'password'"
          ref="ShowPasswordIcon"
          class="eye-icon_parent"
          @click="showPassword = !showPassword"
        >
          <i class="fas fa-eye eye-icon_child"></i>
        </span>
      </slot>

      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text">
            <i :class="addonRightIcon"></i>
          </div>
        </span>
      </slot>
    </div>
    <slot name="errorInput">
      <div class="d-block mt-1 is-invalid-feedback-message">
        <span
          v-error-input-text="{ validation: validationResult, keyName }"
        ></span>
      </div>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  name: "ClientJbbfBaseInput",
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    label: {
      type: String,
      description: "Input label",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right",
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left",
    },
    bold: {
      type: Boolean,
      default: false,
    },
    failInput: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validationResult: {
      type: Object,
      default: function () {
        return {};
      },
    },
    keyName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      focused: false,
      showPassword: false,
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },
  watch: {
    showPassword() {
      if (this.showPassword) {
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.add("fa-eye-slash");
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.remove("fa-eye");
      } else {
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.add("fa-eye");
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.remove("fa-eye-slash");
      }
    },
  },
  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
};
</script>
<style>
.eye-icon_parent {
  position: absolute;
  right: 10px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
