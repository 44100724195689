/* eslint-disable */ 
import store from '@/store';
import { 
  GET_IS_STORE_ORGANIZATION_NEXT_LOGIN, 
  GET_ID_ORGANIZATION_NEXT_LOGIN,
  ACT_GET_LIST_ORGANIZATION_ACCOUNT,
  GET_LIST_ORGANIZATION_ACCOUNT,
  SET_ORGANIZATION_INSTANCE
} from '@/store/modules/Organization/types';

import { ACT_GET_MANAGE_INFO } from '@/store/modules/AuthManage/types'
import { findObjectRecursiveArr } from '@/helpers/common';
import _ from 'lodash';

export default async function SelectOrganization({to, next}) {
  let isStoreOrganization = store.getters[`Organization/${GET_IS_STORE_ORGANIZATION_NEXT_LOGIN}`];
  // get list organization
  
  if (isStoreOrganization ) {
    let idOrganization = store.getters[`Organization/${GET_ID_ORGANIZATION_NEXT_LOGIN}`];
    
    try {
      await Promise.all([
        store.dispatch(`Organization/${ACT_GET_LIST_ORGANIZATION_ACCOUNT}`),
        store.dispatch(`AuthManage/${ACT_GET_MANAGE_INFO}`, {id_organization: idOrganization})
      ]);
      let listOrganization = store.getters[`Organization/${GET_LIST_ORGANIZATION_ACCOUNT}`];
      let orgInstance = findObjectRecursiveArr(listOrganization, 'id', idOrganization);
      store.commit(`Organization/${SET_ORGANIZATION_INSTANCE}`, orgInstance);

      return next();
    } catch (err) {
      throw new Error('Can not get manage-info or get list organization!')
    }
  } else {
    let { path } = to;

    return next({ name: 'ManageSelectFacilities', query: { redirect: path } });
  }
}