import {
  SET_VALID_URL_FACILITY,
  SET_FACILITY_DOMAIN_INFO,
  SET_CONFIG,
  SET_CLIENT_CAN_REGISTER,
} from "./types";

export default {
  [SET_FACILITY_DOMAIN_INFO](state, payload) {
    // payload includes: facility_code, logo_img
    state.facility_domain.info = payload;
  },
  [SET_VALID_URL_FACILITY](state, payload) {
    // payload: true or false
    state.facility_domain.isValidDomain = payload;
  },
  [SET_CONFIG](state, config) {
    state.config = config;
  },
  [SET_CLIENT_CAN_REGISTER](state, payload) {
    state.facility_domain.info.client_can_register = payload;
  },
};
