// action

// mutations

export const SET_CLIENT_PERMISSION_VIEW = "SET_CLIENT_PERMISSION_VIEW";
export const REFRESH_CLIENT_PERMISSION_VIEW = "REFRESH_CLIENT_PERMISSION_VIEW";
export const SET_CLIENT_VIEW_FLAG = "SET_CLIENT_VIEW_FLAG";

// getters
export const GET_FACILITY_MENU_ID = "GET_FACILITY_MENU_ID";
export const IS_CLIENT_CAN_BE_VIEWED = "IS_CLIENT_CAN_BE_VIEWED";
export const IS_CLIENT_CAN_BE_EDITABLE = "IS_CLIENT_CAN_BE_EDITABLE";
