/* eslint-disable */ 
const setValue = (e, decimal, withOutZeroLeading) => {
  if (!decimal) {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  } else {
    e.target.value = e.target.value.replace(/^([^.]*\.[^.]*)\..*$/, "$1");
  }
  if(withOutZeroLeading) {
    if (e.target.value != '') { 
      e.target.value = Number(e.target.value) === 0 ? 0 : e.target.value.replace(/^0+/g, '');
    }
  }
}

export default {
  bind: function(el, binding) {
    let { decimal, withOutZeroLeading } = binding.modifiers ;
    el.handler = function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    };

    el.handleChange = function(evt) {
      setValue(evt, decimal, withOutZeroLeading);
    }

    el.handleBlur = function(evt) {
     setValue(evt, decimal, withOutZeroLeading);
    }

    el.addEventListener('keypress', el.handler);
    el.addEventListener('change', el.handleChange);
    el.addEventListener('blur', el.handleBlur);
  },
  unbind: function(el) {
    el.removeEventListener('keypress', el.handler);
    el.removeEventListener('change', el.handleChange);
    el.removeEventListener('blur', el.handleBlur);
  }
}
