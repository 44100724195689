// import domain from '@/middlewares/manage/domain';
import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "access-contents/:content_cms_code",
  name: "AccessContent",
  component: () =>
    import(
      /* webpackChunkName: "access-content-manage-layout-view" */ "@/views/Manage/AccessContent/LayoutView.vue"
    ),
  redirect: { name: "AccessContentSearch" },
  children: [
    {
      path: "",
      name: "AccessContentSearch",
      component: () =>
        import(
          /* webpackChunkName: "access-content-manage-searching-view" */ "@/views/Manage/AccessContent/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create",
      name: "AccessContentCreate",
      component: () =>
        import(
          /* webpackChunkName: "access-content-manage-create-view" */ "@/views/Manage/AccessContent/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id",
      name: "AccessContentEdit",
      component: () =>
        import(
          /* webpackChunkName: "access-content-manage-edit-view" */ "@/views/Manage/AccessContent/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
