var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('b-form-input',_vm._b({class:{
      'is-invalid': Object.prototype.hasOwnProperty.call(
        _vm.validationResult,
        _vm.keyName
      ),
    },attrs:{"placeholder":_vm.placeholder || _vm.$t('panel.label_place_holder')},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}},'b-form-input',_vm.$attrs,false)),(_vm.isFailValidation)?_c('span',{directives:[{name:"error-input-text",rawName:"v-error-input-text",value:({
      validation: _vm.validationResult,
      keyName: _vm.keyName,
    }),expression:"{\n      validation: validationResult,\n      keyName: keyName,\n    }"}]}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }