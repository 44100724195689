<template>
  <div class="d-flex flex-column">
    <b-form-input
      v-model="data"
      v-bind="$attrs"
      :class="{
        'is-invalid': Object.prototype.hasOwnProperty.call(
          validationResult,
          keyName
        ),
      }"
      :placeholder="placeholder || $t('panel.label_place_holder')"
    ></b-form-input>
    <span
      v-if="isFailValidation"
      v-error-input-text="{
        validation: validationResult,
        keyName: keyName,
      }"
    >
    </span>
  </div>
</template>
<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
export default {
  name: "BaseInputNotLabel",
  mixins: [handleValidateMixin],
  props: {
    placeholder: {
      type: String,
      default: "",
    },
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
