/* eslint-disable */
import {
  ref,
  computed,
  onBeforeMount,
  watch,
  toRef,
  onBeforeUnmount,
  onMounted,
} from "vue";
import Store from "@/store";
import {
  GET_CLIENT_ALL_PERMISSION_MENU,
} from "@/store/modules/AuthClient/types";
import {
  SET_CLIENT_PERMISSION_VIEW,
  REFRESH_CLIENT_PERMISSION_VIEW,
} from "@/store/modules/ClientPermissionScreen/types";
import vueRouter from "@/router";
import useRedirectUnauthorized from "../useRedirectUnauthorized";

export default function useInitializePermission() {
  const $route = toRef(vueRouter.app, "$route");
  const $router = vueRouter.app.$router;
  const view_flag = ref(true);
  const menu_id = ref("");
  const id = ref("");
  const can_access_page = ref(false);
  const { redirect401 } = useRedirectUnauthorized();

  const getClientPermissionMenu = computed(
    () => Store.getters[`AuthClient/${GET_CLIENT_ALL_PERMISSION_MENU}`]
  );
  // --------------- METHODS ----------------
  const returnItemMenu = (listMenu, fullPath) => {
    return listMenu.reduce((findItem, curItem) => {
      if (findItem) return findItem;
      if (fullPath.includes(curItem.url)) {
        return curItem;
      }
      if (curItem.children.length)
        return returnItemMenu(curItem.children, fullPath);
    }, null);
  };

  const setClientPermissionView = (...args) =>
    Store.commit(
      `ClientPermissionScreen/${SET_CLIENT_PERMISSION_VIEW}`,
      ...args
    );
  const refreshClientPermissionView = () =>
    Store.commit(`ClientPermissionScreen/${REFRESH_CLIENT_PERMISSION_VIEW}`);

  // refresh state
  const refreshPermissionState = () => {
    view_flag.value = 1;
    menu_id.value = "";
    id.value = "";
    can_access_page.value = false;
    // refresh store
    refreshClientPermissionView();
  };

  const initializePermission = (url = null) => {
    const path = url || $route.value.path;
    if (getClientPermissionMenu.value.length) {
      let findItemMenu = returnItemMenu(getClientPermissionMenu.value, path);
      if (findItemMenu) {
        let {
          menu_id: menuId,
          view_flag: viewFlag,
          facility_menu_id: facilityMenuId,
        } = findItemMenu;
        if (!viewFlag) {
          can_access_page.value = false;
          redirect401();
          return;
        }
        view_flag.value = viewFlag;
        menu_id.value = menuId;
        id.value = facilityMenuId;
        can_access_page.value = true;
        // save store
        setClientPermissionView({
          id: facilityMenuId,
          menu_id: menuId,
          view_flag: viewFlag,
        });
      } else {
        redirect401();
        return;
      }
    } else {
      redirect401();
    }
  };

  // ----------------- WATCH ---------------------
  watch(
    () => $route.value,
    () => {
      refreshPermissionState();
      initializePermission();
    }
  );

  // ----------------- LIFE CIRCLE ---------------

  onMounted(() => {
    refreshPermissionState();
    initializePermission();
  });

  onBeforeUnmount(async () => {
    await refreshPermissionState();
  });

  return {
    view_flag,
    menu_id,
    id,
    can_access_page,
  };
}
