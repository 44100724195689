<template>
  <b-row class="justify-content-center core__input__group__wrapper mt-2 mb-2">
    <b-col
      xl="2"
      lg="3"
      md="4"
      sm="12"
      sx="12"
      class="d-flex"
      :class="{ 'align-items-center': labelMiddleCenter }"
    >
      <slot name="label">
        <label class="m-0 core__input__group__wrapper-title__input">
          <slot name="title" :title="title">
            <span class="label__text" v-html="title"> </span>
          </slot>
          <div v-if="subTitle" class="font-weight-normal">{{ subTitle }}</div>
        </label>
        <span
          v-if="required"
          class="text-danger text-nowrap pl-1 font-weight-bold"
          >{{ textRequired }}</span
        >
      </slot>
    </b-col>
    <b-col xl="10" lg="9" md="8" sm="12" sx="12">
      <slot name="input" :input-fail-validate="isInputFailValidation">
        <div :class="addClassCustom">
          <!-- text-input -->
          <b-form-input
            v-if="input"
            v-model="data"
            :class="{
              'is-invalid':
                validationResult && validationResult.hasOwnProperty(keyName),
            }"
            trim
            v-bind="propsInput"
            v-on="$listeners"
          ></b-form-input>
        </div>
      </slot>

      <div v-error-input-text="{ validationResult, keyName: keyName }"></div>

      <div v-if="listKeyName && validationResult" class="text-danger">
        <template v-for="(itemKeyName, index) in listKeyName">
          <span
            v-if="
              Object.prototype.hasOwnProperty.call(
                validationResult,
                itemKeyName
              )
            "
            :key="index"
            class="d-block"
          >
            {{ validationResult[itemKeyName] }}
          </span>
        </template>
      </div>
      <div>
        <slot name="hideText">
          <span
            v-if="hintText"
            class="core__input__group__wrapper-hint__text"
            >{{ hintText }}</span
          >
        </slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import _ from "lodash";

export default {
  name: "ClientJbbfBaseInputGroup",
  mixins: [handleValidateMixin],
  props: {
    value: {
      type: [String, Number],
    },
    // add * behind label of required input
    required: {
      type: Boolean,
      default: false,
    },
    // title of input
    title: {
      type: String,
      default: "",
    },
    // if want show text-input
    input: {
      type: Boolean,
      default: true,
    },
    // prop of input such as maxlength, required, ...
    propsInput: {
      type: Object,
      default: function () {
        return {};
      },
    },
    hintText: {
      type: String,
      default: "",
    },
    labelMiddleCenter: {
      type: Boolean,
      default: true,
    },
    addClassCustom: {
      type: String,
      default: "",
    },
    textRequired: {
      type: String,
      default: "*",
    },
    subTitle: {
      type: String,
      default: "",
    },

    listKeyName: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    isInputFailValidation() {
      return (
        this.errorValidation &&
        !_.isEmpty(this.validationResult) &&
        Object.prototype.hasOwnProperty.call(
          this.validationResult,
          this.keyName
        )
      );
    },
  },
};
</script>

<style scoped lang="scss">
.core__input__group__wrapper {
  &-title__input {
    margin: 0;
    font-size: 16px;
    color: #7a7a7a;
  }

  &-hint__text {
    color: #8993a4;
    font-size: 12px;
  }

  margin-bottom: 5px;

  @media screen and (max-width: 900px) {
    margin-bottom: 10px;
  }
}
</style>
