<template>
  <base-modal-dialog
    name="member_type_renewable_flag_dialog"
    :draggable="enableDraggable"
    height="auto"
    width="500"
    :click-to-close="false"
  >
    <b-card class="m-0 renew__expired__date__card__dialog" no-body>
      <div class="renew__expired__date__card__dialog-header__card">
        {{ $t("panel.label_confirm_title") }}
      </div>
      <b-card-body>
        <b-overlay
          :show="loading"
          opacity="0.8"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="md"
        >
          <div class="d-block mt-3 mb-3">
            {{ $t("panel.label_required_create_invoice") }}
          </div>
        </b-overlay>

        <div class="d-block mt-2">
          <slot name="btn-action">
            <div class="d-flex w-100 justify-content-end g-5">
              <b-button
                :disabled="loading"
                class="renew__expired__date__card__dialog-button__ok"
                @click="confirmOK"
              >
                OK
              </b-button>
              <b-button
                :disabled="loading"
                class="renew__expired__date__card__dialog-button__cancel"
                @click="cancel"
              >
                {{ $t("panel.label_cancel_btn_modal") }}
              </b-button>
            </div>
          </slot>
        </div>
      </b-card-body>
    </b-card>
  </base-modal-dialog>
</template>

<script>
import clientInvoiceService from "@/services/client/invoice";
import { mapGetters, mapMutations } from "vuex";
import {
  GET_CLIENT_ACCOUNT_INFO,
  SET_CLIENT_MEMBER_TYPE_RENEWABLE_FLAG,
} from "@/store/modules/AuthClient/types";
import useHandleScreenSize from "@/hooks/useHandleScreenSize";
import { computed } from "vue";

export default {
  name: "ClientAlertRenewExpiredDateDialog",
  setup() {
    const { windowWidth } = useHandleScreenSize();
    const enableDraggable = computed(() =>
      windowWidth.value > 900 ? true : false
    );
    return {
      enableDraggable,
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("AuthClient", {
      clientInfo: GET_CLIENT_ACCOUNT_INFO,
    }),
  },
  methods: {
    ...mapMutations("AuthClient", {
      setRenewExpiredDate: SET_CLIENT_MEMBER_TYPE_RENEWABLE_FLAG,
    }),
    async confirmOK() {
      if (!this.loading) {
        this.loading = true;

        try {
          let response = await clientInvoiceService.renewMemberTypeExpiredDate({
            account_code: this.clientInfo?.account_code || "",
            class_type: this.clientInfo?.class_type || "",
            corporate_code: this.clientInfo?.corporate_code || "",
          });
          let { member_type_renewable_flag } = response;
          this.loading = false;
          this.createInvoiceSuccess(member_type_renewable_flag);
        } catch (err) {
          this.loading = false;
          this.closeModal();
          this.$errorSystem(err.data);
        }
      }
    },
    cancel() {
      if (!this.loading) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$modal.hide("member_type_renewable_flag_dialog");
    },
    createInvoiceSuccess(expiredInput) {
      this.setRenewExpiredDate(expiredInput);
      const { name } = this.$route;
      if (name == "ClientMyInvoiceView") {
        // if we are staying in my-invoices page
        this.$alertSuccess(this.$t("panel.label_create_invoice_success"));
        this.$eventBus.$emit("refresh-my-invoices-page");
        this.closeModal();
      } else {
        // or staying another pages
        this.closeModal();
        setTimeout(() => {
          this.$alertSuccess(this.$t("panel.label_create_invoice_success"));
        }, 1000);
        // go to my-invoices page
        this.$router.push("/my-invoices");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
