<template>
  <div
    class="form-group"
    :class="{
      'input-group': hasIcon,
      'input-group-focus': focused,
      ...rootCustomClass,
    }"
  >
    <slot name="label">
      <label
        v-if="label"
        class="control-label"
        :class="{ 'font-weight-bold': bold }"
      >
        {{ label }}
      </label>
      <label v-if="required" class="text-danger">*</label>
    </slot>
    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <div class="input-group-text">
          <i :class="addonLeftIcon"></i>
        </div>
      </span>
    </slot>
    <slot>
      <div class="position-relative">
        <input
          v-show-password="{
            inputType: $attrs.type,
            showPassword: showPassword,
          }"
          :value="value"
          v-bind="$attrs"
          class="form-control"
          :class="{ 'is-invalid': failInput }"
          aria-describedby="addon-right addon-left"
          v-on="listeners"
        />
        <div
          v-if="$attrs.type == 'password'"
          ref="ShowPasswordIcon"
          class="eye-icon_parent"
          @click="showPassword = !showPassword"
        >
          <i class="fas fa-eye eye-icon_child"></i>
        </div>
      </div>
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div class="input-group-text">
          <i :class="addonRightIcon"></i>
        </div>
      </span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  name: "BaseInput",
  inheritAttrs: false,
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    label: {
      type: String,
      description: "Input label",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right",
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left",
    },
    bold: {
      type: Boolean,
      default: false,
    },
    failInput: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rootCustomClass: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      focused: false,
      showPassword: false,
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },
  watch: {
    showPassword() {
      if (this.showPassword) {
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.add("fa-eye-slash");
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.remove("fa-eye");
      } else {
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.add("fa-eye");
        this.$refs.ShowPasswordIcon.querySelector(
          ".eye-icon_child"
        ).classList.remove("fa-eye-slash");
      }
    },
  },
  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
};
</script>
<style>
.eye-icon_parent {
  position: absolute;
  right: 10px;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
