import { formatConstantToArray } from "@/helpers/common";

export default {
  props: {
    showOptionAll: {
      type: Boolean,
      default: false,
    },
    showEmptyOption: {
      type: Boolean,
      default: true,
    },
    hideSearch: {
      type: Boolean,
      description: "Hide input-search text in selection",
      default: false,
    },
    showPlaceHolder: {
      type: Boolean,
      description: "Show option please-choose in the top first option",
      default: true,
    },
    mapTextRecord: {
      type: Boolean,
      description:
        "Item option only { text: '', id: '' } and selection is only id, when turn on TRUE , it will search text and auto get fill id",
      default: false,
    },
    textRecord: {
      type: String,
      default: "",
    },
    autoFetch: {
      type: Boolean,
      default: false,
    },
    optionsInput: {
      type: Array,
      default: function () {
        return [];
      },
    },
    hintText: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },

  watch: {
    // it will emit INPUT only mapTextRecord = TRUE and textRecord != ""
    textRecord() {
      if (this.mapTextRecord && this.textRecord) {
        let find = this.options.find((item) => item.text === this.textRecord);
        if (find) {
          this.$emit("input", find.id);
        }
      }
    },
  },

  data() {
    return {
      options: [],
      loading: false,
      fetchError: false,
    };
  },

  methods: {
    formatConstantToArray,
    forceRedirect403() {
      this.$emit("redirect-403");
    },
    handleError(err) {
      if (err.forbidden) {
        this.forceRedirect403();
      }
    },
  },
};
