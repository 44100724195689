import { autoDownloadFromUrl } from "@/helpers/common";
import {
  ACT_PROCESS_EXPORT_CSV_FILE,
  SET_PROCESS_EXPORT_CSV_FILE,
} from "./types";

export default {
  [ACT_PROCESS_EXPORT_CSV_FILE]({ commit }) {
    commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: true, error: false });

    setTimeout(() => {
      commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: false });
      const urldemo =
        "https://storage.googleapis.com/jbbf/facilities/o020ze02mt/organizations/1/export/member/1662453028_members.csv";
      console.log(urldemo);
      autoDownloadFromUrl(urldemo, "demofile");
    }, 2000);
  },
};
