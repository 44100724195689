import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "deposits",
  name: "DepositManage",
  component: () =>
    import(
      /* webpackChunkName: "deposit-manage-layout-view" */ "@/views/Manage/Deposit/LayoutView.vue"
    ),
  redirect: { name: "DepositSearch" },
  children: [
    {
      path: "",
      name: "DepositSearch",
      component: () =>
        import(
          /* webpackChunkName: "deposit-manage-searching-view" */ "@/views/Manage/Deposit/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "detail/:account_code/:invoice_code",
      name: "DepositDetail",
      component: () =>
        import(
          /* webpackChunkName: "deposit-manage-detail-view" */ "@/views/Manage/Deposit/Detail.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
