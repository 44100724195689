<template>
  <div
    v-if="trackingProgressInstance"
    class="export__progressing__bar bg-white border rounded p-3"
  >
    <div class="d-block">
      <b>{{ trackingProgressInstance.title }}</b>
    </div>

    <div class="export__progressing__bar-wrapper">
      <div class="export__progressing__bar-progress__bar">
        <span class="percent">{{
          trackingProgressInstance.getCurrentProcessPercent
        }}</span>
        <div
          class="export__progressing__bar-progress__bar-progress__fill"
          :style="{
            width: isError
              ? '100%'
              : trackingProgressInstance.getCurrentProcessPercent,
            background: bgColorProgressBar,
          }"
        ></div>
      </div>
    </div>

    <div class="d-block mt-2">
      <div
        class="d-flex w-100 justify-content-between flex-md-row flex-column g-5"
      >
        <p
          class="m-0 p-0 status__display"
          :style="{
            color: isError ? '#dc3545' : '#c7c7c7',
          }"
        >
          {{ statusDisplay }}
        </p>

        <div class="d-flex g-5 justify-content-end">
          <div
            v-show="isGetDownLoadUrlDone"
            class="download__url"
            @click="trackingProgressInstance.remove()"
          >
            <a :href="urlDownload" download target="_blank">
              <Icon
                v-b-tooltip.bottom="`${$t('export-csv.label_download')}`"
                icon="material-symbols:download"
                height="24"
              />
            </a>
          </div>
          <div v-show="isError || isGetDownLoadUrlDone">
            <div class="d-flex g-5">
              <div
                v-show="isError"
                class="d-flex cursor-pointer"
                @click="retry"
              >
                <Icon
                  v-b-tooltip.bottom="`${$t('export-csv.label_retry')}`"
                  icon="ic:baseline-refresh"
                  height="24"
                />
              </div>
              <div
                v-show="isError || isGetDownLoadUrlDone"
                class="d-flex cursor-pointer"
                @click="remove"
              >
                <Icon
                  v-b-tooltip.bottom="`${$t('export-csv.label_close')}`"
                  icon="ic:round-close"
                  height="24"
                  class="cursor-pointer"
                />
              </div>
            </div>
          </div>
          <Icon
            v-show="isError"
            icon="ic:round-warning"
            height="24"
            color="#dc3545"
          />
          <Icon
            v-show="isCompleted"
            icon="material-symbols:check-circle-rounded"
            height="24"
            color="#28a745"
          />

          <Icon
            v-show="inProgress && !isError"
            icon="svg-spinners:bars-rotate-fade"
            height="24"
            color="#000000"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExportProgressingBar",
};
</script>

<script setup>
import { Icon } from "@iconify/vue2";
import { defineProps } from "vue";
import useProgressingBarTracking from "@/hooks/useProgressingBarTracking";

const props = defineProps({
  taskName: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "Export Account List",
  },
});

const {
  trackingProgressInstance,
  statusDisplay,
  bgColorProgressBar,
  isError,
  urlDownload,
  isGetDownLoadUrlDone,
  inProgress,
  isCompleted,
} = useProgressingBarTracking({
  task_name: props.taskName,
});

const retry = () => {
  if (trackingProgressInstance.value) {
    trackingProgressInstance.value.retry();
  }
};

const remove = () => {
  if (trackingProgressInstance.value) {
    trackingProgressInstance.value.remove(true);
  }
};
</script>

<style lang="scss" scoped>
.status__display {
  font-size: 14px;
}
.export__progressing__bar {
  display: block;
  &-wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr min-content;
    align-items: center;
  }

  &-progress__bar {
    width: 100%;
    height: 20px;
    background-color: #586066;
    border-radius: 5px;
    position: relative;

    .percent {
      color: #ffffff;
      position: absolute;
      left: 50%;
      right: 50%;
      font-size: 14px;
      font-weight: bold;
      z-index: 1;
      width: auto;
      margin: 0;
      padding: 0;
      text-wrap: nowrap;
    }
    &-progress__fill {
      height: 100%;
      position: absolute;
      left: 0;
      height: 20px;
      border-radius: 5px;
      transition: all 0.8s ease-in-out;
    }
  }
  .download__url {
    display: block;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    a {
      color: inherit;
    }
  }
}
</style>
