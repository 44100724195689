<template>
  <b-pagination
    :value="currentPage"
    :total-rows="rows"
    :per-page="perPage"
    :page-class="['']"
    prev-text="前"
    next-text="次"
    limit="10"
    class="mx-1"
  >
  </b-pagination>
</template>

<script>
export default {
  name: "ClientJbbfBasePagination",
  props: {
    rows: Number,
    perPage: Number,
    currentPage: Number,
  },
  methods: {},
};
</script>
