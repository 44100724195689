import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "onboardings",
  name: "OnboardingManage",
  component: () =>
    import(
      /* webpackChunkName: "onboarding-manage-layout-view" */ "@/views/Manage/Onboarding/LayoutView.vue"
    ),
  redirect: { name: "OnboardingSearch" },
  children: [
    {
      path: "",
      name: "OnboardingSearch",
      component: () =>
        import(
          /* webpackChunkName: "onboarding-manage-searching-view" */ "@/views/Manage/Onboarding/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/onboardings/create
      name: "OnboardingCreate",
      component: () =>
        import(
          /* webpackChunkName: "onboarding-manage-create-view" */ "@/views/Manage/Onboarding/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/onboardings/edit/1
      name: "OnboardingEdit",
      component: () =>
        import(
          /* webpackChunkName: "onboarding-manage-edit-view" */ "@/views/Manage/Onboarding/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
