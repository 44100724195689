import {
  GET_PROGRESSING_TRACKING_ITEM,
  GET_LIST_IMPORT_PROGRESS_MANAGEMENT_STACK,
  GET_ALL_PROGRESS_DONE,
} from "./types";

export default {
  [GET_PROGRESSING_TRACKING_ITEM]: (state) => (task_name) => {
    return (
      state.import_progressing.find((item) => item.task_name == task_name) ||
      null
    );
  },
  [GET_LIST_IMPORT_PROGRESS_MANAGEMENT_STACK]: (state) =>
    state.import_progressing,
  [GET_ALL_PROGRESS_DONE]: (state) =>
    state.import_progressing.every((item) => item.is_completed),
};
