import {
  SET_MANAGEMENT_PERMISSION_VIEW,
  REFRESH_MANAGEMENT_PERMISSION_VIEW,
  SET_MANAGEMENT_VIEW_FLAG,
} from "./types";
import { initialize } from "./index";

export default {
  [SET_MANAGEMENT_PERMISSION_VIEW]: (state, payload) => {
    state.management_permission_view = Object.assign(
      state.management_permission_view,
      payload
    );
  },
  [REFRESH_MANAGEMENT_PERMISSION_VIEW]: (state) => {
    state.management_permission_view = initialize();
  },
  [SET_MANAGEMENT_VIEW_FLAG]: (state, flag) => {
    if (typeof flag !== "boolean") {
      throw new Error(`'flag' shoudl be Boolean type!`);
    }
    state.management_permission_view.view_flag = flag;
  },
};
