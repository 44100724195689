import {
  SET_LIST_ORGANIZATION_ACCOUNT,
  SET_SAVE_ORGANIZATION_INSTANCE_NEXT_LOGIN,
  SET_ORGANIZATION_INSTANCE,
} from "./types";
import storage from "@/helpers/storageManage";
import {
  ApplicationManageSaveOrganizationFlag,
  ApplicationManageSaveOrganizationId,
} from "@/plugins/setting";

export default {
  [SET_LIST_ORGANIZATION_ACCOUNT](state, payload = []) {
    state.list_oragnizations_access = payload;
  },
  [SET_SAVE_ORGANIZATION_INSTANCE_NEXT_LOGIN](state, payload) {
    let { id_organization, flag } = payload;

    state.save_for_next_login = {
      id_organization: flag ? id_organization : null,
      save: flag ? "save" : null,
    };

    if (flag) {
      // save localstorage
      storage.setStorage({
        [ApplicationManageSaveOrganizationFlag]: "save",
        [ApplicationManageSaveOrganizationId]: id_organization,
      });
    } else {
      storage.removeMultipleStorage([
        ApplicationManageSaveOrganizationFlag,
        ApplicationManageSaveOrganizationId,
      ]);
    }
  },
  [SET_ORGANIZATION_INSTANCE](state, payload) {
    state.organization_info = payload;
  },
};
