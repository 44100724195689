<template>
  <div
    class="card"
    :class="[
      type && `card-${type}`,
      { base__simple__card__container: useSimpleCard },
      cardClass,
    ]"
    v-bind="$attrs"
  >
    <div v-if="$slots.image" class="card-image">
      <slot name="image"></slot>
    </div>
    <div
      v-if="hasTitle || showBtnToProfile"
      class="card-header"
      :class="headerClasses"
    >
      <slot name="header">
        <h4 class="card-title" :class="titleClasses">
          <span>
            <icon-wrapper v-if="hasIcon" :icon="icon" custom-class="mr-2" />
            <span style="font-weight: 700">{{ title }}</span>
          </span>
          <router-link
            v-if="showBtnToProfile"
            :to="{ name: 'ClientChangeProfileView' }"
            class="text-edit-card text-secondary-color"
          >
            <i class="fa-solid fa-pen-to-square"></i>
            {{ $t("personal.label_adjust_profile") }}
          </router-link>
        </h4>
        <p v-if="subTitle" class="card-category">{{ subTitle }}</p>
        <p v-if="requiredNote" class="text-danger">{{ requiredNote }}</p>
      </slot>
    </div>
    <div v-if="$slots.default" class="card-body" :class="bodyClasses">
      <slot></slot>
    </div>
    <slot name="raw-content"></slot>
    <div v-if="$slots.footer" class="card-footer" :class="footerClasses">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
import IconWrapper from "@/components/Client/Jbbf/Icon/JbbfIconWrapper.vue";

export default {
  name: "ClientJbbfBaseCard",
  components: { IconWrapper },
  props: {
    icon: {
      type: Object,
      default: () => {},
      description: "Card icon",
    },
    title: {
      type: String,
      default: "",
      description: "Card title",
    },
    subTitle: {
      type: String,
      description: "Card subtitle",
    },
    type: {
      type: String,
      description: "Card type (e.g primary/danger etc)",
    },
    headerClasses: {
      type: [String, Object, Array],
      description: "Card header css classes",
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: "Card body css classes",
    },
    footerClasses: {
      type: [String, Object, Array],
      description: "Card footer css classes",
    },
    titleClasses: {
      type: [String, Object, Array],
      description: "Title page css classes",
    },
    useSimpleCard: {
      type: Boolean,
      description: "Remove border radius & margin & border",
      default: false,
    },
    cardClass: {
      type: String,
      default: "",
    },
    showBtnToProfile: {
      type: Boolean,
      default: false,
    },
    requiredNote: {
      type: String,
      description: "required Note",
    },
  },
  computed: {
    hasIcon() {
      return this.icon && (this.icon.name || this.icon.image);
    },
    hasTitle() {
      return this.hasIcon || this.title;
    },
  },
};
</script>

<style scoped lang="scss">
.base__simple__card__container {
  background-color: #ffffff;
  margin: 0;
  border-radius: 0;
  border: none;
  position: relative;
  box-sizing: border-box;
}
.text-edit-card {
  font-size: 1rem;
}
</style>
