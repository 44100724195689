<template>
  <div
    class="sidebar"
    :style="sidebarStyle"
    :data-color="backgroundColor"
    :data-image="backgroundImage"
  >
    <div class="sidebar-wrapper">
      <div class="logo">
        <a href="#" class="simple-text logo__container">
          <div class="logo-img">
            <img :src="displayLogo" alt="" />
          </div>
          <router-link
            v-if="organization"
            :to="{ name: 'manageOverview' }"
            class="cover-profile-organization-name text-white"
          >
            <div class="profile-name">
              {{ fullname }}
            </div>
            <div v-if="showOrganization" class="facility-organization-name">
              {{ $t("account.label_facility_organization_name") }}:
              {{ organization.name }}
            </div>
          </router-link>
        </a>
      </div>

      <slot name="content"></slot>
      <ul class="nav nav-main__links">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
            @click="closeNavbar"
          >
            <i :class="link.icon"></i>
            <p>{{ link.name }}</p>
          </sidebar-link>
        </slot>
      </ul>
      <ul v-if="$slots['bottom-links']" class="nav nav-bottom">
        <slot name="bottom-links"></slot>
      </ul>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";
import { mapGetters } from "vuex";
import storage from "@/helpers/storageManage";
import { GET_FACILITY_INFO } from "@/store/modules/Facility/types";
import { GET_ORGANIZATION_INSTANCE } from "@/store/modules/Organization/types";
import { ApplicationManageProfile } from "@/plugins/setting";
import { GET_ACCOUNT_INFO } from "@/store/modules/AuthAdmin/types";
import { flag } from "@/constants/commons";

export default {
  components: {
    SidebarLink,
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  props: {
    title: {
      type: String,
      default: "Admin",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = [
          "",
          "blue",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
          "black",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundImage: {
      type: String,
      default: "/img/sidebar-5.jpg",
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
    ...mapGetters("Facility", {
      facilityInfo: GET_FACILITY_INFO,
    }),
    ...mapGetters("Organization", {
      organization: GET_ORGANIZATION_INSTANCE,
    }),
    ...mapGetters("AuthAdmin", {
      adminInfo: GET_ACCOUNT_INFO,
    }),
    displayLogo() {
      return this.facilityInfo?.logo_image_path || "/img/Logo.svg";
    },
    fullname() {
      if (this.organization) {
        let profile = JSON.parse(storage.getStorage(ApplicationManageProfile));
        return profile.last_name + profile.first_name;
      }
      if (this.adminInfo) {
        return this.adminInfo.last_name + " " + this.adminInfo.first_name;
      }
      return this.title;
    },
    showOrganization() {
      return !(
        this.$facilityOrganizationStructure &&
        this.$facilityOrganizationStructure == flag.notUse.value
      );
    },
  },
};
</script>
<style scoped lang="scss">
.text-white {
  color: white;
}
.cover-profile-organization-name {
  display: inline-block;
  width: 170px;
  height: 48px;
  .profile-name,
  .facility-organization-name {
    width: 157px;
    text-align: left;
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 22px;
  }
  .profile-name {
    margin-bottom: -7px;
  }
  .facility-organization-name {
    font-size: 12px;
    margin-top: -5px;
  }
}
</style>
<style>
.sidebar .sidebar-wrapper {
  display: flex;
  flex-direction: column;
}
.sidebar .nav-main__links {
  flex: 1;
}
.sidebar .sidebar-wrapper .logo .logo__container {
  padding-left: 10px;
}
</style>
