import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "payment-plan-fee-conditions",
  name: "EdicAdminFacilityPaymentPlanFeeConditionManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-master-payment-plan-fee-condition-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/PaymentPlanFeeCondition/LayoutView"
    ),
  redirect: { name: "EdicAdminFacilityPaymentPlanFeeConditionSearch" },
  children: [
    {
      path: "", // /admin/payment-plan-fee-conditions
      name: "EdicAdminFacilityPaymentPlanFeeConditionSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-master-payment-plan-fee-condition-searching-view" */ "@/views/EdicAdmin/Facilities/Manage/PaymentPlanFeeCondition/Search.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create", // /admin/payment-plan-fee-conditions/create
      name: "EdicAdminFacilityPaymentPlanFeeConditionCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-master-payment-plan-fee-condition-create-view" */ "@/views/EdicAdmin/Facilities/Manage/PaymentPlanFeeCondition/Create.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id", //  /admin/payment-plan-fee-conditions/edit/1
      name: "EdicAdminFacilityPaymentPlanFeeConditionEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-master-payment-plan-fee-condition-edit-view" */ "@/views/EdicAdmin/Facilities/Manage/PaymentPlanFeeCondition/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
