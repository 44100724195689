<template>
  <div class="d-block">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ClientJbbfBaseContent",
};
</script>

<style></style>
