import { bffServiceInstanceClient } from "@/plugins/api_service/services";

const prefix = "/management/invoices";

export default {
  getInvoiceHistory: (accountCode) => {
    return bffServiceInstanceClient.get(`${prefix}/get-history`, {
      account_code: accountCode,
    });
  },
  renewMemberTypeExpiredDate({ account_code, class_type, corporate_code }) {
    return bffServiceInstanceClient.post(
      `/member/invoices/renew-member-type-expired-date`,
      {},
      {
        account_code,
        class_type,
        corporate_code,
      }
    );
    // return new Promise((resolve) => {
    //   console.log(account_code, class_type, corporate_code);
    //   setTimeout(() => resolve({ data: "ERRORRRRRRRRRRRRRR" }), 2000);
    // });
  },
};
