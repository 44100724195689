import {
  ACT_EXPORT_CSV_EVENT_PARTICIPANT,
  SET_PROCESS_EXPORT_CSV_FILE,
  ACT_IMPORT_CSV,
} from "./types";
import { autoDownloadFromUrl } from "@/helpers/common";
import eventParticipantService from "@/services/manage/event-participant/event-participant";
import i18n from "@/plugins/i18n";
import { HttpStatusCode } from "axios";

export default {
  [ACT_EXPORT_CSV_EVENT_PARTICIPANT]: async (
    { commit },
    { vm, querySearch }
  ) => {
    // start search
    commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: true, error: false });
    try {
      let response = await eventParticipantService.exportCsvEventParticipant(
        querySearch
      );
      let { url_download } = response;
      autoDownloadFromUrl(url_download);
      commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: false, error: false });
      vm.$toast.success(i18n.t("member.label_download_csv_successfully"));
    } catch (e) {
      commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: false, error: true });
      vm.$toast.error(i18n.t("panel.fail"));
    }
  },
  [ACT_IMPORT_CSV]: async (
    { state },
    {
      event_code,
      event_cms_code,
      account_code,
      facility_code,
      login_organization,
      encode_type,
    }
  ) => {
    state.importCsv = {
      ...state.importCsv,
      validation: {},
      validation_source: null,
      error: false,
      loading: true,
      success: false,
      total_insert: 0,
      total_updated: 0,
    };

    try {
      let res = await eventParticipantService.importCsv(
        state.importCsv.file,
        event_code,
        event_cms_code,
        account_code,
        facility_code,
        login_organization,
        encode_type
      );
      let { total_updated_event_participant_courses } = res;
      state.importCsv = {
        ...state.importCsv,
        loading: false,
        success: true,
        total_updated: total_updated_event_participant_courses,
      };
    } catch (e) {
      state.importCsv.loading = false;
      state.importCsv.success = false;
      state.importCsv.error = true;
      if (e.statusCode == HttpStatusCode.Forbidden) {
        throw e;
      }
      if (e.failValidation) {
        let { data, source_data } = e;
        state.importCsv.validation = data;
        state.importCsv.validation_source = source_data;
      } else {
        throw new Error(e);
      }
    }
  },
};
