import { ACT_RELATED_OPTIONS, ACT_GET_ACCOUNT_CMS_GROUP } from "./types";

export default {
  [ACT_RELATED_OPTIONS]: ({ state }) => {
    // call api
    setTimeout(() => {
      state.related_options = [
        {
          id: 2,
          text: "Giới tính",
          options: [
            {
              related_id: 1,
              text: "Nam",
            },
            {
              related_id: 2,
              text: "Nữ",
            },
          ],
        },
        {
          id: 3,
          text: "Màu sắc",
          options: [
            {
              related_id: 3,
              text: "Đen",
            },
            {
              related_id: 4,
              text: "Xanh",
            },
            {
              related_id: 5,
              text: "Trắng",
            },
            {
              related_id: 6,
              text: "Hồng",
            },
          ],
        },
        {
          id: 6,
          text: "Màu sắc 2",
          options: [
            {
              related_id: 11,
              text: "Đen 2",
            },
            {
              related_id: 12,
              text: "Xanh 2",
            },
          ],
        },
        {
          id: 10,
          text: "option 10",
          options: [],
        },
      ];
    }, 1000);
  },

  [ACT_GET_ACCOUNT_CMS_GROUP]: ({ state }) => {
    setTimeout(() => {
      state.account_cms_groups = [
        { id: 1, text: "account cms group 1" },
        { id: 2, text: "account cms group 2" },
        { id: 3, text: "account cms group 3" },
        { id: 4, text: "account cms group 4" },
        { id: 5, text: "account cms group 5" },
        { id: 6, text: "account cms group 6" },
        { id: 7, text: "account cms group 7" },
        { id: 8, text: "account cms group 8" },
      ];
    }, 1000);
  },
};
