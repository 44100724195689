var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.trackingProgressInstance)?_c('div',{staticClass:"export__progressing__bar bg-white border rounded p-3"},[_c('div',{staticClass:"d-block"},[_c('b',[_vm._v(_vm._s(_setup.trackingProgressInstance.title))])]),_c('div',{staticClass:"export__progressing__bar-wrapper"},[_c('div',{staticClass:"export__progressing__bar-progress__bar"},[_c('span',{staticClass:"percent"},[_vm._v(_vm._s(_setup.isError ? "100%" : _setup.currentProgressPercent))]),_c('div',{staticClass:"export__progressing__bar-progress__bar-progress__fill",style:({
          width: _setup.isError ? '100%' : _setup.currentProgressPercent,
          background: _setup.bgColorProgressBar,
        })})])]),_c('div',{staticClass:"d-block mt-2"},[_c('div',{staticClass:"d-flex w-100 justify-content-between flex-md-row flex-column g-5"},[_c('div',{staticClass:"m-0 p-0 status__display",style:({
          color: _setup.isError ? '#dc3545' : '#c7c7c7',
        })},[_c('p',{staticClass:"p-0 m-0 text-secondary"},[_vm._v(" "+_vm._s(_vm.$t("import-csv.step"))+": "),_c('b',[_vm._v(_vm._s(_setup.currentStep))])]),(_setup.isError || _setup.isCompleted || _setup.isHavingValidation)?_c('div',{staticClass:"mt-1",class:{
            'is-invalid-feedback-message': _setup.isError || _setup.isHavingValidation,
            'success__progress font-weight-bold': _setup.isCompleted,
          },staticStyle:{"font-size":"16px"}},[(_setup.isError)?_c('p',{staticClass:"p-0 m-0"},[_vm._v(_vm._s(_setup.errorMsg))]):_vm._e(),(_setup.isHavingValidation)?_c('p',{staticClass:"p-0 m-0"},[_vm._v(" "+_vm._s(_vm.$t("import-csv.fail_validation_import"))+" ")]):_vm._e(),(_setup.isCompleted)?_c('p',{staticClass:"p-0 m-0"},[_vm._v(" "+_vm._s(_vm.$t("import-csv.completed_progress"))+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"d-flex g-5 justify-content-end"},[_c('div',[_c('div',{staticClass:"d-flex g-5"},[(_setup.isError || _setup.isCompleted)?_c('div',{staticClass:"d-flex cursor-pointer",on:{"click":_setup.remove}},[_c(_setup.Icon,{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(`${_vm.$t('export-csv.label_close')}`),expression:"`${$t('export-csv.label_close')}`",modifiers:{"bottom":true}}],staticClass:"cursor-pointer",attrs:{"icon":"ic:round-close","height":"24"}})],1):_vm._e()])]),_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(_setup.isError),expression:"isError"}],attrs:{"icon":"ic:round-warning","height":"24","color":"#dc3545"}}),_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(_setup.isCompleted),expression:"isCompleted"}],attrs:{"icon":"material-symbols:check-circle-rounded","height":"24","color":"#28a745"}}),_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(_setup.inProgress && !_setup.isError),expression:"inProgress && !isError"}],attrs:{"icon":"svg-spinners:bars-rotate-fade","height":"24","color":"#000000"}})],1)])]),(_vm.showBackRoute && _setup.backRoutePage && _vm.$route.name != _setup.backRoutePage)?_c('div',{staticClass:"block mt-2"},[_c('b-badge',{staticStyle:{"cursor":"pointer"},attrs:{"variant":"primary"},on:{"click":_setup.handleClick}},[_vm._v(_vm._s(_vm.$t("import-csv.go_to_detail")))])],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }