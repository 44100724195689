import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "setting-functional-menu",
  name: "ManageSettingFunctionalMenuLayout",
  component: () =>
    import(
      /* webpackChunkName: "management-setting-functional-menu-layout-view" */ "@/views/Manage/FunctionalMenu/LayoutView.vue"
    ),
  redirect: { name: "ManagementSettingMenu" },
  children: [
    {
      path: "",
      name: "ManagementSettingMenu",
      component: () =>
        import(
          /* webpackChunkName: "setting-functional-menu-view" */ "@/views/Manage/FunctionalMenu/SettingMenu.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
