import eventBus from "@/helpers/eventBus";

export default {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $eventBus: {
        get: function () {
          return eventBus;
        },
      },
    });
  },
};
