// getters
export const GET_LIST_PROGRESS_STACK = "GET_LIST_PROGRESS_STACK";
export const GET_LIST_PROGRESS_MANAGEMENT_STACK =
  "GET_LIST_PROGRESS_MANAGEMENT_STACK";
export const GET_LIST_PROGRESS_ADMIN_STACK = "GET_LIST_PROGRESS_ADMIN_STACK";
export const GET_PROGRESSING_TRACKING_ITEM = "GET_PROGRESSING_TRACKING_ITEM";
export const GET_ALL_PROGRESS_DONE = "GET_ALL_PROGRESS_DONE";
// mutations
export const SET_ADD_PROGRESS_EXPORT_TO_STACK =
  "SET_ADD_PROGRESS_EXPORT_TO_STACK";

export const SET_REFRESH_TO_EMPTY_ALL_STACK = "SET_REFRESH_TO_EMPTY_ALL_STACK";

export const SET_FILTER_TASK_EXPORT = "SET_FILTER_TASK_EXPORT";
export const SET_ERROR_FOR_ALL_PROCESSING_EXPORT =
  "SET_ERROR_FOR_ALL_PROCESSING_EXPORT"; // if network is error for some issues
export const SET_PROCESS = "SET_PROCESS";

export const SET_CLOSE_PROGRESS_AND_FRESH = "SET_CLOSE_PROGRESS_AND_FRESH";
