<template>
  <div class="d-block mb-2">
    <base-input v-bind="$attrs">
      <b-form-file
        browse-text="ファイル選択"
        :accept="accept"
        placeholder="表示する情報がありません"
        @change="browseInput"
      ></b-form-file>
    </base-input>

    <div v-if="reviewImg" class="review__image">
      <div class="review__item__single">
        <button class="remove__icon" @click="removeSingleImg">
          <i class="fa-solid fa-xmark text-danger"></i>
        </button>

        <b-img thumbnail fluid :src="reviewImg" alt="Image 1"></b-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientJbbfBaseInputFile",
  props: {
    accept: {
      type: String,
      default: "image/*",
    },
  },

  data() {
    return {
      image: null,
      reviewImg: null,
    };
  },
  methods: {
    browseInput(e) {
      if (e.target.files.length) {
        this.image = e.target.files[0];
        this.reviewImg = URL.createObjectURL(this.image);
      } else {
        this.removeSingleImg();
      }
    },
    removeSingleImg() {
      this.image = this.reviewImg = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.review__image {
  display: flex;
  position: relative;
  margin-top: 10px;
  width: 100%;

  .review__item__single {
    position: relative;
    width: 30%;

    .img-thumbnail {
      padding: 0;
      border: none;
    }

    .remove__icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      z-index: 1;
      border: none;
      background-color: transparent;
      outline: none;
      padding: 0;
      margin: 0;
    }

    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
}
</style>
