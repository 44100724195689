<template>
  <div class="d-block">
    <multiselect
      v-model="multiple_value"
      :options="localOptions"
      allow-empty
      label="text"
      track-by="id"
      :multiple="multiple"
      searchable
      :placeholder="placeholder"
      :disabled="disabled"
      :class="{ 'is-invalid': error || isFailValidation }"
      :select-label="$t('panel.label_press_enter_to_select')"
      :selected-label="$t('panel.label_selected')"
      :deselect-label="$t('panel.label_press_enter_to_remove')"
      :taggable="taggable"
      :tag-placeholder="$t('panel.label_add_this_as_a_new_option')"
      v-bind="$attrs"
      @tag="addTag"
    >
      <template #noOptions>
        <span>{{ noOptions }}</span>
      </template>

      <template #placeholder>
        <span>{{ placeholder }}</span>
      </template>

      <template #noResult>
        <span>{{ $t("panel.label_no_data") }}</span>
      </template>
    </multiselect>

    <b-form-invalid-feedback v-if="isFailValidation && showErrorText">
      {{ textValidation }}
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import handleValidateMixin from "@/mixins/handle-validate-mixin";

export default {
  name: "BaseMultipleSelect",
  components: {
    Multiselect,
  },
  mixins: [handleValidateMixin],
  props: {
    value: null, //Array, String, Number, Object
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    showErrorText: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localOptions: [],
    };
  },
  computed: {
    noOptions() {
      return this.$t("panel.label_no_data");
    },
    placeholder() {
      return this.$t("panel.label_please_choose");
    },
    multiple_value: {
      get() {
        if (Array.isArray(this.value)) {
          if (this.options.length && this.value) {
            let findOptionByListId = [...this.options].filter((item) =>
              this.value.includes(item.id)
            );
            return findOptionByListId;
          } else {
            return [];
          }
        } else {
          let findOption = [...this.options].find(
            (item) => item.id == this.value
          );
          return findOption;
        }
      },
      set(newValue) {
        if (this.multiple) {
          let reduceId = newValue.reduce(function (listId, item) {
            listId.push(item.id);
            return listId;
          }, []);
          this.$emit("input", reduceId);
        } else {
          this.$emit("input", newValue ? newValue.id : "");
        }
      },
    },
  },
  watch: {
    options: {
      immediate: true,
      handler: function () {
        this.localOptions = this.options;
      },
    },
  },
  created() {
    this.filterDataBeforeRender();
  },
  methods: {
    addTag(newTag) {
      let newData = {
        id: newTag,
        text: newTag,
      };
      this.localOptions.push(newData);
      this.multiple_value = newData;
    },
    filterDataBeforeRender() {
      if (Array.isArray(this.value)) {
        let findOptionByListId = JSON.parse(JSON.stringify([...this.options]))
          .filter((item) => this.value.includes(item.id))
          .map((item) => item.id);
        this.$emit("input", findOptionByListId);
      } else {
        let findOption = [...this.options].find(
          (item) => item.id == this.value
        );
        this.$emit("input", findOption?.id || "");
      }
    },
  },
};
</script>

<style lang="scss">
.multiselect__tags {
  border: 1px solid #ced4da;
  color: #495057;
  padding: 5px 40px 0 0.75rem;
}
.multiselect__select {
  width: auto;

  &:before {
    border-color: #343a40 transparent transparent;
  }
}
.multiselect__placeholder {
  color: #495057;
  font-size: 16px;
  margin-bottom: 5px;
  // height: 41px;
}

.multiselect.is-invalid {
  .multiselect__tags {
    border: 1px solid #dc3545 !important;
  }
}
.multiselect__single {
  margin-top: 5px;
}
</style>
