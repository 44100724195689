import useInitializePermission from "./useInitializePermission";
import useScreenPermission from "./useScreenPermission";
import useComponentPermission from "./useComponentPermission";

export const eventName = {
  management_not_allow_to_access: "management_not_allow_to_access",
  management_not_found: "management_not_found",
};

export const provideDataType = {
  manage_is_disabled_editing: "manage_is_disabled_editing",
  manage_can_create_edit_remove: "manage_can_create_edit_remove",
  is_using_permission: "is_using_permission",
};

const typeUsing = ["initialize", "screen", "component"];

export default function mainManagementPermission(options) {
  const { type, displayScreen } = options;

  // validate type
  if (!typeUsing.includes(type.toLowerCase())) {
    throw new Error(
      `[type] parameter is not valid!, only support ['initialize', 'screen', 'component']`
    );
  }

  //
  switch (type) {
    case "initialize":
      return useInitializePermission();

    case "screen":
      return useScreenPermission(displayScreen);

    case "component":
      return useComponentPermission();

    default:
      break;
  }
}
