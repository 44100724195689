import { bffServiceInstance } from "@/plugins/api_service/services";

const prefixEvents = "/management/events";
const prefixEventParticipant = "/management/event-participants";
const prefixEventParticipantCsv = "/management/event-participant-csv";
const prefixEvent = "/management/event";
import { state } from "@/socket";

export default {
  search: (query) =>
    bffServiceInstance.get(`${prefixEventParticipant}/`, query),
  filterSearching: ({ page, default_search, advanced_search, extend_params }) =>
    bffServiceInstance.post(
      `${prefixEventParticipant}/search`,
      {},
      { page, default_search, advanced_search, ...extend_params }
    ),
  store: (bodyForm) =>
    bffServiceInstance.post(`${prefixEventParticipant}`, {}, bodyForm),
  show: (id) => bffServiceInstance.get(`${prefixEventParticipant}/${id}`),
  update: (id, body) =>
    bffServiceInstance.put(`${prefixEventParticipant}/${id}`, {}, body),
  remove: (id) => bffServiceInstance.delete(`${prefixEventParticipant}/${id}`),
  getMasterData: (query) =>
    bffServiceInstance.get(`${prefixEvents}/master-cms`, query),
  getEventSelection: (query) =>
    bffServiceInstance.get(`${prefixEvents}/get-select-list`, query),
  getResultSelection: (query) =>
    bffServiceInstance.get(
      `${prefixEvents}/get-event-result-select-list`,
      query
    ),
  getCourseAndCourseOption: (query) =>
    bffServiceInstance.get(`${prefixEvents}/get-list-course`, query),
  getEventComponent: (query) =>
    bffServiceInstance.get(`${prefixEvents}/event-component-cms`, query),
  getMemberAccountList: (query) =>
    bffServiceInstance.get(`${prefixEvents}/get-member-select-list`, query),
  exportCsvEventParticipant: (socketEventName, searchForm) =>
    bffServiceInstance.post(
      `${prefixEventParticipantCsv}/export`,
      {},
      {
        ...searchForm,
        socket_event_name: socketEventName,
        socket_id: state.socketId,
      }
    ),
  importCsv: ({
    file,
    event_code,
    event_cms_code,
    account_code,
    facility_code,
    login_organization,
    encode_type,
    channel_name,
  }) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("event_code", event_code);
    formData.append("event_cms_code", event_cms_code);
    formData.append("account_code", account_code);
    formData.append("facility_code", facility_code);
    formData.append("login_organization", login_organization);
    formData.append("encode_type", encode_type);
    formData.append("socket_event_name", channel_name);
    formData.append("socket_id", state.socketId);

    return bffServiceInstance.post(
      `${prefixEventParticipantCsv}/import`,
      {},
      formData,
      { Accept: "*/*", "Content-Type": "multipart/form-data" }
    );
  },
  // importCsv: (
  //   file,
  //   event_code,
  //   event_cms_code,
  //   account_code,
  //   facility_code,
  //   login_organization,
  //   encode_type
  // ) =>
  //   new Promise((resolve) => {
  //     setTimeout(() => {
  //       console.log(
  //         file,
  //         event_code,
  //         event_cms_code,
  //         account_code,
  //         facility_code,
  //         login_organization,
  //         encode_type
  //       );
  //       resolve("ok");
  //     }, 1000);
  //   }),
  getEventInfo: ({ event_cms_code, event_code }) =>
    bffServiceInstance.get(`${prefixEvent}/${event_cms_code}/${event_code}`),
  getEventSettings: (query) =>
    bffServiceInstance.get(`${prefixEvents}/get-event-settings`, query),
  getEvent: (query) =>
    bffServiceInstance.get(`${prefixEvents}/get-event-info`, query),
};
