import Vue from "vue";
import ClickOutSide from "./click-ouside.js";
import NumberOnly from "./number-only.js";
import PhoneNumberOnly from "./phone-number-only.js";
import ValidateInput from "./validate-input";
import ErrorInputText from "./error-input-text";
import ScrollHorizontal from "./scroll-horizontal";
import FormatDateInputTyping from "./format-date-input-typing.js";
import AutoFormatDate from "./AutoFormatDate/auto-format-date.js";
import ValidateDateFormat from "./AutoFormatDate/validate-date-format.js";
import ShowPassword from "./show-password.js";
import MinMaxInputNumber from "./min-max-input-number.js";
import LazyLoadImage from "./lazyload-image.js";
import ReviewFileStorageSignurl from "./review-file-storage-signurl.js";

Vue.directive("click-outside", ClickOutSide);
Vue.directive("number-only", NumberOnly);
Vue.directive("phone-number-only", PhoneNumberOnly);
Vue.directive("validate-input", ValidateInput);
Vue.directive("error-input-text", ErrorInputText);
Vue.directive("scroll-horizontal", ScrollHorizontal);
Vue.directive("format-date-input-typing", FormatDateInputTyping);
Vue.directive("auto-format-date", AutoFormatDate);
Vue.directive("validate-date-format", ValidateDateFormat);
Vue.directive("show-password", ShowPassword);
Vue.directive("min-max-input-number", MinMaxInputNumber);
Vue.directive("lazyload-image", LazyLoadImage);
Vue.directive("review-file-storage-signurl", ReviewFileStorageSignurl);
