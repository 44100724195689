import {
  GET_ACTIVE_PARENT_ID,
  GET_OPENING_PARENT_ID,
  GET_PREVIOUS_OPENING_ITEM_HAVE_CHILDREN,
} from "./types";

export default {
  [GET_ACTIVE_PARENT_ID]: (state) => state.active_parent_id,
  [GET_OPENING_PARENT_ID]: (state) => state.opening_parent_id,
  [GET_PREVIOUS_OPENING_ITEM_HAVE_CHILDREN]: (state) =>
    state.previous_opening_item_have_children,
};
