<template>
  <modal
    :name="name"
    :resizable="options.resizable"
    :adaptive="options.adaptive"
    :draggable="resizeScreen"
    :classes="options.classes"
    :width="options.width"
    :height="options.height"
    :click-to-close="options.clickToClose"
  >
    <slot :close-modal="closeModal"></slot>
  </modal>
</template>

<script>
export default {
  name: "ClientBaseModal",
  props: {
    name: {
      type: String,
      default: "base-modal",
    },
    config: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    resizeScreen() {
      const maxWidthSmallScreen = 900;
      let currentScreen = window.innerWidth;
      return currentScreen <= maxWidthSmallScreen
        ? false
        : this.options.draggable;
    },
    options: {
      get() {
        let {
          resizable = true,
          adaptive = true,
          draggable = true,
          classes = "",
          width = 250,
          height = "auto",
          clickToClose = false,
        } = this.config;
        return {
          resizable,
          adaptive,
          draggable,
          classes,
          width,
          height,
          clickToClose,
        };
      },
    },
  },
  mounted() {
    console.log(this.resizeScreen);
  },
  destroyed() {},
  methods: {
    closeModal() {
      this.$modal.hide(this.name);
    },
    resizeHandler(e) {
      console.log(e);
    },
  },
};
</script>

<style></style>
