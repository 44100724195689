import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  active_parent_id: "",
  opening_parent_id: "",
  previous_opening_item_have_children: true,
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
