import {
  ACT_EXPORT_CSV_MANAGE_MEMBER,
  SET_PROCESS_EXPORT_CSV_FILE,
  ACT_IMPORT_CSV_MANAGE_MEMBER,
  ACT_FORCE_FRESH_IMPORT_CSV,
  SET_REFRESH_IMPORT_CSV_FILE,
} from "./types";
import { autoDownloadFromUrl } from "@/helpers/common";
import managementMemberService from "@/services/manage/member";
import i18n from "@/plugins/i18n";
import { HttpStatusCode } from "axios";

export default {
  [ACT_EXPORT_CSV_MANAGE_MEMBER]: async ({ commit }, { vm, querySearch }) => {
    // start search
    commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: true, error: false });
    try {
      let response = await managementMemberService.exportCsvManageMember(
        querySearch
      );
      let { url_download } = response;
      autoDownloadFromUrl(url_download);
      commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: false, error: false });
      vm.$toast.success(i18n.t("member.label_download_csv_successfully"));
    } catch (e) {
      commit(SET_PROCESS_EXPORT_CSV_FILE, { loading: false, error: true });
      vm.$toast.error(i18n.t("panel.fail"));
    }
  },

  [ACT_IMPORT_CSV_MANAGE_MEMBER]: async (
    { state },
    { vm, facility_code, login_organization, encode_type }
  ) => {
    state.importCsv = {
      ...state.importCsv,
      validation: {},
      validation_source: null,
      error: false,
      loading: true,
      success: false,
      total_insert_members: 0,
      total_updated_members: 0,
    };

    try {
      let res = await managementMemberService.importCsvManageMember(
        state.importCsv.file,
        facility_code,
        login_organization,
        encode_type
      );
      let { total_insert_members, total_updated_members } = res;
      state.importCsv = {
        ...state.importCsv,
        loading: false,
        success: true,
        total_insert_members: total_insert_members,
        total_updated_members: total_updated_members,
      };
      vm.$toast.success(i18n.t("member.label_import_csv_successfully"));
    } catch (e) {
      state.importCsv.loading = false;
      state.importCsv.success = false;
      state.importCsv.error = true;
      if (e.statusCode == HttpStatusCode.Forbidden) {
        throw e;
      }
      if (e.failValidation) {
        let { data, source_data } = e;
        state.importCsv.validation = data;
        state.importCsv.validation_source = source_data;
        vm.$toast.error(i18n.t("panel.label_failed_validation"));
      } else {
        vm.$errorSystem();
      }
    }

    // setTimeout(() => {
    //   state.importCsv.loading = false;
    //   vm.$toast.success('Upload csv successfully!');

    //   state.importCsv = {
    //     ...state.importCsv,
    //     file: null,
    //     csv_content: {
    //       data: [],
    //       meta: {},
    //       errors: []
    //     }
    //   }
    // }, 20000);
  },

  [ACT_FORCE_FRESH_IMPORT_CSV]: ({ commit }) => {
    commit(SET_REFRESH_IMPORT_CSV_FILE);
  },
};
