import { ACT_CHECK_VALID_DOMAIN_FACILITY } from "./types";
import adminFacilityService from "@/services/admin/facilities";

export default {
  async [ACT_CHECK_VALID_DOMAIN_FACILITY]({ state }, payload) {
    let { url } = payload;

    try {
      let response = await adminFacilityService.validURL(url);
      state.facility_domain.isValidDomain = true;
      state.facility_domain.info = response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
