/* eslint-disable */
import { ACT_GET_LIST_ORGANIZATION_ACCOUNT, ACT_GET_ORGANIZATION_INSTANCE, ACT_SET_REFRESH_DEFAULT } from './types';
import { GET_ACCOUNT_ID } from '@/store/modules/AuthManage/types';
import authManageService from '@/services/auth/auth_manage';
import storage from '@/helpers/storageManage';
import { ApplicationManageSaveOrganizationFlag, ApplicationManageSaveOrganizationId } from '@/plugins/setting';

export default {

  async [ACT_GET_LIST_ORGANIZATION_ACCOUNT]({state, rootGetters}) {
    let accountId = rootGetters[`AuthManage/${GET_ACCOUNT_ID}`];
    try {
      if (!state.list_oragnizations_access.length) {
        let res = await authManageService.getListOrganizationAccountLogin(accountId);
        state.list_oragnizations_access = res.data;
      }
   
    } catch (err) {
      console.log(err)
      return false;
    }
  },

  async [ACT_GET_ORGANIZATION_INSTANCE]({state, commit, rootState}, payload ) {
    let { id_organization } = payload;
    try {
      let res = await authManageService.getInfo(id_organization);
      let { permission_menu } = res;
      rootState.AuthManage.permission_menu = permission_menu;
      // console.log(rootState)
      // state.organization_info = res;
    } catch (err) {
      return false;
    }
  },

  [ACT_SET_REFRESH_DEFAULT]({state}) {
    state.list_oragnizations_access = [];
    state.organization_info = null;
    state.save_for_next_login = {
      id_organization: null,
      save: false
    }

    storage.removeMultipleStorage([
      ApplicationManageSaveOrganizationFlag,
      ApplicationManageSaveOrganizationId
    ])
  }
}