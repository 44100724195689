import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "account-cms",
  name: "EdicAdminFacilityAccountCmsManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-account-cms-setting-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/AccountCmsSetting/Layout"
    ),
  redirect: { name: "EdicAdminFacilityAccountCms" },
  children: [
    {
      path: "", // /admin/plan
      name: "EdicAdminFacilityAccountCms",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-account-cms-setting" */ "@/views/EdicAdmin/Facilities/Manage/AccountCmsSetting/AccountCms"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
