export const GET_FACILITY_MANAGEMENT_INFO = "GET_FACILITY_MANAGEMENT_INFO";
export const GET_FACILITY_MANAGEMENT_CODE = "GET_FACILITY_MANAGEMENT_CODE";
export const GET_FACILITY_MANAGEMENT_ID = "GET_FACILITY_MANAGEMENT_ID";
export const GET_FACILITY_MENU_OVERLAY = "GET_FACILITY_MENU_OVERLAY";
export const GET_IS_FACILITY_EXISTS = "GET_IS_FACILITY_EXISTS";
export const GET_FACILITY_IS_USING_APP = "GET_FACILITY_IS_USING_APP";
export const GET_FINANCIAL_BALANCE_TYPE = "GET_FINANCIAL_BALANCE_TYPE";
export const GET_FINANCIAL_BALANCE_DATE = "GET_FINANCIAL_BALANCE_DATE";

export const SET_FACILITY_MANAGEMENT_INFO = "SET_FACILITY_MANAGEMENT_INFO";
export const SET_REFRESH_FACILITY_MANAGEMENT_INFO =
  "SET_REFRESH_FACILITY_MANAGEMENT_INFO";
export const SET_FACILITY_MENU_OVERLAY = "SET_FACILITY_MENU_OVERLAY";
