<template>
  <div class="d-block">
    <!-- LOADING -->
    <div class="d-block">
      <transition name="fade">
        <div ref="element" class="d-block">
          <slot v-if="loading" name="loading">
            <p>LOADING..............</p>
          </slot>
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div v-if="!loading" class="d-block">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BaseLazyLoadContent",
};
</script>

<script setup>
import { onMounted, ref, defineProps } from "vue";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";

const props = defineProps({
  option: {
    type: Object,
    default: function () {
      return {
        rootMargin: "0px",
        root: null,
        threshold: 1,
      };
    },
  },
});

const element = ref(null);
const loading = ref(true);

onMounted(() => {
  useIntersectionObserver({
    elementWatcher: element.value,
    callback: () => {
      if (loading.value) {
        setTimeout(() => (loading.value = false), 50);
      }
    },
    option: props.option,
  });
});
</script>

<style lang="scss" scoped></style>
