<template>
  <div class="http404__container">
    <div class="http404__content">
      <div class="pic-404">
        <img
          class="pic-404__parent"
          src="@/assets/images/errors-views/404/404.png"
          alt="404"
        />
        <img
          class="pic-404__child left"
          src="@/assets/images/errors-views/404/404_cloud.png"
          alt="404"
        />
        <img
          class="pic-404__child mid"
          src="@/assets/images/errors-views/404/404_cloud.png"
          alt="404"
        />
        <img
          class="pic-404__child right"
          src="@/assets/images/errors-views/404/404_cloud.png"
          alt="404"
        />
      </div>
      <div class="bullshit">
        <div class="bullshit__oops">OOPS!</div>
        <div class="bullshit__headline">{{ message }}</div>
        <div class="bullshit__info">{{ $t("panel.label_404_text") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  computed: {
    message() {
      return this.$t("panel.label_404_announce_title");
    },
  },
};
</script>
