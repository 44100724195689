import storage from "@/helpers/storageManage";
import {
  ApplicationManageTokenExpiredAt,
  ApplicationManageStorageToken,
  ApplicationClientTokenExpiredAt,
  ApplicationClientStorageToken,
  ApplicationAdminTokenExpiredAt,
  ApplicationAdminStorageToken,
  ApplicationClientAccountTemp,
} from "@/plugins/setting";

import moment from "moment";

const rangeTime = 5;

export const checkAuthManageLifeTime = () => {
  let expiredAt = storage.getStorage(ApplicationManageTokenExpiredAt);
  let tokenManage = storage.getStorage(ApplicationManageStorageToken);
  if (!tokenManage || !expiredAt) {
    return false;
  }
  let pointNowTime = moment().valueOf();
  expiredAt = moment(parseInt(expiredAt))
    .subtract(rangeTime, "minutes")
    .valueOf();

  if (pointNowTime >= expiredAt) {
    return false;
  }
  return true;
};

export const checkClientManageLifeTime = () => {
  let expiredAt = storage.getStorage(ApplicationClientTokenExpiredAt);
  let tokenManage = storage.getStorage(ApplicationClientStorageToken);
  let isAccountTemp = parseInt(
    storage.getStorage(ApplicationClientAccountTemp)
  );
  if (!tokenManage || !expiredAt || isAccountTemp) {
    return false;
  }

  let pointNowTime = moment().valueOf();
  expiredAt = moment(parseInt(expiredAt))
    .subtract(rangeTime, "minutes")
    .valueOf();

  if (pointNowTime >= expiredAt) {
    return false;
  }
  return true;
};

export const checkAuthAdminLifeTime = () => {
  let expiredAt = storage.getStorage(ApplicationAdminTokenExpiredAt);
  let tokenAdmin = storage.getStorage(ApplicationAdminStorageToken);
  if (!tokenAdmin || !expiredAt) {
    return false;
  }
  let pointNowTime = moment().valueOf();
  expiredAt = moment(parseInt(expiredAt))
    .subtract(rangeTime, "minutes")
    .valueOf();

  if (pointNowTime >= expiredAt) {
    return false;
  }
  return true;
};
