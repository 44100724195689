import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  process_export_csv: {
    loading: false,
    searching: {},
    error: false,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
