import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "plan",
  name: "EdicPlanManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-plan-layout-view" */ "@/views/EdicAdmin/Plans/LayoutView.vue"
    ),
  redirect: { name: "EdicPlansSearch" },
  children: [
    {
      path: "", // /new-admin/facilities
      name: "EdicPlansSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-plan-searching-view" */ "@/views/EdicAdmin/Plans/Search.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create", // /new-admin/facilities/create
      name: "EdicPlansCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-plan-create-view" */ "@/views/EdicAdmin/Plans/Create.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id", // /admin/plan/edit/1
      name: "EdicPlansEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-plan-edit-view" */ "@/views/EdicAdmin/Plans/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
