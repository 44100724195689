<template>
  <div class="d-block" v-bind="$attrs">
    <div class="d-flex w-100 align-items-center">
      <input
        v-model.trim="date"
        v-auto-format-date="{ mode: inputMode }"
        class="form-control input-date"
        :class="{ 'is-invalid': isFailValidation || isErrorFormat }"
        :placeholder="getPlaceHolder"
        :disabled="disabled || isTurningOnDatePicker"
        @keypress="onKeypress"
        @focus="removeValidation"
      />

      <!-- mode: date & dateTime -->
      <template v-if="modeDatePicker">
        <v-date-picker
          ref="vDatePicker"
          :value="localDate"
          :popover="{ visibility: 'click', positionFixed: true }"
          :is24hr="is24hr"
          :mode="mode"
          :minute-increment="minuteIncrement"
          locale="ja"
          @input="handlePickDate"
          @popoverWillHide="isTurningOnDatePicker = false"
          @popoverWillShow="isTurningOnDatePicker = true"
        >
          <template #default="{ togglePopover }">
            <button
              v-if="showIconAppend"
              type="button"
              class="calendar__button__trigger"
              :disabled="disabled"
              @click.prevent="togglePopover"
            >
              <i class="fa-solid fa-calendar"></i>
            </button>
          </template>
        </v-date-picker>
      </template>

      <!-- mode: yearMonth -->
      <template v-if="modeMonthYear">
        <base-input-monthly-picker
          ref="monthlyPicker"
          v-model.trim="date"
          style="display: none"
          :always-directive="alwaysDirective"
          @popoverWillHide="isTurningOnDatePicker = false"
          @popoverWillShow="isTurningOnDatePicker = true"
        >
          <template #default="{ openPickMonth }">
            <button
              v-if="showIconAppend"
              type="button"
              class="calendar__button__trigger"
              :disabled="disabled"
              @click="openPickMonth"
            >
              <i class="fa-solid fa-calendar"></i>
            </button>
          </template>
        </base-input-monthly-picker>
      </template>
    </div>
    <!-- <span
      v-validate-date-format="{
        date: date,
        mode: mode,
        displayName: displayName,
      }"
    ></span> -->
    <span
      v-if="isErrorFormat && !isFailValidation"
      class="is-invalid-feedback-message d-block"
      >{{ textErrorFormat }}</span
    >
    <div
      v-else-if="isFailValidation && showErrorText"
      class="is-invalid-feedback-message"
    >
      {{ textValidation }}
    </div>
  </div>
</template>

<script>
import {
  regexYYYYMMDD,
  regexYYYYMMDDHHmm,
  regexYYYYMM,
  regexMMDD,
} from "@/directives/AutoFormatDate/auto-format-date";
import cloneDeep from "lodash/cloneDeep";
import handleValidateMixin from "@/mixins/handle-validate-mixin";
//import debounce from 'lodash/debounce';

export default {
  name: "BaseInputDateText",
  mixins: [handleValidateMixin],
  props: {
    value: {
      type: [String, Date],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showIconAppend: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: "",
    },
    is24hr: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "date",
      description: "Only 2 mode [ date, dateTime ]",
      validator: (propValue) => {
        return ["date", "dateTime", "monthYear"].includes(propValue);
      },
    },
    minuteIncrement: {
      type: Number,
      description:
        "custom intervals for the minute select options, set 60 for no increment to select",
      default: 1,
    },
    // modelConfig: {
    //   type: Object,
    //   description: "if you wantt display YYYY/MM/DD HH:MM in input :model-config='{ type: 'string', mask: 'YYYY/MM/DD HH:mm',}" ,
    //   default: function(){
    //     return {};
    //   }
    // },
    mask: {
      type: Object,
      description: ":mask='{ L: 'YYYY/MM/DD' }'",
      default: function () {
        return {};
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    displayName: {
      type: String,
      default: "Input",
    },
    showErrorText: {
      type: Boolean,
      default: true,
    },
    alwaysDirective: {
      type: String,
      default: "",
      description: "Only 2 params: up | down",
    },
  },

  data() {
    return {
      localDate: null,
      isTurningOnDatePicker: false,
    };
  },

  computed: {
    modeDatePicker() {
      return ["date", "dateTime"].includes(this.mode);
    },
    modeMonthYear() {
      return this.mode == "monthYear";
    },
    date: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
        // if (moment(newValue).isValid()) {
        //   this.$emit('input', moment(newValue).format(this.format));
        // } else {
        //   this.$emit('input', '');
        // }
      },
    },

    modelConfig() {
      return {
        type: "string",
        mask: this.getFormat,
      };
    },

    // get format
    getFormat() {
      if (this.format) return this.format;

      switch (this.mode) {
        case "date":
          return "YYYY/MM/DD";

        case "dateTime":
          return "YYYY/MM/DD HH:mm";

        default:
          return "L";
      }
    },

    masks() {
      return {
        input: "YYYY/MM/DD",
      };
    },

    getPlaceHolder() {
      if (this.placeholder) return this.placeholder;
      switch (this.mode) {
        case "date":
          return this.$t("panel.label_please_choose_date");
        case "dateTime":
          return this.$t("panel.label_please_choose_date_time");
        case "monthYear":
          return this.$t("panel.label_please_choose_month_year");
        default:
          return this.$t("panel.label_please_choose");
      }
    },

    displayFormatError() {
      if (this.format == "MM/DD") return "m/d";
      switch (this.mode) {
        case "date":
          return "Y/m/d";

        case "dateTime":
          return "Y/m/d H:i";

        case "monthYear":
          return "Y/m";
        default:
          return "";
      }
    },

    isErrorFormat() {
      switch (this.mode) {
        case "date":
          if (
            regexYYYYMMDD.test(this.date) ||
            regexMMDD.test(this.date) ||
            !this.date ||
            this.date.length == 0
          ) {
            return false;
          } else {
            return true;
          }
        case "dateTime":
          if (
            regexYYYYMMDDHHmm.test(this.date) ||
            !this.date ||
            this.date.length == 0
          ) {
            return false;
          } else {
            return true;
          }
        case "monthYear":
          if (
            regexYYYYMM.test(this.date) ||
            !this.date ||
            this.date.length == 0
          ) {
            return false;
          } else {
            return true;
          }
        default:
          return false;
      }
    },

    textErrorFormat() {
      if (this.isErrorFormat)
        return `${this.displayName}は${this.displayFormatError}形式で指定してください。`;
      return "";
    },
    inputMode() {
      return this.format == "MM/DD" ? "monthDate" : this.mode;
    },
  },

  watch: {
    date() {
      this.localDate = this.date;
      /*debounce(() => {
        if (new Date(this.date) != 'Invalid Date') {
          this.localDate = this.$moment(this.date).format(this.getFormat)
        }
      }, 500)*/
    },
    isErrorFormat() {
      if (this.isErrorFormat) {
        let cloneValidation = cloneDeep(this.$validation);
        delete cloneValidation[this.keyName];
        this.$setValidation({ ...cloneValidation });
      }
    },
  },

  methods: {
    handleClick() {
      this.$refs.monthlyPicker.openPickMonth();
    },
    handlePickDate(valueDate) {
      this.$refs.vDatePicker.hidePopover();
      if (valueDate && this.isTurningOnDatePicker) {
        this.date = this.$moment(valueDate).format(this.getFormat);
        this.localDate = this.date;
        this.removeValidation();
      }
    },

    onKeypress(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (this.mode == "date") {
        return (keyCode < 47 || keyCode > 57) && event.preventDefault();
      }
      (keyCode < 47 || keyCode > 58) && keyCode != 32 && event.preventDefault();
    },

    removeValidation() {
      let localvalidation = JSON.parse(JSON.stringify(this.validationResult));
      if (Object.prototype.hasOwnProperty.call(localvalidation, this.keyName)) {
        delete localvalidation[this.keyName];
        delete this.$validation[this.keyName];
        this.$setValidation(localvalidation);
        this.$emit("update:validationResult", localvalidation);
      }
    },
  },
};
</script>
<!-- note 
  if you wantt display YYYY/MM/DD HH:MM in input
   :model-config="{
                  type: 'string',
                  mask: 'YYYY/MM/DD HH:mm',
                  }"

    :mask="{ L: 'YYYY/MM/DD' }"

-->

<style lang="scss" scoped>
.calendar__button__trigger {
  border: none;
  outline: none;
  background-color: inherit;
}
.date-input {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  box-shadow: none;

  input {
    border: none;
    outline: none;
  }

  &:focus-within {
    background-color: #ffffff;
    border: 1px solid #aaaaaa;
    box-shadow: none;
    outline: 0 !important;
    color: #333333;
  }

  .icon {
    position: absolute;
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translate(-50%, -50%);
  }
}
</style>
