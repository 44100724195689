import {
  SET_USER_NAME,
  SET_TOKEN,
  SET_IS_AUTH,
  SET_REFRESH_AUTH,
  SET_ACCOUNT_INFO,
  SET_EXPIRED_AT,
  SET_PERMISSION_MENU,
  SET_STATUS_ACCOUNT,
  SET_PERMISSION_TREE_MENU,
} from "./types";

export default {
  [SET_USER_NAME]() {
    console.log("call mutations from user module");
  },
  [SET_TOKEN](state, token) {
    state.token = token;
  },
  [SET_IS_AUTH](state, status) {
    state.accountLogin.isAuth = status; // success or fail
  },
  [SET_REFRESH_AUTH](state) {
    (state.accountLogin = {
      isAuth: "fail",
      info: {},
    }),
      (state.token = null);
    state.expired_at = null;
    state.permission_menu = [];
  },
  [SET_ACCOUNT_INFO](state, { account_code, account_id }) {
    state.accountLogin.info = {
      account_id,
      account_code,
    };
  },
  [SET_EXPIRED_AT](state, miliseconds) {
    state.expired_at = miliseconds;
  },
  [SET_PERMISSION_MENU](state, permisionMenu) {
    state.permission_menu = permisionMenu;
  },
  SET_IS_SCREEN_MANAGE(state, is_screen_manage) {
    state.is_screen_manage = is_screen_manage;
  },
  [SET_STATUS_ACCOUNT](state, payload) {
    state.accountLogin.isAuth = payload; // payload is success or fail
  },
  [SET_PERMISSION_TREE_MENU]: (state, payload) => {
    state.permission_tree_menu = payload;
  },
};
