<template>
  <div class="for-template">
    <div v-if="!notFound" class="d-block">
      <b-card class="core__card__form" header-text-variant="white-text">
        <b-form autocomplete="false" v-on="$listeners" @submit.prevent>
          <!-- content -->
          <div class="core__card__form-content">
            <slot
              :management-can-create-edit-remove="managementCanCreateEditRemove"
            >
            </slot>
          </div>
          <!-- overlay -->
          <b-overlay
            v-if="useLoading"
            :show="loading"
            opacity="0.7"
            no-wrap
            spinner-variant="secondary"
            spinner-type="grow"
            spinner-small
            rounded
          />
        </b-form>
        <!-- SLOT ACTIONS -->
      </b-card>
      <b-card-footer :class="customClassFooter">
        <div :class="customClassFooter + '-actions'">
          <slot
            name="actions"
            :show-action="showAction"
            :open-modal-create-update="openModalCreateUpdate"
            :go-back-searching="goBackSearching"
            :disabled-execute-btn="disabledExecuteBtn"
            :management-can-create-edit-remove="managementCanCreateEditRemove"
            :show-btn-create-update="showBtnCreateUpdate"
            :update="update"
            :show-btn-delete="showBtnDelete"
            :is-disabled-delete-btn="isDisabledDeleteBtn"
            :open-modal-remove="openModalRemove"
          >
            <div
              :class="[
                'd-flex',
                showBtnBack ? 'justify-content-between' : 'justify-content-end',
              ]"
            >
              <!-- define searching page name to turn back -->
              <div>
                <b-button
                  v-if="showBtnBack"
                  type="button"
                  variant="secondary"
                  class="mr-2 btn-w-100"
                  @click="goBackSearching"
                >
                  {{ $t("panel.label_go_back") }}
                </b-button>
              </div>

              <div
                class="d-flex flex-wrap justify-content-end"
                style="gap: 5px"
              >
                <!-- define delete button -->
                <template v-if="update">
                  <b-button
                    v-if="showBtnDelete"
                    variant="danger"
                    class="btn-w-100"
                    :disabled="isDisabledDeleteBtn"
                    @click="openModalRemove"
                  >
                    {{ $t("panel.label_remove") }}
                  </b-button>
                </template>

                <!-- define extend buttons -->
                <slot name="extend-buttons"></slot>

                <!-- define create or update button -->
                <template v-if="showBtnCreateUpdate">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="btn-w-100"
                    :disabled="disabledExecuteBtn"
                    @click="openModalCreateUpdate"
                  >
                    {{
                      update
                        ? $t("panel.label_update")
                        : $t("panel.label_register")
                    }}
                  </b-button>
                </template>
              </div>
            </div>
          </slot>
        </div>
      </b-card-footer>
    </div>
    <div v-else class="d-flex g-5 w-100 w-100">
      <not-found-component />
    </div>
    <!-- SLOT MODAL -->
    <slot name="modals" :handle-ok="handleOk">
      <!-- Modal update-->
      <base-modal-confirm
        name="base-modal-create-update-confirm"
        :custom-confirm-text="confirmTextUpdate"
        @confirm-process="handleOk"
      >
      </base-modal-confirm>

      <!-- Modal delete -->
      <base-modal-confirm
        name="base-modal-remove-confirm"
        :custom-confirm-text="confirmTextDelete"
        remove
        @confirm-process="handleRemove"
      >
      </base-modal-confirm>
    </slot>
  </div>
</template>

<script>
import NotFoundComponent from "@/components/Errors/NotFoundComponent";
import useMainManagementPermission from "@/hooks/useManagementPermission/main";

export default {
  name: "BaseCardCreateUpdate",
  components: {
    NotFoundComponent,
  },

  props: {
    header: {
      type: String,
      default: "header-card",
    },
    useLoading: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },
    showRemoveButton: {
      type: Boolean,
      default: true,
    },
    confirmTextDelete: {
      type: String,
      default: "",
    },
    notFound: {
      type: Boolean,
      default: false,
    },
    disabledExecuteBtn: Boolean,
    disabledDeleteBtn: {
      type: Boolean,
      default: false,
    },
    goBackRouteName: {
      type: String,
      default: "",
    },
    usePermissionChecking: {
      type: Boolean,
      default: true,
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    confirmTextUpdate: {
      type: String,
      default: "",
    },
    showBtnBack: {
      type: Boolean,
      default: true,
    },
    hasSidebarMenu: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isUsingPermissionManagement, managementCanCreateEditRemove } =
      useMainManagementPermission({ type: "component" });
    return {
      isUsingPermissionManagement,
      managementCanCreateEditRemove,
    };
  },

  computed: {
    customClassFooter() {
      return this.hasSidebarMenu
        ? "sidebar__core__card__footer"
        : "core__card__footer";
    },
    showBtnCreateUpdate() {
      // duplicate code, if we want to check only create-button, we can add more logic here
      if (this.usePermissionChecking) {
        if (this.isUsingPermissionManagement) {
          if (this.showAction) {
            return this.managementCanCreateEditRemove;
          }
          return false;
        }
        return this.showAction;
      } else {
        return this.showAction;
      }
    },

    showBtnDelete() {
      // duplicate code, if we want to check only delete-button, we can add more logic here
      if (this.usePermissionChecking) {
        if (this.isUsingPermissionManagement) {
          if (this.showRemoveButton) {
            return this.managementCanCreateEditRemove;
          }
          return false;
        }
        return this.showRemoveButton;
      } else {
        return this.showRemoveButton;
      }
    },
    isDisabledDeleteBtn() {
      return this.loading || this.disabledDeleteBtn;
    },
  },

  created() {},
  methods: {
    handleRemove() {
      this.$emit("confirm-remove");
    },

    handleOk() {
      if (!this.isUsingPermissionManagement) {
        this.$emit("confirm-ok");
      } else {
        if (this.managementCanCreateEditRemove) {
          this.$emit("confirm-ok");
        } else {
          this.announcePermissionIsDeny();
          return;
        }
      }
    },

    reset() {
      this.$parent.reset();
    },
    goBackSearching() {
      if (this.goBackRouteName) {
        this.$router.push(window.goBackSearchingRoute(this.goBackRouteName));
      } else {
        this.$router.go(-1);
      }
    },
    openModalCreateUpdate() {
      if (this.openModal) {
        this.$modal.show("base-modal-create-update-confirm");
        return false;
      }
      if (!this.disabledExecuteBtn) {
        this.handleOk();
      }
      // this.$modal.show('base-modal-create-update-confirm')
      // requirement: not use modal to show dialog confirm
    },
    openModalRemove() {
      if (this.isUsingPermissionManagement) {
        if (this.managementCanCreateEditRemove) {
          this.$modal.show("base-modal-remove-confirm");
        } else {
          this.announcePermissionIsDeny();
        }
      } else {
        this.$modal.show("base-modal-remove-confirm");
      }
    },

    announcePermissionIsDeny() {
      this.$toast.error(
        this.$t("panel.label_management_is_not_allowed_use_this_feature"),
        {
          position: "top-right",
          timeout: 3000,
          icon: true,
          pauseOnFocusLoss: true,
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.core__card__form {
  margin-bottom: 40px;
  &-content {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    @media only screen and (min-width: 993px) {
      &__searching > .row div {
        padding: 2px 0px !important;
      }
    }
  }
  .title__searching {
    font-weight: 600;
    font-size: 15px;
    color: black;
    margin: 0;
  }

  &-actions {
    display: block;
    margin: 5px 0;
  }
}
.core__card__footer {
  //height: 60px;
  position: fixed;
  z-index: var(--zIndexFooterActions);
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #fff;
  &-actions {
    display: block;
  }
}

.sidebar__core__card__footer {
  //height: 60px;
  position: fixed;
  z-index: var(--zIndexFooterActions);
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-left: 260px;
  background-color: #fff;
  &-actions {
    display: block;
    margin-right: 260px;
  }
}

@media only screen and (max-width: 991px) {
  .sidebar__core__card__footer {
    margin: 0;
    &-actions {
      display: block;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .edic__admin__wrapper__content .sidebar__core__card__footer {
    margin: 0;
    &-actions {
      display: block;
      margin: 0;
    }
  }
}
.create__data__action {
  margin: 10px 0;
}
</style>
