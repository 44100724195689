import {
  SET_ADD_PROGRESS_IMPORT_TO_STACK,
  SET_PROGRESS_IMPORT,
  SET_FILTER_TASK_IMPORT,
} from "./types";

export default {
  [SET_ADD_PROGRESS_IMPORT_TO_STACK]: (state, importProgressing) => {
    const findProgressing = state.import_progressing.find(
      (item) => item.task_name === importProgressing.task_name
    );
    // only add once time
    if (!findProgressing) {
      state.import_progressing.push(importProgressing);
    }
  },
  [SET_PROGRESS_IMPORT]: (state, payload) => {
    const { task_name, response } = payload;
    state.import_progressing = state.import_progressing.map((item) => {
      if (item.task_name == task_name) {
        item.handleResponse(response);
      }
      return item;
    });
  },
  [SET_FILTER_TASK_IMPORT]: (state, task_name) => {
    state.import_progressing = state.import_progressing.filter(
      (item) => item.task_name != task_name
    );
  },
};
