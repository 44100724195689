import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "payment-method",
  name: "EdicAdminFacilityPaymentMethodManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-payment-method-management-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/PaymentMethod/Layout.vue"
    ),
  redirect: { name: "EdicAdminFacilityPaymentMethodEdit" },
  children: [
    {
      path: "",
      name: "EdicAdminFacilityPaymentMethodEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-payment-method-management" */ "@/views/EdicAdmin/Facilities/Manage/PaymentMethod/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
