<template>
  <div class="d-block">
    <b-form-input
      v-model="data"
      v-min-max-input-number="{ min: min, max: max }"
      v-validate-input="{ validation: validationResult, keyName: keyName }"
      :class="inputClass"
      type="text"
      autocomplete="off"
      :placeholder="$t('panel.label_place_holder')"
      :min="min"
      :max="max"
      :disabled="disabled"
      @keypress="inputNumber"
    ></b-form-input>
    <span
      v-if="showErrorText"
      v-error-input-text="{ validation: validationResult, keyName: keyName }"
    ></span>
  </div>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";

export default {
  name: "BaseInputNumber",
  mixins: [handleValidateMixin],
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    showErrorText: {
      type: Boolean,
      default: false,
    },
    inputClass: String,
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 255,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    inputNumber(e) {
      e = e || window.event;
      const backspaceKey = 8,
        point = 46,
        minus = 45;
      let value = e.target.value,
        charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
      let findDot = value.split("").filter((item) => item == ".").length;
      if (findDot == 1 && charCode == point) {
        e.preventDefault();
      }
      var charStr = String.fromCharCode(charCode);
      if (
        !charStr.match(/^(-|\+)*[0-9]+$/) &&
        ![backspaceKey, point, minus].includes(charCode) &&
        e.ctrlKey == false
      ) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control:disabled {
  background-color: #e9ecef;
}
</style>
