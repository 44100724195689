import { bffServiceInstance } from "@/plugins/api_service/services";

const prefix = "/management/auth";

export default {
  login: (credential) =>
    bffServiceInstance.post(`${prefix}/login`, {}, credential), // email, password, facility_code
  logout: () => bffServiceInstance.post(`${prefix}/logout`, {}, {}),
  getListOrganizationAccountLogin: (accountId) =>
    bffServiceInstance.get(
      `${prefix}/get-list-organization-account-login/${accountId}`
    ),
  getInfo: (idOrganization) =>
    bffServiceInstance.get(`${prefix}/get-info`, {
      organization: idOrganization,
    }),
};
