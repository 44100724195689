<template>
  <div class="d-block">
    <slot></slot>

    <slot name="action">
      <div class="change__profile__wrapper-action__btn mt-5">
        <div class="item">
          <b-button class="d-flex align-items-center" variant="danger" block>
            <span class="mx-auto">登録する</span>
          </b-button>
        </div>
        <div class="item">
          <b-button class="d-flex align-items-center" block>
            <span class="mx-auto">戻る</span>
          </b-button>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "ClientJbbfCreateUpdateCard",
};
</script>

<style></style>
