<template>
  <div>
    <template v-if="false">
      <b-overlay :show="false" rounded spinner-small>
        <b-form-input disabled />
      </b-overlay>
    </template>
    <base-select
      v-else
      v-model="selected"
      :show-place-holder="showPlaceHolder"
      :hide-search="hideSearch"
      :options="optionsInput"
      :disabled="disabled"
      :error="isFailValidation"
    ></base-select>
    <div
      v-show="isFailValidation && showTextValidation"
      class="is-invalid-feedback-message"
    >
      {{ textValidation }}
    </div>
  </div>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import singleSelectionMixin from "@/mixins/single-selection-mixin";

export default {
  name: "BaseSingleSelect",
  mixins: [handleValidateMixin, singleSelectionMixin],
  props: {
    showTextValidation: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style></style>
