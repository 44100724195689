import moment from "moment";
import { dependencyOperatorType } from "@/constants/commons";

const constantDefineConstants = {
  ["LogicalFunc::getAgeByYear"]: {
    description:
      "get the age from the current year - value of basic_info.birthday",
    callback: ({ basic_info, value_compare, operator_type }) => {
      const { birthday = "" } = basic_info;
      const validBirthday = moment(birthday, "YYYY/MM/DD", true);
      const currentYear = moment().year();

      if (!birthday || !validBirthday.isValid()) return false;

      const supportOperatorType = {
        [dependencyOperatorType.more.value]: () =>
          currentYear - validBirthday.year() >= parseInt(value_compare),
        [dependencyOperatorType.less.value]: () =>
          currentYear - validBirthday.year() <= parseInt(value_compare),
        [dependencyOperatorType.equal.value]: () =>
          currentYear - validBirthday.year() == parseInt(value_compare),
      };

      return operator_type in supportOperatorType
        ? supportOperatorType[operator_type]()
        : false;
    },
  },
};

const checkLogicalFuncNameAndExecute = (
  logical_function = "",
  { basic_info = {}, extend_info = {}, value_compare, operator_type }
) => {
  if (!logical_function)
    throw new Error("'logic_function' should be a string!");
  const functionName = logical_function.match(/[a-zA-Z]+::[a-zA-Z]+/)[0];
  if (!functionName) return false;
  if (functionName in constantDefineConstants) {
    return constantDefineConstants[functionName].callback({
      basic_info,
      extend_info,
      value_compare,
      operator_type,
    });
  }
  return false;
};
export { constantDefineConstants, checkLogicalFuncNameAndExecute };
