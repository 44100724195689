/* eslint-disable */
import { 
    ACT_CLIENT_LOGIN_ACCOUNT,
    ACT_CLIENT_LOGOUT, 
    ACT_CLIENT_GET_ACCOUNT_INFO,
    SET_CLIENT_TOKEN,
    SET_CLIENT_REFRESH_AUTH,
    SET_CLIENT_ACCOUNT_INFO,
    SET_CLIENT_EXPIRED_AT,
    ACT_FORCE_FRESH_AUTH,
    ACT_SYNC_CLIENT_AUTH_INFO,
    SET_CLIENT_PERMISSION_MENU,
    SET_CLIENT_LOGIN_ORGAIZATION,
    SET_CLIENT_MEMBER_TYPES,
    SET_CLIENT_MENU_HEADER,
    SET_CLIENT_MEMBER_CARD_DESIGNS,
    ACT_FORCE_GET_SYNC_CLIENT_INFO
} from "./types";
import _ from 'lodash';
import storage from '@/helpers/storageManage';
import authClientService from '@/services/client/auth/login';
import store from '@/store';
import { SET_ORGANIZATION_INSTANCE } from '@/store/modules/Organization/types';

import { 
    ApplicationClientStorageToken, 
    ApplicationClientIsAuth, 
    ApplicationClientTokenExpiredAt,
    ApplicationClientAccountInfo,
    ApplicationClientPaymentInfo,
    ApplicationClientAccountTemp,
    ApplicationClientCorporateCode,
} from '@/plugins/setting'

import moment from 'moment';
import { accountTemp } from "@/constants/commons";
// import _ from "lodash";

export default {
    [ACT_CLIENT_LOGIN_ACCOUNT]: async ({state, commit}, payload) => {
        try {
            let response = await authClientService.login(payload);
            let { access_token, account_code, account_id, facility_code, expires_in, account_info } = response;
            let expiredAt = moment().add(expires_in, 'minutes').valueOf() // miliseconds 
            
            // state
            state.clientAccountLogin.isAuth = 'success';

            // commit
            commit(SET_CLIENT_TOKEN, access_token);
            commit(SET_CLIENT_ACCOUNT_INFO, { account_code, account_id, ...account_info });
            commit(SET_CLIENT_EXPIRED_AT, expiredAt)
            // 
            storage.setStorage({ 
                [ApplicationClientStorageToken]: access_token, 
                [ApplicationClientIsAuth]: 'success',
                [ApplicationClientTokenExpiredAt]: expiredAt,
                [ApplicationClientAccountInfo]: JSON.stringify({ account_id, account_code, facility_code, ...account_info })
            });
            localStorage.setItem(ApplicationClientAccountTemp, account_info.temp_flag)
            localStorage.setItem(ApplicationClientCorporateCode, account_info.corporate_code || "")
        } catch (err) {
           return Promise.reject(err);
        }
    },
    [ACT_CLIENT_LOGOUT]: async ({dispatch}) => { //todo edit
        await authClientService.logout();
        // remove localStrong of client
        dispatch(ACT_FORCE_FRESH_AUTH)
        //
        //handle redirect to page login in file src\layouts\Client\Jbbf\ClientJbbfTheNavbar.vue at watch is isAuthClient
    },
    [ACT_CLIENT_GET_ACCOUNT_INFO]: async () => { //todo edit
        try {
            // let res = await authClientservice.getInfo();
            // console.log(res)
        } catch (err) {
            console.log(err)
            return;
        }
    },
    [ACT_FORCE_FRESH_AUTH]: ({ commit }) => {
        storage.removeStorage(ApplicationClientStorageToken);
        storage.removeStorage(ApplicationClientIsAuth);
        storage.removeStorage(ApplicationClientTokenExpiredAt);
        storage.removeStorage(ApplicationClientAccountInfo);
        storage.removeStorage(ApplicationClientAccountTemp);
        storage.removeStorage(ApplicationClientCorporateCode);
        storage.removeStorage(ApplicationClientPaymentInfo);
        commit(SET_CLIENT_REFRESH_AUTH);
    },

    // bad solution ---> will refactor in future
    [ACT_FORCE_GET_SYNC_CLIENT_INFO]: async ({ commit }) => {
        try {
            let response = await authClientService.getInfo();
            let { permission_menu, header_menu, organization, account } = response;
            // set account
            if (account) {
                commit(SET_CLIENT_ACCOUNT_INFO, { ...account });
            }
            // set permission menu
            commit(SET_CLIENT_PERMISSION_MENU, permission_menu || []);
            // set organization login
            commit(SET_CLIENT_LOGIN_ORGAIZATION, organization.id || '');
            //
            store.commit(`Organization/${SET_ORGANIZATION_INSTANCE}`, organization || null);

            // set client member type
            commit(SET_CLIENT_MEMBER_TYPES, account.member_type_details || []);
            // set account_temp
            if (account && account.temp_flag) {
                localStorage.setItem(ApplicationClientAccountTemp, account.temp_flag);
            }
            // set header menu
            commit(SET_CLIENT_MENU_HEADER, header_menu || []);
            // set member card design
            commit(SET_CLIENT_MEMBER_CARD_DESIGNS, account.member_card_designs || [])
        } catch (e) {
            return Promise.reject(e);
        }
    },

    [ACT_SYNC_CLIENT_AUTH_INFO]: async ({ state, commit }) => {
        if (!state.clientPermissionMenu.length || 
            !state.login_organization ||
            !state.member_types || 
            !state.client_menu_header.length
        ) {
            try {
                let response = await authClientService.getInfo();
                let { permission_menu, header_menu, organization, account } = response;
                // set account
                if (account) {
                    commit(SET_CLIENT_ACCOUNT_INFO, { ...account });
                }
                // set permission menu
                commit(SET_CLIENT_PERMISSION_MENU, permission_menu || []);
                // set organization login
                commit(SET_CLIENT_LOGIN_ORGAIZATION, organization.id || '');

                // format menu and store
                let menuFormat = [];
                permission_menu.forEach(function m(el) {
                    menuFormat.push({
                        ...el,
                        title: el.name
                    })
                    if (el.children && el.children.length) {
                        el.children.forEach(m);
                    }
                })
                state.permission_menu = menuFormat;
                //
                store.commit(`Organization/${SET_ORGANIZATION_INSTANCE}`, organization || null);

                // set client member type
                commit(SET_CLIENT_MEMBER_TYPES, account.member_type_details || []);
                // set account_temp
                if (account && account.temp_flag) {
                    localStorage.setItem(ApplicationClientAccountTemp, account.temp_flag);
                }
                // set header menu
                commit(SET_CLIENT_MENU_HEADER, header_menu || []);
                // set member card design
                commit(SET_CLIENT_MEMBER_CARD_DESIGNS, account.member_card_designs || []);

                // process get Info done
                state.process_get_info_is_done = true;
            } catch (e) {
                return Promise.reject(e);
            }
        }
    },
}