import {
  SET_ACTIVE_PARENT_ID,
  SET_OPENING_PARENT_ID,
  SET_PREVIOUS_OPENING_ITEM_HAVE_CHILDREN,
} from "./types";

export default {
  [SET_ACTIVE_PARENT_ID]: (state, payload) => {
    state.active_parent_id = payload;
  },

  [SET_OPENING_PARENT_ID]: (state, payload) => {
    state.opening_parent_id = payload;
  },

  [SET_PREVIOUS_OPENING_ITEM_HAVE_CHILDREN]: (state, payload) => {
    state.previous_opening_item_have_children = payload;
  },
};
