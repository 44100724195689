import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import storage from "@/helpers/storageManage";
import {
  ApplicationClientStorageToken,
  ApplicationClientIsAuth,
  ApplicationClientTokenExpiredAt,
  ApplicationClientAccountTemp,
} from "@/plugins/setting";
import { accountTemp } from "@/constants/commons";

const state = () => ({
  clientProfile: {
    name: "client_profile",
  },
  clientExpiredAt: storage.getStorage(ApplicationClientTokenExpiredAt), // the time that token is not valid anymore
  clientToken: storage.getStorage(ApplicationClientStorageToken),
  clientAccountLogin: {
    isAuth: storage.getStorage(ApplicationClientIsAuth) || "fail",
    info: {},
  },
  clientPermissionMenu: [],
  accountTemp: localStorage.getItem(ApplicationClientAccountTemp)
    ? parseInt(localStorage.getItem(ApplicationClientAccountTemp))
    : accountTemp,
  login_organization: "",
  member_types: [],
  client_menu_header: [],
  member_card_designs: [],
  permission_menu: [],
  process_get_info_is_done: false,
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
