import Store from "@/store";
import { computed } from "vue";
import { GET_PROGRESSING_TRACKING_ITEM } from "@/store/modules/ExportProgressing/types";
import i18n from "@/plugins/i18n";

export default function useProgressingBarTracking({ task_name = "" }) {
  const trackingProgressInstance = computed(() => {
    return Store.getters[`ExportProgressing/${GET_PROGRESSING_TRACKING_ITEM}`](
      task_name
    );
  });

  // status
  const inProgress = computed(() => trackingProgressInstance.value.in_progress);
  const isLoading = computed(() => trackingProgressInstance.value.loading);
  const isGetDownLoadUrlDone = computed(
    () => isCompleted.value && trackingProgressInstance.value.url_download
  );
  const isCompleted = computed(
    () => trackingProgressInstance.value.is_completed
  );
  const isError = computed(() => trackingProgressInstance.value.error);
  const urlDownload = computed(
    () => trackingProgressInstance.value.url_download
  );
  // icon Name
  const iconName = computed(() => {
    if (isError.value) {
      return "ic:round-warning";
    }
    if (inProgress.value) {
      return "svg-spinners:bars-rotate-fade";
    }
    if (isCompleted.value) {
      return "material-symbols:check-circle-rounded";
    }
    return "";
  });

  // status display
  const statusDisplay = computed(() => {
    if (isError.value) {
      return i18n.t("export-csv.label_export_fail");
    }
    if (isLoading.value) {
      return i18n.t("export-csv.label_waiting");
    }

    if (inProgress.value) {
      return i18n.t("export-csv.label_inprocessing");
    }

    if (isCompleted.value) {
      return i18n.t("export-csv.label_export_complete");
    }

    return "";
  });

  // colorIcon
  const colorIcon = computed(() => {
    if (isCompleted.value) {
      return "#28a745";
    }
    if (isError.value) {
      return "#dc3545";
    }
    return "#000000";
  });

  // color progress bar
  const bgColorProgressBar = computed(() => {
    if (isError.value) {
      return "#dc3545";
    }
    if (isCompleted.value) {
      return "#28a745";
    }

    if (inProgress.value) {
      return "#006fe6";
    }
    return "";
  });

  return {
    trackingProgressInstance,
    statusDisplay,
    iconName,
    colorIcon,
    bgColorProgressBar,
    isError,
    isGetDownLoadUrlDone,
    urlDownload,
    inProgress,
    isCompleted,
  };
}
