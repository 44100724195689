<template>
  <div class="d-block">
    <template v-if="can_access_page">
      <slot></slot>
    </template>
  </div>
</template>

<script>
import useInitializePermission from "@/hooks/useClientPermission/useInitializePermission";

export default {
  name: "BaseClientPermissionView",
  setup() {
    let { view_flag, menu_id, id, can_access_page } = useInitializePermission();
    return {
      view_flag,
      menu_id,
      id,
      can_access_page,
    };
  },
};
</script>
