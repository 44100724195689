import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  search: {
    search_fields: {},
    form: {},
    result_fields: {},
  },
  exportCsv: {
    loading: false,
    error: false,
  },

  importCsv: {
    file: null,
    loading: false,
    csv_content: {
      data: [],
      meta: {},
      errors: [],
    },
    validation: {},
    validation_source: null,
    error: false,
    success: false,
    total_insert_admins: 0,
    total_updated_admins: 0,
  },

  detail: {
    basic_info: {},
    extend_info: {},
  },
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
