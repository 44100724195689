import _ from "lodash";

const bindErrorValidation = (el, binding) => {
  let { validation, keyName } = binding.value;
  if (
    !_.isEmpty(validation) &&
    Object.prototype.hasOwnProperty.call(validation, keyName)
  ) {
    el.classList.add("is-invalid");
  } else {
    el.classList.remove("is-invalid");
  }
};

export default {
  bind: bindErrorValidation,
  update: bindErrorValidation,
};
