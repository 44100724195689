import { mapGetters } from "vuex";
import {
  GET_FACILITY_CODE,
  GET_CHECKING_FACILITY_USING_APP,
  GET_CHECKING_FACILITY_USING_ORGANIZATION_STRUCTURE,
  GET_CHECKING_FACILITY_USING_CORPORATION_STRUCTURE,
} from "@/store/modules/Facility/types";
import { GET_ID_ORGANIZATION_NEXT_LOGIN } from "@/store/modules/Organization/types";
import {
  GET_CLIENT_LOGIN_ORGANIZATION,
  GET_CLIENT_ACCOUNT_ID,
} from "@/store/modules/AuthClient/types";
import { GET_ACCOUNT_ID } from "@/store/modules/AuthManage/types";
import {
  GET_FACILITY_MANAGEMENT_CODE,
  GET_FACILITY_MANAGEMENT_ID,
} from "@/store/modules/FacilityManagement/types";
import { maxValueNumber } from "@/constants/commons";

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        ...mapGetters("Facility", {
          $facilityCode: GET_FACILITY_CODE,
          $facilityUseAppOrNot: GET_CHECKING_FACILITY_USING_APP,
          $facilityOrganizationStructure:
            GET_CHECKING_FACILITY_USING_ORGANIZATION_STRUCTURE,
          $facilityCorporationStructure:
            GET_CHECKING_FACILITY_USING_CORPORATION_STRUCTURE,
        }),
        ...mapGetters("Organization", {
          $loginOrganization: GET_ID_ORGANIZATION_NEXT_LOGIN,
        }),
        ...mapGetters("AuthClient", {
          $clientLoginOrganization: GET_CLIENT_LOGIN_ORGANIZATION,
          $clientAccountId: GET_CLIENT_ACCOUNT_ID,
        }),
        ...mapGetters("AuthManage", {
          $manageAccountId: GET_ACCOUNT_ID,
        }),
        ...mapGetters("FacilityManagement", {
          $edicAdminFacilityCode: GET_FACILITY_MANAGEMENT_CODE,
          $edicAdminFacilityId: GET_FACILITY_MANAGEMENT_ID,
        }),
        $maxValueNumber: () => maxValueNumber,
      },
    });
  },
};
