import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import storage from "@/helpers/storageManage";
import {
  ApplicationManageStorageToken,
  ApplicationManageIsAuth,
  ApplicationManageTokenExpiredAt,
  ApplicationManageAccountInfo,
} from "@/plugins/setting";

const state = () => ({
  profile: {
    name: "",
  },
  expired_at: storage.getStorage(ApplicationManageTokenExpiredAt), // the time that token is not valid anymore
  token: storage.getStorage(ApplicationManageStorageToken),
  accountLogin: {
    isAuth: storage.getStorage(ApplicationManageIsAuth) || "fail",
    info: storage.getStorage(ApplicationManageAccountInfo)
      ? JSON.parse(storage.getStorage(ApplicationManageAccountInfo))
      : {},
  },
  permission_menu: [],
  permission_tree_menu: [],
  is_screen_manage: true,
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
