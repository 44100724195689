import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "account-permissions",
  name: "AccountPermission",
  component: () =>
    import(
      /* webpackChunkName: "account-permission-layout-view" */ "@/views/Manage/AccountPermission/LayoutView.vue"
    ),
  redirect: { name: "AccountPermissionSearchUpdate" },
  children: [
    {
      path: "",
      name: "AccountPermissionSearchUpdate",
      component: () =>
        import(
          /* webpackChunkName: "account-permission-search-update-view" */ "@/views/Manage/AccountPermission/SearchUpdate.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
