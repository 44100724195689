import Store from "@/store";
import { ACT_FORCE_FRESH_AUTH } from "@/store/modules/AuthManage/types";
import Router from "@/router";
import { checkAuthAdminLifeTime } from "@/helpers/authenticate";

export default async function authClient({ to, next }) {
  const isAuthAdmin = checkAuthAdminLifeTime();
  // not authenticated
  if (!isAuthAdmin) {
    let { path } = to;
    // remove store
    Store.dispatch(`AuthAdmin/${ACT_FORCE_FRESH_AUTH}`);
    setTimeout(() => {
      Router.push({ name: "EdicAdminLogin", query: { redirect: path } });
    }, 500);
  } else {
    // authenticate is TRUE
    if (to.name === "EdicAdminLogin") {
      //
      return Router.push({ name: "EdicOverView" });
    }
    return next();
  }
}
