export default {
  inserted: (el) => {
    function loadImage() {
      const imageElement = Array.from(el.children).find(
        (el) => el.nodeName === "IMG"
      );

      if (imageElement) {
        // if find element is IMAGE
        imageElement.addEventListener("load", () => {
          el.classList.add("filter");
          setTimeout(() => {
            el.querySelector(".loading__image").style.display = "none";
            el.classList.remove("filter");
          }, 1000);
        });

        imageElement.addEventListener("error", () => console.log("error"));
        imageElement.src = imageElement.dataset.url;
        // console.log(imageElement.dataset)
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: "0",
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }

    if (window["IntersectionObserver"]) {
      createObserver();
    } else {
      loadImage();
    }
  },
};
