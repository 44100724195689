<template>
  <component
    :is="tag"
    v-bind="$attrs"
    custom
    router-link-exact-active
    @click.native="hideSidebar"
    v-slot="{ navigate, href, isActive }"
  >
    <li :class="{ active: isActive && $attrs.to != '' }" @click="navigate">
      <a class="nav-link" :href="href" @click="navigate">
        <slot :is-active="isActive">
          <i v-if="link.icon" :class="link.icon"></i>
          <p>{{ link.name }}</p>
        </slot>
      </a>
    </li>
  </component>
</template>
<script>
export default {
  inject: {
    autoClose: {
      default: true,
    },
  },
  inheritAttrs: false,
  props: {
    link: {
      type: [String, Object],
      default: () => {
        return {
          name: "",
          path: "",
          icon: "",
        };
      },
    },
    tag: {
      type: String,
      default: "router-link",
    },
  },
  methods: {
    hideSidebar() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style></style>
