<template>
  <div class="d-block selection-base-component">
    <select class="form-control select2" v-bind="$props">
      <slot></slot>
    </select>
    <div v-if="errorFetch" class="d-block mt-1 is-invalid-feedback-message">
      <span>{{ $t("panel.fail") }}</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import $ from 'jquery';

export default {
    name: 'BaseSelect',
    props: {
        options: Array,
        value: [String, Number, Array],
        errorFetch: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        showPlaceHolder: {
            type: Boolean,
            default: true
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showOptionAll: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        textPlaceholder: {
            type: String, 
            default: ''
        },
        optionHtml: {
            type: Boolean,
            default: false,
        },
        showLabelNoData: {
            type: Boolean,
            default: false,
        },
        inModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            localOptions: []
        }
    },
    computed: {
        labelDefault() {
            let defaultPlaceHolder = this.textPlaceholder || this.$t("panel.label_please_choose")
            let data = [{
                id: '',
                text:  this.options.length ? defaultPlaceHolder : (this.showLabelNoData ? this.$t("panel.label_no_data") : this.$t("panel.label_please_choose")),
            }]
            return data;
        },
        configSelect2() {
            let config = {
                // theme: 'bootstrap4',
                data: this.localOptions,
                width: '100%',
                disabled: this.disabled,
                // multiple: true,
                language: {
                    noResults: function ()  {
                        return this.$t("panel.select2_not_found")
                    }.bind(this)
                },
            };
            if (this.hideSearch) {
                config.minimumResultsForSearch = -1
            }
            // check multiple to turn on multiple = true
            if (this.multiple) {
                config.multiple = true;
                config.placeholder = this.$t('panel.label_please_choose')
            } else {
                config.theme = 'bootstrap4'
            }
            if (this.optionHtml) {
                config.templateResult = function (d) {
                    return $(d.text);
                }
                config.templateSelection = function (d) {
                    return $(d.text).text();
                }
            }
            if (this.inModal) {
                config.dropdownParent = $('.modal-content');
            }
           

            return config;
        }
    },
    created() {
        window.addEventListener("resize", this.resizeWidth);
    },
    mounted: function() {
        this.initializeOption();
        var vm = this;
        var element = this.$el.querySelector('.select2');
        $(element)
            // init select2
            .select2(
                this.configSelect2
            )
            .val(this.value)
            .trigger("change")
            // emit event on change.
            .on("change", function() {
                vm.$emit("input", this.value);
            });
        // return empty when component mounted - if data isn't contained inside option => return empty
        this.returnEmpty();

        // check error flag
        if (this.error || this.errorFetch) {
            $(this.$el.querySelector('.select2')).addClass('is-invalid');
        }

        // case: multiple
       
        this.$nextTick(() => {
            if (this.multiple) {
                let elementSelectMultiple = this.$el.querySelector('.select2-selection--multiple'),
                    elementSearchText = this.$el.querySelector('.select2-search__field');
                elementSelectMultiple.style.minHeight = '40px'
                elementSelectMultiple.style.borderColor = '#E3E3E3';
                elementSearchText.style.marginTop = '8px';
            }
        })
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWidth);
        $(this.$el.querySelector('.select2'))
            .off()
            .select2("destroy");
    },
    watch: {
        value: {
            immediate: true,
            handler: function (value) {
                if (this.$el && this.$el.querySelector('.select2')) {
                    // update value
                    $(this.$el.querySelector('.select2'))
                        .val(value)
                        .trigger("change");
                }
            }
        },
        // value: function(value) {
        //     // update value
        //     $(this.$el.querySelector('.select2'))
        //         .val(value)
        //         .trigger("change");
        // },
        options: function() {
            this.initializeOption()
            // update options
            $(this.$el.querySelector('.select2'))
                .empty()
                .select2(this.configSelect2);

            $(this.$el.querySelector('.select2'))
                .val(this.value)
                .trigger('change');

            // this.returnEmpty();

        },
        error: function(value) {
            if (value) {
                $(this.$el.querySelector('.select2')).addClass('is-invalid');
            } else {
                $(this.$el.querySelector('.select2')).removeClass('is-invalid');
            }
        },
        errorFetch: function(value) {
            if (value) {
                $(this.$el.querySelector('.select2')).addClass('is-invalid');
            } else {
                $(this.$el.querySelector('.select2')).removeClass('is-invalid');
            }
        } 
    },
    methods: {
        initializeOption() {
            if (this.showPlaceHolder && !this.multiple) {
                this.localOptions = [...this.labelDefault, ...this.options];
            } else {
                this.localOptions = [...this.options];
            }
            
            // if turn on show option all
            if (this.showOptionAll) {
                this.localOptions = [{ id: '', text: this.$t('panel.label_all')}, ...this.localOptions];
            } 
        },
        resizeWidth() {
            $(this.$el.querySelector('.select2'))
                .select2(this.configSelect2)
        },
        returnEmpty() {
            const find = this.options.find(item => {
                if(item.children) {
                    const foundItem = item.children.find((childItem)=>childItem.id == this.value)
                    return foundItem ? true : false;
                }
                return item.id == this.value
            });
            if (!find) {
                this.$emit('input', '');
            }
        },
        
    }
}
</script>

<style lang="scss">
    .select2-results {
        border-top: 1px solid #AAAAAA;
    }
    .select2-container--bootstrap4.select2-container--focus .select2-selection {
        box-shadow: none;
        border-color: #AAAAAA;
    }
    .select2-container--bootstrap4 .select2-selection--single {
        height: calc(1.5em + 0.75rem + 4px) !important;
    }
    .select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
        line-height: calc(1.5em + 1rem);
    }
</style>
