import {
  ACT_LOGIN_MEMBER,
  ACT_LOGIN_ACCOUNT,
  ACT_LOGOUT,
  ACT_GET_ACCOUNT_INFO,
  SET_TOKEN,
  SET_REFRESH_AUTH,
  SET_ACCOUNT_INFO,
  SET_EXPIRED_AT,
  ACT_GET_MANAGE_INFO,
  SET_STATUS_ACCOUNT,
  ACT_FORCE_FRESH_AUTH,
  // SET_PERMISSION_TREE_MENU
} from "./types";

import { SET_CLOSE_PROGRESS_AND_FRESH } from "@/store/modules/ExportProgressing/types";

import { ACT_FORCE_FRESH_IMPORT_CSV } from "@/store/modules/ManageMember/types";

import { ACT_SET_REFRESH_DEFAULT } from "@/store/modules/Organization/types";

import storage from "@/helpers/storageManage";
import authservice from "@/services/auth/auth_manage";
import { menuManageType } from "@/constants/commons";

import {
  ApplicationManageStorageToken,
  ApplicationManageIsAuth,
  ApplicationManageTokenExpiredAt,
  ApplicationManageAccountInfo,
  ApplicationManageProfile,
} from "@/plugins/setting";

import moment from "moment";
// import _ from "lodash";

export default {
  [ACT_LOGIN_MEMBER]() {
    console.log("call action from user module");
  },

  [ACT_LOGIN_ACCOUNT]: async ({ commit }, payload) => {
    let response = await authservice.login(payload);
    let {
      access_token,
      account_code,
      account_id,
      expires_in,
      system_admin_flag,
      first_name,
      last_name,
    } = response;

    let expiredAt = moment().add(expires_in, "minutes").valueOf(); // miliseconds

    // commit
    commit(SET_TOKEN, access_token);
    commit(SET_ACCOUNT_INFO, { account_code, account_id });
    commit(SET_EXPIRED_AT, expiredAt);
    commit(SET_STATUS_ACCOUNT, "success");
    //
    storage.setStorage({
      [ApplicationManageStorageToken]: access_token,
      [ApplicationManageIsAuth]: "success",
      [ApplicationManageTokenExpiredAt]: expiredAt,
      [ApplicationManageAccountInfo]: JSON.stringify({
        account_id,
        account_code,
        system_admin_flag,
      }),
      [ApplicationManageProfile]: JSON.stringify({ first_name, last_name }),
    });
  },
  [ACT_LOGOUT]: async ({ dispatch }) => {
    await authservice.logout();
    dispatch(ACT_FORCE_FRESH_AUTH);
  },

  [ACT_FORCE_FRESH_AUTH]: async ({ commit, dispatch }) => {
    storage.removeStorage(ApplicationManageStorageToken);
    storage.removeStorage(ApplicationManageIsAuth);
    storage.removeStorage(ApplicationManageTokenExpiredAt);
    storage.removeStorage(ApplicationManageAccountInfo);
    storage.removeStorage(ApplicationManageProfile);

    dispatch(`Organization/${ACT_SET_REFRESH_DEFAULT}`, null, { root: true }); // refresh organization_selection
    dispatch(`ManageMember/${ACT_FORCE_FRESH_IMPORT_CSV}`, null, {
      root: true,
    });
    dispatch(`ExportProgressing/${SET_CLOSE_PROGRESS_AND_FRESH}`, null, {
      root: true,
    });

    commit(SET_REFRESH_AUTH);
  },

  [ACT_GET_ACCOUNT_INFO]: async () => {
    try {
      let res = await authservice.getAccountInfo();
      console.log(res);
    } catch (err) {
      console.log(err);
      return;
    }
  },
  [ACT_GET_MANAGE_INFO]: async ({ state }, payload) => {
    let { id_organization } = payload;
    try {
      if (!state.permission_menu.length) {
        try {
          let res = await authservice.getInfo(id_organization);
          let menuFormat = [];
          res.permission_menu.map(function f(el) {
            // const buildUrl = (item) => {
            //     if (!_.isEmpty(el.url)) {
            //         if (item.type && item.type == menuManageType.event.value && item.event_cms_code) {
            //             return `/manage/events/${item.url}/${item.event_cms_code}/${item.menu_id}`;
            //         }

            //         return `/manage/${item.url}`;
            //     }
            //     return "";
            // }

            menuFormat.push({
              id: el.id,
              url: el.url,
              icon: el.icon,
              title: el.name,
              menu_id: el.menu_id,
              facility_menu_id: el.facility_menu_id,
              type: el.type,
              event_cms_code:
                el.type == menuManageType.event.value
                  ? el.event_cms_code
                  : null,
              edit_flag: el.edit_flag,
              icon_type: el.icon_type,
              icon_color: el.icon_color,
              icon_prefix: el.icon_prefix,
              icon_name: el.icon_name,
              customize_icon_active_image_path:
                el.customize_icon_active_image_path,
              customize_icon_inactive_image_path:
                el.customize_icon_inactive_image_path,
            });

            if (el.children && el.children.length) {
              return el.children.map(f);
            }
            return el;
          });
          state.permission_menu = menuFormat;
          state.permission_tree_menu = res.permission_menu;
          // console.log(rootState)
          // state.organization_info = res;
        } catch (e) {
          return Promise.reject(e);
        }
      }
    } catch (err) {
      return false;
    }
  },
};
