import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import storage from "@/helpers/storageManage";
import {
  ApplicationAdminStorageToken,
  ApplicationAdminIsAuth,
  ApplicationAdminTokenExpiredAt,
  ApplicationAdminAccountInfo,
} from "@/plugins/setting";

const state = () => ({
  expired_at: storage.getStorage(ApplicationAdminTokenExpiredAt), // the time that token is not valid anymore
  token: storage.getStorage(ApplicationAdminStorageToken),
  accountLogin: {
    isAuth: storage.getStorage(ApplicationAdminIsAuth) || "fail",
    info: storage.getStorage(ApplicationAdminAccountInfo)
      ? JSON.parse(storage.getStorage(ApplicationAdminAccountInfo))
      : {},
  },
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
