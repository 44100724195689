import { bffServiceInstance } from "@/plugins/api_service/services";

const prefix = "/admin/facilities";

export default {
  validURL: (domain) =>
    bffServiceInstance.get(`${prefix}/check-login-facility/${domain}`, {
      useCancelToken: false,
    }),
  getListMenu: (query) =>
    bffServiceInstance.get(`${prefix}/menu/get-list-menu`, query),
};
