import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "account-cms-groups",
  name: "EdicAdminFacilityAccountCmsGroupManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-account-cms-group-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/AccountCmsGroup/Layout"
    ),
  redirect: { name: "EdicAdminFacilityAccountCmsGroupSearch" },
  children: [
    {
      path: "",
      name: "EdicAdminFacilityAccountCmsGroupSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-account-cms-group-search" */ "@/views/EdicAdmin/Facilities/Manage/AccountCmsGroup/Search"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create", // /admin/account-cms-groups/create
      name: "EdicAdminFacilityAccountCmsGroupCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-account-cms-group-create" */ "@/views/EdicAdmin/Facilities/Manage/AccountCmsGroup/Create"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id", // /admin/account-cms-groups/edit/1
      name: "EdicAdminFacilityAccountCmsGroupEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-account-cms-group-edit" */ "@/views/EdicAdmin/Facilities/Manage/AccountCmsGroup/Edit"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
