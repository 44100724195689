import $ from "jquery";

export default {
  bind: function (el) {
    el.handler = function (evt) {
      evt = evt ? evt : window.event;
      $(this).val(
        $(this)
          .val()
          .replace(/[^0-9]-/g, "")
      );
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode >= 48 && charCode <= 57) ||
        charCode === 45 ||
        charCode === 8 ||
        charCode === 189
      ) {
        return true;
      } else {
        event.preventDefault();
      }
    };
    el.addEventListener("keypress", el.handler);
    el.addEventListener("change", el.handler);
  },
  unbind: function (el) {
    el.removeEventListener("keypress", el.handler);
    el.removeEventListener("change", el.handler);
  },
};
