import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "customize-page",
  name: "CustomizePage",
  component: () =>
    import(
      /* webpackChunkName: "customize-page-management-layout-view" */ "@/views/Manage/CustomizePage/LayoutView.vue"
    ),
  redirect: { name: "CustomizePageSetting" },
  children: [
    {
      path: "",
      name: "CustomizePageSetting",
      component: () =>
        import(
          /* webpackChunkName: "customize-page-setting-view" */ "@/views/Manage/CustomizePage/CustomizePageV2.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
