import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "member-type",
  name: "EdicAdminFacilityMemberTypeManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-facility-management-member-type-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/MemberType/LayoutView"
    ),
  redirect: { name: "EdicAdminFacilityMemberTypeSearch" },
  children: [
    {
      path: "", // /admin/member-type
      name: "EdicAdminFacilityMemberTypeSearch",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-member-type-searching-view" */ "@/views/EdicAdmin/Facilities/Manage/MemberType/Search.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "create", // /admin/member-type/create
      name: "EdicAdminFacilityMemberTypeCreate",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-member-type-create-view" */ "@/views/EdicAdmin/Facilities/Manage/MemberType/Create.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
    {
      path: "edit/:id", //  /admin/member-type/edit/1
      name: "EdicAdminFacilityMemberTypeEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-facility-management-member-type-edit-view" */ "@/views/EdicAdmin/Facilities/Manage/MemberType/Edit.vue"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
