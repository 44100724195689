/* eslint-disable */
import { 
  inject,
  getCurrentInstance
} from 'vue'

import { eventName } from './main';

export default function useScreenPermission(display) {
  if (typeof display !== 'string') {
    throw new Error(`'display' must be a string`);
  }
  if (!['create', 'search', 'edit'].includes(display.toLowerCase())) {
    throw new Error(`'display' parameter must be 'create' or 'search' or 'edit'`);
  }

  const isUsingPermission = inject('isUsingPermission', false);

  const manageCanCreateEditRemove = inject('manageCanCreateEditRemove', null);

  const instanceService = getCurrentInstance();

  const execute = () => {
    switch (display) {
      case 'create':
        if (isUsingPermission.value) {
          if (!manageCanCreateEditRemove.value) {
            instanceService.proxy.$eventBus.$emit(eventName.management_not_allow_to_access)
          }
        }  
        break;
    
      default:
        break;
    }
  }


  // execute in created() lifecircle
  execute();
}