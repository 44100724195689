<template>
  <div class="base__blank__page">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ClientJbbfBaseBlankPage",
};
</script>

<style lang="scss" scoped>
.base__blank__page {
  min-height: 100vh;
  display: flex;
}
</style>
