import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

import predicates from "./predicates";

import createMutationsSharer from "vuex-shared-mutations";

Vue.use(Vuex);

const state = {
  nameApp: "Renew Edic",
  searchingInstance: {},
  // account info when login
  accountLogin: {
    isAuth: false,
    info: {},
  },
  validation: {},
  pageNotFoundClientJbbf: false,
};
const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  plugins: [
    createMutationsSharer({
      predicate: (mutation) => {
        const predicate = predicates;

        return predicate.indexOf(mutation.type) >= 0;
      },
    }),
  ],
  modules,
});
export default store;

export const useStore = () => store;
