import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "settings",
  name: "SettingManage",
  component: () =>
    import(
      /* webpackChunkName: "setting-manage-layout-view" */ "@/views/Manage/Settings/LayoutView.vue"
    ),
  redirect: { name: "SettingMain" },
  children: [
    {
      path: "", // /manage/settings
      name: "SettingMain",
      component: () =>
        import(
          /* webpackChunkName: "setting-manage-view" */ "@/views/Manage/Settings/Setting.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "import-export-csv-account-cms-components", // admin/settings/import-export-csv-account-cms-components
      name: "SettingImportExportCsvAccountCmsComponent",
      component: () =>
        import(
          /* webpackChunkName: "setting-manage-import-export-csv-account-cms-component-view" */ "@/views/Manage/Settings/ImportExportCsvAccountCmsComponent.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "themes", // admin/settings/themes
      name: "SettingTheme",
      component: () =>
        import(
          /* webpackChunkName: "setting-manage-theme-setting-view" */ "@/views/Manage/Settings/ThemeSettings.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "languages", // admin/settings/languages
      name: "SettingLanguage",
      component: () =>
        import(
          /* webpackChunkName: "setting-language-view" */ "@/views/Manage/Settings/SettingLanguage.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
