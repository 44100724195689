import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  config: null,
  facility_domain: {
    isValidDomain: false,
    info: null,
  },
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
