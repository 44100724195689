import { auth as authAdmin } from "@/middlewares/admin";

export default {
  path: "storages",
  name: "StorageManage",
  component: () =>
    import(
      /* webpackChunkName: "edic-admin-storage-manage-layout-view" */ "@/views/EdicAdmin/Facilities/Manage/StorageManage/LayoutView"
    ),
  redirect: { name: "StorageManageCreateEdit" },
  children: [
    {
      path: "",
      name: "StorageManageCreateEdit",
      component: () =>
        import(
          /* webpackChunkName: "edic-admin-storage-manage-create-edit" */ "@/views/EdicAdmin/Facilities/Manage/StorageManage/CreateEdit"
        ),
      meta: {
        middleware: [authAdmin],
      },
    },
  ],
};
