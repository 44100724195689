import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "invoices",
  name: "InvoiceManage",
  component: () =>
    import(
      /* webpackChunkName: "invoice-manage-layout-view" */ "@/views/Manage/Invoice/LayoutView.vue"
    ),
  redirect: { name: "InvoiceSearch" },
  children: [
    {
      path: "",
      name: "InvoiceSearch",
      component: () =>
        import(
          /* webpackChunkName: "invoice-manage-searching-view" */ "@/views/Manage/Invoice/FilterSearching.vue"
        ),
      beforeEnter: (to, from, next) => {
        // ...
        const tab_invoice_filter = sessionStorage.getItem("tab_invoice_filter");
        if (
          tab_invoice_filter != null &&
          (from.name == "InvoiceCreate" || from.name == "InvoiceEdit")
        ) {
          to.meta.tab_invoice_filter = tab_invoice_filter;
        } else {
          to.meta.tab_invoice_filter = "";
        }
        next();
      },
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/invoice/create
      name: "InvoiceCreate",
      component: () =>
        import(
          /* webpackChunkName: "invoice-manage-create-view" */ "@/views/Manage/Invoice/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/invoice/edit/1
      name: "InvoiceEdit",
      component: () =>
        import(
          /* webpackChunkName: "invoice-manage-edit-view" */ "@/views/Manage/Invoice/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
