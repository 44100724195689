export const ACT_FORMAT_SEARCH_FORM = "ACT_FORMAT_SEARCH_FORM";
export const ACT_EXPORT_CSV_MANAGE_MEMBER = "ACT_EXPORT_CSV_MANAGE_MEMBER";
export const ACT_IMPORT_CSV_MANAGE_MEMBER = "ACT_IMPORT_CSV_MANAGE_MEMBER";
export const ACT_FORCE_FRESH_IMPORT_CSV = "ACT_FORCE_FRESH_IMPORT_CSV";

// mutations
export const SET_CMS_SEARCH_INPUT = "SET_SEARCH_FORM";
export const SET_SEARCH_FIELDS = "SET_SEARCH_FIELDS";
export const SET_SEARCH_FORM = "SET_SEARCH_FORM";
export const SET_RESULT_FIELDS = "SET_RESULT_FIELDS";
export const SET_ATTR_FORM_SEARCH = "SET_ATTR_FORM_SEARCH";
export const SET_BASIC_INFO = "SET_BASIC_INFO";
export const SET_EXTEND_INFO = "SET_EXTEND_INFO";
export const SET_DETAIL_EMPTY_INFO = "SET_DETAIL_EMPTY_INFO";
export const SET_PROCESS_EXPORT_CSV_FILE = "SET_PROCESS_EXPORT_CSV_FILE";
export const SET_PROCESS_IMPORT_CSV_FILE = "SET_PROCESS_IMPORT_CSV_FILE";
export const SET_REFRESH_IMPORT_CSV_FILE = "SET_REFRESH_IMPORT_CSV_FILE";
export const SET_REFRESH_MEMBER_INFO_DATA = "SET_REFRESH_MEMBER_INFO_DATA";

// getters
export const GET_SEARCH_FORM = "GET_SEARCH_FORM";
export const GET_SEARCH_FIELDS = "GET_SEARCH_FIELDS";
export const GET_RESULTS_FIELDS = "GET_RESULTS_FIELDS";
export const GET_BASIC_INFO_FORM = "GET_BASIC_INFO_FORM";
export const GET_EXTEND_INFO_FORM = "GET_EXTEND_INFO_FORM";
export const GET_PROCESS_EXPORT_CSV_FILE = "GET_PROCESS_EXPORT_CSV_FILE";
export const GET_PROCESS_IMPORT_CSV_FILE = "GET_PROCESS_IMPORT_CSV_FILE";
export const GET_IMPORT_CSV_CONTENT = "GET_IMPORT_CSV_CONTENT";
