<template>
  <div class="manage__login__wrapper">
    <b-container fluid="sm" class="d-flex w-100 justify-content-center">
      <b-row class="w-100 justify-content-center">
        <div class="m-0 p-0 col-sx-12 col-sm-12 col-xl-9">
          <b-row class="manage__login__wrapper-login__content w-100">
            <b-col xl="6" md="6" sm="12" class="m-0 p-0">
              <div
                class="manage__login__wrapper-login__content-login__avatar have__logo__avatar"
              >
                <img
                  class="avatar__facility"
                  src="/img/Logo.svg"
                  alt="facility-image"
                />
              </div>
            </b-col>
            <div class="col-md-12 col-sm-12 m-0 p-0 col-xl-6">
              <div class="manage__login__wrapper-login__content-login__form">
                <form v-if="!isAuth">
                  <!-- email -->
                  <div class="d-block">
                    <base-input
                      v-model.trim="form.login_account"
                      :label="$t('login.label_login_email')"
                      :fail-input="failEmail"
                      bold
                      required
                      :placeholder="$t('panel.label_place_holder')"
                    />
                    <span
                      v-if="failEmail"
                      class="is-invalid-feedback-message"
                      >{{ $validation.login_account }}</span
                    >
                  </div>

                  <!-- password -->
                  <div class="d-block mt-2">
                    <base-input
                      v-model="form.password"
                      type="password"
                      :label="$t('login.label_login_password')"
                      :fail-input="failPassword"
                      bold
                      required
                      :placeholder="$t('panel.label_place_holder')"
                    />
                    <span
                      v-if="failPassword"
                      class="is-invalid-feedback-message"
                      >{{ $validation.password }}</span
                    >
                  </div>

                  <!-- login button -->
                  <div class="d-block mt-3">
                    <button class="btn__submit" @click="submitForm">
                      {{ $t("login.label_btn_login") }}
                    </button>
                  </div>
                  <div
                    v-if="msgError"
                    class="d-block mt-2 text-danger text-center font-weight-bold"
                  >
                    <span>{{ msgError }}</span>
                  </div>
                </form>

                <!-- loading -->
                <transition name="fade">
                  <div v-if="loading" class="start__loading">
                    <div class="avatar__loading"></div>
                  </div>
                </transition>
              </div>
            </div>
          </b-row>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  ACT_LOGIN_ACCOUNT,
  GET_ACCOUNT_IS_AUTH,
} from "@/store/modules/AuthAdmin/types";
import { isEmpty } from "lodash";

export default {
  name: "LoginAdminView",
  data() {
    return {
      form: {
        login_account: "", // exp: abc3@gmail.com
        password: "", // exp: 12345678
      },
      loading: false,
      msgError: "",
    };
  },
  computed: {
    ...mapGetters("AuthAdmin", {
      isAuth: GET_ACCOUNT_IS_AUTH,
    }),
    failEmail() {
      return this.$validation["login_account"] ? true : false;
    },
    failPassword() {
      return this.$validation["password"] ? true : false;
    },
  },
  watch: {
    isAuth() {
      if (this.isAuth) {
        let { redirect } = this.$route.query;
        if (redirect) {
          this.$router.push({ path: redirect });
        } else {
          this.$router.push({ name: "EdicFacilitiesSearch" });
        }
      }
    },
  },
  created() {},
  methods: {
    ...mapActions("AuthAdmin", {
      login: ACT_LOGIN_ACCOUNT,
    }),

    async submitForm(e) {
      e.preventDefault();
      this.msgError = "";
      this.$setValidation({});
      if (this.checkForm()) {
        this.loading = true;
        // call api
        try {
          await this.login({ ...this.form });
          this.loading = false;
        } catch (err) {
          this.loading = false;
          if (err.unauthorized) {
            this.msgError = err.data;
          }
          if (err.failValidation) {
            this.$setValidation(err.data);
          }
        }
      }
    },
    checkForm() {
      // Standard rfc 5322
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.form.login_account && !pattern.test(this.form.login_account)) {
        this.$validation.login_account = this.$t("panel.is_invalid_email");
      } else if (this.form.login_account && this.form.password) {
        return true;
      }
      if (isEmpty(this.form.login_account)) {
        this.$validation.login_account = this.$t("validation.required", {
          input: this.$t("login.label_login_email"),
        });
      }
      if (isEmpty(this.form.password)) {
        this.$validation.password = this.$t("validation.required", {
          input: this.$t("login.label_login_password"),
        });
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.manage__login__wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  &-login__content {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 0.25rem;

    &-login__form {
      padding: 4rem 3rem;
      position: relative;
      height: 100%;
      display: flex;

      div.form-group {
        margin-bottom: 0px;
      }

      form {
        width: 100%;
        margin: auto 0;

        .btn__submit {
          background-color: #dbb05d;
          color: #ffffff;
          border: none;
          outline: none;
          width: 100%;
          display: inline-block;
          font-weight: 400;
          text-align: center;
          vertical-align: middle;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
          border: 1px solid transparent;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          border-radius: 0.25rem;

          &:active {
            background-color: #c8a052;
          }
        }
      }

      @media only screen and (max-width: 900px) {
        padding: 1.5rem;
      }

      .start__loading {
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        align-items: center;
        justify-content: center;
        opacity: 0.85;
        backdrop-filter: blur(2px);

        .avatar__loading {
          width: 50px;
          aspect-ratio: 1;
          border-radius: 50%;
          border: 8px solid;
          border-color: #000 #0000;
          animation: avatar_loading 1s infinite;
        }
      }
    }

    &-login__avatar {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      &.have__logo__avatar {
        padding: 50px;

        @media screen and (max-width: 760px) {
          padding: 10px;
        }
      }

      &.is-loading {
        background-color: #f8f9fa;
      }
      .avatar__facility {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 767.9px) {
        padding: 0;

        .avatar__facility {
          object-fit: cover;
          width: 60%;
          height: auto;
        }
      }
    }

    @media screen and (max-width: 767.9px) {
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
  }
}
@keyframes avatar_loading {
  to {
    transform: rotate(0.5turn);
  }
}
</style>
