import {
  SET_CLIENT_PERMISSION_VIEW,
  REFRESH_CLIENT_PERMISSION_VIEW,
  SET_CLIENT_VIEW_FLAG,
} from "./types";
import { initialize } from "./index";

export default {
  [SET_CLIENT_PERMISSION_VIEW]: (state, payload) => {
    let { id, menu_id, view_flag } = payload;
    state.client_permission_view.id = id;
    state.client_permission_view.menu_id = menu_id;
    state.client_permission_view.view_flag = view_flag;
  },
  [REFRESH_CLIENT_PERMISSION_VIEW]: (state) => {
    state.client_permission_view = initialize();
  },
  [SET_CLIENT_VIEW_FLAG]: (state, flag) => {
    if (typeof flag !== "boolean") {
      throw new Error(`'flag' shoudl be Boolean type!`);
    }
    state.client_permission_view.view_flag = flag;
  },
};
