import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export function BasicClientPermissionScreen() {
  this.view_flag = 1;
  this.menu_id = "";
  this.id = "";
}

export function initialize() {
  return Object.assign({}, new BasicClientPermissionScreen());
}

const state = () => ({
  client_permission_view: initialize(),
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
