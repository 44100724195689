import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = () => ({
  show_menu_id: 0,
  current_module: "",
});

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
