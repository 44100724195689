import {
  SET_CLIENT_USER_NAME,
  SET_CLIENT_TOKEN,
  SET_CLIENT_IS_AUTH,
  SET_CLIENT_REFRESH_AUTH,
  SET_CLIENT_ACCOUNT_INFO,
  SET_CLIENT_EXPIRED_AT,
  SET_CLIENT_PERMISSION_MENU,
  SET_CLIENT_LOGIN_ORGAIZATION,
  SET_CLIENT_MEMBER_TYPES,
  SET_CLIENT_MENU_HEADER,
  SET_CLIENT_MEMBER_CARD_DESIGNS,
  SET_CLIENT_MEMBER_TYPE_RENEWABLE_FLAG,
} from "./types";
import { ApplicationClientAccountTemp } from "@/plugins/setting";
import { isEmpty } from "lodash";

export default {
  [SET_CLIENT_USER_NAME]() {
    console.log("call mutations from user module");
  },
  [SET_CLIENT_TOKEN](state, token) {
    state.clientToken = token;
  },
  [SET_CLIENT_IS_AUTH](state, status) {
    state.clientAccountLogin.isAuth = status; // success or fail
  },
  [SET_CLIENT_REFRESH_AUTH](state) {
    (state.clientAccountLogin = {
      isAuth: "fail",
      info: {},
    }),
      (state.clientToken = null);
    state.clientExpiredAt = null;
    state.clientPermissionMenu = [];
    state.process_get_info_is_done = false;
  },
  [SET_CLIENT_ACCOUNT_INFO](state, payload) {
    state.clientAccountLogin.info = payload;
  },
  [SET_CLIENT_EXPIRED_AT](state, miliseconds) {
    state.clientExpiredAt = miliseconds;
  },
  [SET_CLIENT_PERMISSION_MENU](state, permisionMenu) {
    state.clientPermissionMenu = permisionMenu;
  },
  updateClientAccountTemp: (state, payload) => {
    state.accountTemp = payload;
    localStorage.setItem(ApplicationClientAccountTemp, payload);
  },
  [SET_CLIENT_LOGIN_ORGAIZATION]: (state, payload) => {
    state.login_organization = payload;
  },
  [SET_CLIENT_MEMBER_TYPES]: (state, payload) => {
    state.member_types = payload;
  },
  [SET_CLIENT_MENU_HEADER]: (state, payload) => {
    state.client_menu_header = payload;
  },
  [SET_CLIENT_MEMBER_CARD_DESIGNS]: (state, payload) => {
    state.member_card_designs = payload;
  },
  [SET_CLIENT_MEMBER_TYPE_RENEWABLE_FLAG]: (state, flag) => {
    if (!isEmpty(state.clientAccountLogin.info)) {
      if (
        Object.prototype.hasOwnProperty.call(
          state.clientAccountLogin.info,
          "member_type_renewable_flag"
        )
      ) {
        state.clientAccountLogin.info.member_type_renewable_flag = flag;
      }
    }
  },
};
