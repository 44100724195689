import authManage from "@/middlewares/manage/auth";
import domain from "@/middlewares/manage/domain";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "account-settings",
  name: "AccountSetting",
  component: () =>
    import(
      /*webpackChunkName:"account-setting-layout-view" */ "@/views/Manage/AccountSetting/LayoutView.vue"
    ),
  redirect: { name: "AccountSettingCreate" },
  children: [
    {
      path: "",
      name: "AccountSettingCreate",
      component: () =>
        import(
          /* webpackChunkName: "account-setting-create-view" */ "@/views/Manage/AccountSetting/AccountSetting.vue"
        ),
      props: { default: true },
      meta: {
        middleware: [domain, authManage, selectOrganization],
      },
    },
  ],
};
