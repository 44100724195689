<template>
  <span class="cover-icon" :class="customClass">
    <img v-if="useImgIcon" class="icon-image" :src="icon.image" />
    <icon
      v-else-if="useEmojiIcon"
      :icon="realIconName"
      :height="defaultHeight"
      :color="color"
    />
  </span>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { iconType } from "@/constants/commons";

export default {
  name: "JbbfIconWrapper",
  components: { Icon },
  props: {
    icon: {
      type: Object,
      default: () => {},
    },
    defaultHeight: {
      type: Number,
      default: 24,
    },
    customClass: {
      type: String,
      default: "",
    },
    defaultColor: {
      type: String,
      default: "black",
    },
  },
  computed: {
    realIconName() {
      return `${this.icon.prefix}:${this.icon.name}`;
    },
    useEmojiIcon() {
      return this.icon?.type == iconType.emoji.value;
    },
    useImgIcon() {
      return this.icon?.type == iconType.image.value;
    },
    color() {
      return this.icon?.color || this.defaultColor;
    },
  },
};
</script>

<style scoped lang="scss">
.cover-icon {
  svg {
    margin-top: -5px;
  }
  .icon-image {
    margin-top: -8px;
    width: 24px;
    max-height: 24px;
  }
}
</style>
