var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-block"},[_c('div',{staticClass:"select__file__button__wrapper d-flex g-5 w-100",class:{
      'border-validation-error ': _vm.isFailValidation,
      not__error: !_vm.isFailValidation,
    },on:{"click":_vm.selectFileHandler}},[_c('span',{class:'select__file__button__wrapper-select__button text-white ' +
        _vm.btnBgColor},[_vm._v(_vm._s(_vm.$t("panel.label_browse_file")))]),_c('span',{staticClass:"select__file__button__wrapper-file__name text-dark"},[_vm._v(_vm._s(_vm.displayFileName))])]),_c('b-form-file',{ref:"selectFileRef",staticClass:"d-none",attrs:{"value":_vm.value,"accept":_vm.accept,"disabled":_vm.disabled,"state":!_vm.isFailValidation,"browse-text":_vm.$t('panel.label_browse_file'),"placeholder":_vm.$t('panel.label_please_choose_one_file')},on:{"change":_vm.pickFile}}),(_vm.selectImage && _vm.displayImage)?[_c('div',{staticClass:"d-flex g-5 mt-2"},[_c('div',{staticClass:"review__img__wrapper text-danger"},[_c('b-img',{staticClass:"h-100 w-100",attrs:{"thumbnail":"","fluid":"","src":_vm.displayImage,"alt":"Image 1"}})],1),((_vm.image || _vm.imageUrl) && !_vm.disabled)?_c('div',{staticClass:"text-danger cursor-pointer",on:{"click":_vm.removeFile}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])]:_vm._e(),(_vm.isFailValidation)?_c('div',{staticClass:"is-invalid-feedback-message"},[_vm._v(" "+_vm._s(_vm.textValidation)+" ")]):_vm._e(),_c('span',{directives:[{name:"error-input-text",rawName:"v-error-input-text",value:({
      validation: _vm.$validation,
      keyName: _vm.validateFileSizeName,
    }),expression:"{\n      validation: $validation,\n      keyName: validateFileSizeName,\n    }"}]}),_c('span',{directives:[{name:"error-input-text",rawName:"v-error-input-text",value:({
      validation: _vm.$validation,
      keyName: _vm.validateFileExtension,
    }),expression:"{\n      validation: $validation,\n      keyName: validateFileExtension,\n    }"}]}),_c('div',{staticClass:"d-block hint__text"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.hintText)}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }