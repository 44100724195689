<template>
  <client-jbbf-base-card use-simple-card card-class="h-100">
    <div class="d-block">
      <div class="text-left mt-4">
        <b-button
          v-for="(button, idx) in buttons"
          :key="idx"
          class="mr-2 mt-2"
          variant="outline-secondary"
        >
          {{ button.text }}</b-button
        >
      </div>
      <b-input-group class="mt-5" size="lg">
        <b-form-input
          :placeholder="$t('common.search_keyword')"
          class="search-keyword"
        ></b-form-input>
        <b-input-group-append>
          <b-button class="btn btn-secondary" size="sm" type="button">
            <i aria-hidden="true" class="fas fa-search"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </client-jbbf-base-card>
</template>

<script>
export default {
  name: "ClientJbbfCategoryButtons",
  data() {
    return {
      buttons: [
        {
          text: "JBPコラム",
        },
        {
          text: "JBP定例会",
        },
        {
          text: "イベント",
        },
        {
          text: "動画",
        },
        {
          text: "音声",
        },
        {
          text: "コラム",
        },
        {
          text: "研修",
        },
        {
          text: "クレド",
        },
        {
          text: "社内向け情報共有",
        },
        {
          text: "社長メッセージ",
        },
        {
          text: "行動指針",
        },
        {
          text: "社内研修",
        },
        {
          text: "PDFファイル",
        },
        {
          text: "Edic操作マニュアル",
        },
        {
          text: "北海道ツアー",
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.search-keyword {
  background-color: #f2f2f2;
}
</style>
