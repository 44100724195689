/* eslint-disable */
import { bffServiceInstance } from "@/plugins/api_service/services";
import { formatFormRequest } from '@/helpers/common';
import { platFormType } from '@/constants/commons';
import { state } from "@/socket";
const prefix = "/management/account-cms";


import _ from 'lodash';

const convertNullToEmpty = (value) => value != null ? value : "";

const buildBasicInfoFormData = ({ basic_info }) => {
  let form = new FormData();

  if (!_.isEmpty(basic_info)) {
    for (let dataKey in basic_info) {
      switch (dataKey) {
        case 'member_type':
          if (!_.isEmpty(basic_info[dataKey])) {
            basic_info[dataKey].forEach((item, idx) => {
              form.append(`basic_info[${dataKey}][${idx}]`, convertNullToEmpty(item))
            });
          }
          break;
        
        case 'document':
          basic_info[dataKey].forEach((item, idx) => {
            if (item) { 
              form.append(`basic_info[${dataKey}][${idx}][file]`, convertNullToEmpty(item.file));
            }
          })
          break;
        case 'document_url':
          if (basic_info[dataKey] && Array.isArray(basic_info[dataKey])) {
            basic_info[dataKey].forEach((item, idx) => {
              for (let prop in item) {
                form.append(`basic_info[${dataKey}][${idx}][${prop}]`, convertNullToEmpty(item[prop]));
              }
            });
          }
          break;
        default:
          form.append(`basic_info[${dataKey}]`, convertNullToEmpty(basic_info[dataKey]));
          break;
      }
    }
  }
  
  return form;
}

const buildExtendInfoformData = ({ extend_info }) => {
  let form = new FormData();

  if (!_.isEmpty(extend_info)) {
    for (let keyData in extend_info) {

      if (Array.isArray(extend_info[keyData].value)) {
        if (extend_info[keyData].value.length) {
          extend_info[keyData].value.forEach((data, idx) => {
            form.append(`extend_info[${keyData}][value][${idx}]`, convertNullToEmpty(data));
          })
        } else {
          form.append(`extend_info[${keyData}][value]`, '');
        }
        
      } else if (typeof extend_info[keyData].value == 'object') {
        for (let childKey in extend_info[keyData].value) {
          form.append(`extend_info[${keyData}][value][${childKey}]`, convertNullToEmpty(extend_info[keyData].value[childKey]));
        }
      } else {
        form.append(`extend_info[${keyData}][value]`, convertNullToEmpty(extend_info[keyData].value));
      }
    }
  }

  return form;
}

export default {
  search: (queryForm, facility_code, login_organization) => bffServiceInstance.get('/management/member-accounts', {...queryForm, facility_code: facility_code, login_organization: login_organization }),
  filterSearching: ({ page, default_search, advanced_search }) =>
    bffServiceInstance.post(
      "/management/member-accounts/search",
      {},
      { page, default_search, advanced_search }
    ),
  store: (memberForm) => {
    // // combine 2 form_data
    // for (var pair of extend_info_form.entries()) {
    //   basic_info_form.append(pair[0], pair[1]);
    // }

    // basic_info_form.append('facility_code', memberForm.facility_code);

    // return bffServiceInstance.post('/management/member-accounts',{} , basic_info_form, { 'Accept': '*/*', 'Content-Type': 'multipart/form-data' },);
    return bffServiceInstance.post('/management/member-accounts',{} , memberForm);

  },

  show: (id) => bffServiceInstance.get(`/management/member-accounts/${id}`),
  detail: (id) => bffServiceInstance.get(`/management/member-accounts/get-basic-info-for-detail/${id}`),

  update: (id, memberForm) => {
    let basic_info_form = buildBasicInfoFormData(memberForm);
    let extend_info_form = buildExtendInfoformData(memberForm);
    
    // combine 2 form_data
    for (var pair of extend_info_form.entries()) {
      basic_info_form.append(pair[0], pair[1]);
    }

    basic_info_form.append('facility_code', memberForm.facility_code);
    
    return bffServiceInstance.post(`/management/member-accounts/${id}`, {}, basic_info_form, { 'Accept': '*/*', 'Content-Type': 'multipart/form-data' })
  },
  remove: (id) => bffServiceInstance.delete(`/management/member-accounts/${id}`),
  getMemberCmsSearch: (facilityCode) => bffServiceInstance.get(`/management/member-cms/get-cms-search`, {facility_code: facilityCode}),
  getMemberType: (facilityCode, platform = '', screen = '', class_type = "") => {
    return bffServiceInstance.get(`/management/member-types/get-select-list`, {
      facility_code: facilityCode,
      platform: platform,
      screen: screen,
      class_type: class_type
    })
  },
  exportCsvManageMember: (socketEventName, searchForm) => bffServiceInstance.post('/management/member-csv/export', {}, {
    ...searchForm,
    socket_event_name: socketEventName,
    socket_id: state.socketId,
  }),
  importCsvManageMember: ({file, facility_code, login_organization, encode_type, channel_name, send_invoice_flag, auto_payment_flag}) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('facility_code', facility_code);
    formData.append('login_organization', login_organization);
    formData.append('encode_type', encode_type);
    formData.append('socket_event_name', channel_name);
    formData.append("socket_id", state.socketId);
    formData.append("send_invoice_flag", send_invoice_flag);
    formData.append("auto_payment_flag", auto_payment_flag);

    return bffServiceInstance.post(`/management/member-csv/import`, {}, formData,  { 'Accept': '*/*', 'Content-Type': 'multipart/form-data' });
  },
  // importCsvManageMember: () => new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       msg: "ok upload file"
  //     })
  //   })
  // }),
  getMemberAccountList: ({
    login_organization,
    is_main_member,
    class_type
  }) => bffServiceInstance.get('/management/accounts/get-select-list-account-by-class-type', {
    login_organization,
    is_main_member,
    class_type
  }),
  getListMemberTypeForStore: ({ class_type }) => bffServiceInstance.get('/management/member-types/get-select-list-for-store-member', { class_type }),
  getDetailModules: (id) => bffServiceInstance.get(`/management/member-accounts/detail/${id}`),
  getCmsDetail: (facilityCode) => {
    return bffServiceInstance.get(
      `/management/member-cms/get-cms-detail`,
      {
        facility_code: facilityCode
      }
    );
  },
}
