import storageSignurl from "@/services/manage/storage-signurl";
import { downloadStorageGoogleFile } from "@/helpers/common.js";

export default {
  bind: function (el, binding) {
    const { url, review_storage_type, file_name = "" } = binding.value;
    const { download = false } = binding.modifiers;
    let service;
    switch (review_storage_type) {
      case "manage":
        service = storageSignurl.getManageTemporaryUrl;
        break;
      case "client":
        service = storageSignurl.getClientTemporaryUrl;
        break;
      default:
        break;
    }

    // handler
    el.handler = async function (event) {
      event.preventDefault();
      const source = event.target;

      let responseNewSignURL = await service({ file_url: url });

      // if download is true or defined
      if (download) {
        await downloadStorageGoogleFile(responseNewSignURL, file_name);
        return;
      } else {
        const element = document.createElement("a");
        element.setAttribute("href", responseNewSignURL);
        element.setAttribute("target", source.target);
        element.style.display = "none";

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    };

    // bind event
    el.addEventListener("click", el.handler);
  },
};
