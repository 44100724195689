import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "contents/:url/:content_cms_code",
  name: "ContentManage",
  component: () =>
    import(
      /* webpackChunkName: "content-manage-layout-view" */ "@/views/Manage/Content/LayoutView.vue"
    ),
  redirect: { name: "ContentSearch" },
  children: [
    {
      path: "", // /manage/contents
      name: "ContentSearch",
      component: () =>
        import(
          /* webpackChunkName: "content-manage-searching-view" */ "@/views/Manage/Content/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/contents/create
      name: "ContentCreate",
      component: () =>
        import(
          /* webpackChunkName: "content-manage-create-view" */ "@/views/Manage/Content/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/contents/edit/1
      name: "ContentEdit",
      component: () =>
        import(
          /* webpackChunkName: "content-manage-edit-view" */ "@/views/Manage/Content/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
