import { SET_SHOW_MENU_ID, SET_CURRENT_MODULE } from "./types";

export default {
  [SET_SHOW_MENU_ID](state, payload) {
    state.show_menu_id = payload;
  },
  [SET_CURRENT_MODULE](state, payload) {
    state.current_module = payload;
  },
};
