// load all file base components
const componentFiles = require.context(
  "@/components/Bases",
  true,
  /[A-Za-z]+\.vue$/
);

const GlobalComponents = {
  install(Vue) {
    componentFiles.keys().forEach((elementFile) => {
      // const moduleName = elementFile.replace(/(\.\/|\.vue)/g, '');
      const moduleComponent = componentFiles(elementFile).default;
      Vue.component(moduleComponent.name, moduleComponent);
    });
  },
};

export default GlobalComponents;
