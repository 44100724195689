import { GET_FACILITY_MENU_ID, IS_CLIENT_CAN_BE_VIEWED } from "./types";

export default {
  // GET facility_menu_id
  [GET_FACILITY_MENU_ID]: (state) => state.client_permission_view.id,

  //  IS_MANAGEMENT_CAN_BE_VIEWED
  [IS_CLIENT_CAN_BE_VIEWED]: (state) =>
    Boolean(state.client_permission_view.view_flag),
};
