// mutations
export const SET_ACTIVE_PARENT_ID = "SET_ACTIVE_PARENT_ID";
export const SET_OPENING_PARENT_ID = "SET_OPENING_PARENT_ID";
export const SET_PREVIOUS_OPENING_ITEM_HAVE_CHILDREN =
  "SET_PREVIOUS_OPENING_ITEM_HAVE_CHILDREN";

// getters
export const GET_ACTIVE_PARENT_ID = "GET_ACTIVE_PARENT_ID";
export const GET_OPENING_PARENT_ID = "GET_OPENING_PARENT_ID";
export const GET_PREVIOUS_OPENING_ITEM_HAVE_CHILDREN =
  "GET_PREVIOUS_OPENING_ITEM_HAVE_CHILDREN";
