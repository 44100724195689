import {
  regexYYYYMMDD,
  regexYYYYMMDDHHmm,
  regexYYYYMM,
} from "./auto-format-date";

function ValidateDateFormat(el, binding) {
  let {
    date: dateString,
    mode = "date",
    displayName = "Input",
  } = binding.value;
  //Check whether valid dd/MM/yyyy Date Format.

  switch (mode) {
    case "date":
      if (
        regexYYYYMMDD.test(dateString) ||
        !dateString ||
        dateString.length == 0
      ) {
        ShowHideError(el);
      } else {
        ShowHideError(el, true, displayName, "Y/m/d");
      }
      break;
    case "dateTime":
      if (
        regexYYYYMMDDHHmm.test(dateString) ||
        !dateString ||
        dateString.length == 0
      ) {
        ShowHideError(el);
      } else {
        ShowHideError(el, true, displayName, "Y/m/d H:i");
      }
      break;
    case "monthYear":
      if (regexYYYYMM.test(dateString) || !dateString || dateString == 0) {
        ShowHideError(el);
      } else {
        ShowHideError(el, true, displayName, "Y/m");
      }
      break;
    default:
      break;
  }
}

function ShowHideError(
  el,
  display = false,
  displayName = "Input",
  format = "Y/m/d"
) {
  if (display) {
    el.classList.remove("d-none");
    el.classList.add("is-invalid-feedback-message", "d-block");
    el.innerText = `${displayName}は${format}形式で指定してください。`;
  } else {
    el.classList.remove("is-invalid-feedback-message", "d-block");
    el.classList.add("d-none");
    el.innerText = "";
  }
}

export default {
  bind: function (el, binding) {
    ValidateDateFormat(el, binding);
  },

  update: function (el, binding) {
    ValidateDateFormat(el, binding);
  },
};
