/* eslint-disable */

const checkValue = (str, max) => {
  if (str.charAt(0) !== '0' || str == '00') {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
  };
  return str;
}

export default {
  bind: function (el, binding) {

    el.addEventListener('input', function (evt) {
      this.type = 'text';
      var input = this.value;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 5);
      
      var values = input.split('/').map(function(v) {
        return v.replace(/\D/g, '')
      });
     
      // values[0]: year
      // values[1]: month
      // values[2]: day
      if (values[1]) values[1] = checkValue(values[1], 12);
      if (values[2]) values[2] = checkValue(values[2], 31);
     
      var output = values.map(function(v, i) {
        if (v.length === 4 && i === 0) return v + '/';
        if (v.length == 2 && i == 1) return v + '/';
        return v;
      });
      this.value = output.join('').substr(0, 14);
    });

    // el.addEventListener('blur', function (e) {
    //   this.type = 'text';
    //   var input = this.value;
    //   var values = input.split('/').map(function(v, i) {
    //     return v.replace(/\D/g, '')
    //   });
    //   var output = '';
      
    //   if (values.length == 3) {
    //     var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
    //     var month = parseInt(values[0]) - 1;
    //     var day = parseInt(values[1]);
    //     var d = new Date(year, month, day);
    //     if (!isNaN(d)) {
    //       document.getElementById('result').innerText = d.toString();
    //       var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
    //       output = dates.map(function(v) {
    //         v = v.toString();
    //         return v.length == 1 ? '0' + v : v;
    //       }).join(' / ');
    //     };
    //   };
    //   this.value = output;
    // })
  }
}