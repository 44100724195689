// actions
export const ACT_LOGIN_ACCOUNT = "ACT_LOGIN_ACCOUNT";
export const ACT_LOGOUT = "ACT_LOGOUT";
export const ACT_FORCE_FRESH_AUTH = "ACT_FORCE_FRESH_AUTH";

// mutations
export const SET_TOKEN = "SET_TOKEN";
export const SET_IS_AUTH = "SET_IS_AUTH";
export const SET_REFRESH_AUTH = "SET_REFRESH_AUTH";
export const SET_ACCOUNT_INFO = "SET_ACCOUNT_INFO";
export const SET_EXPIRED_AT = "SET_EXPIRED_AT";

// getters
export const GET_AUTH_TOKEN = "GET_AUTH_TOKEN";
export const GET_ACCOUNT_IS_AUTH = "GET_ACCOUNT_IS_AUTH";
export const GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO";
export const GET_ACCOUNT_FULLNAME = "GET_ACCOUNT_FULLNAME";
