import crypto from "crypto-js";
import { buildUrl } from "@/helpers/common.js";
import axios from "axios";

const secretString = "secret key";

const CancelToken = axios.CancelToken;

class CancelTokenInstance {
  constructor(url, query = {}, body = null, options = {}) {
    if (url) {
      let urlQuery = buildUrl(url, query);
      this.generateName = crypto.AES.encrypt(urlQuery, secretString).toString();
      this.url = url;
      this.query = query;
      this.body = body;
      this.options = options;
      this.time_registration = Math.floor(new Date());
      this.cancel = null;
      this.is_valid_instance = true;
    }
  }

  isValidInstance() {
    return this.is_valid_instance;
  }

  instanceNameUrl() {
    return this.generateName || null;
  }
  // do something more
  getCancelToken() {
    return new CancelToken((c) => {
      this.cancel = c;
    });
  }
  //
  destroyRequest() {
    this.cancel();
  }
}

export default CancelTokenInstance;
