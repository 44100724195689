import Vue from "vue";
import VueRouter from "vue-router";
import modulesRoute from "./modules";
import eventBus from "@/helpers/eventBus";
import middlewarePipeline from "@/middlewares/middlewarePipeline";
import { controllerCancel } from "@/main";
import CancelTokenInstance from "@/models/system/CancelTokenInstance";
import qs from "qs";

Vue.use(VueRouter);

const routes = [...modulesRoute];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "nav-item active",
  routes,
  scrollBehavior: (to) => {
    if (to.meta.disableScroll) {
      return {
        selector: null,
        behavior: "smooth",
        offset: null,
      };
    }
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        offset: { x: 0, y: 250 }, // hardcode
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    var result = qs.stringify(query);
    return result ? "?" + result : "";
  },
});

// handler route error
router.onError((error) => {
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
  console.log("System alert error: ", error);
});

// const validateFacilityUrl = (to, from ,next) => {
//   if (from.path === '/') {
//     return next({ name : 'ManageLogin' })
//   }
// }

// // navigation guards
router.beforeResolve((to, from, next) => {
  next();
});

router.beforeEach((to, from, next) => {
  // if user change another route but the request is still alive --> loop and destroy request
  for (var prop in controllerCancel) {
    if (Object.prototype.hasOwnProperty.call(controllerCancel, prop)) {
      if (
        controllerCancel[prop].constructor.name === CancelTokenInstance.name
      ) {
        controllerCancel[prop].destroyRequest();
        delete controllerCancel[prop];
      }
    }
  }
  if (
    to.name != from.name &&
    to.name != "MananagePageUnauthorized" &&
    !/^\/new-admin/.test(from.path)
  ) {
    eventBus.$emit("asyncComponentLoading", to);
  }

  if (to.meta.middleware) {
    const middleware = to.meta.middleware;
    const context = {
      to,
      from,
      next,
    };
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    });
  }

  // check domain for redirect url
  // if (to.path == "/" && window.location.host == process.env.VUE_APP_ADMIN_URL && to.name !== "Overview") {
  //   next({ name: "Overview" });
  // }
  return next();
});

router.afterEach(() => {
  setTimeout(() => {
    eventBus.$emit("asyncComponentLoaded");
  }, 500);
});

router.beforeResolve((to, from, next) => {
  // setTimeout(() => {
  //   eventBus.$emit('asyncComponentLoaded')
  // }, 500)
  next();
});

export default router;
