<template>
  <b-alert
    v-model="dismissCountDown"
    fade
    :variant="variantColor"
    @dismiss-count-down="handleDimiss"
  >
    {{ message }}
  </b-alert>
</template>

<script>
export default {
  name: "BaseAlertSystem",
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
    displayTime: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      dismissCountDown: 0,
    };
  },
  computed: {
    variantColor() {
      return this.error ? "danger" : "success";
    },
  },
  mounted() {
    this.dismissCountDown = this.displayTime; // 5 seconds
    this.scrollTopWindow();
  },
  methods: {
    handleDimiss(time) {
      if (!time) {
        this.$emit("hide-alert", false);
      }
    },
  },
};
</script>
