const regexYYYYMMDD =
  /([0-9]{2}\d\d)\/(0[1-9]|1[0-2])\/((0|1)[0-9]|2[0-9]|3[0-1])$/;
const regexDDMMYYYY =
  /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(([0-9]{1,2})\d\d))$/;
const regexYYYYMMDDHHmm =
  /([0-9]{2}\d\d)\/(0[1-9]|1[0-2])\/((0|1)[0-9]|2[0-9]|3[0-1]) ((0|1)[0-9]|2[0-4]):([0-5][0-9])$/;
const regexYYYYMM = /([0-9]{2}\d\d)\/(0[1-9]|1[0-2])$/;

// const regexMMDD = /(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])$/; => for case allow accept format m/d like 3/1
const regexMMDD = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

export {
  regexYYYYMMDD,
  regexDDMMYYYY,
  regexYYYYMMDDHHmm,
  regexYYYYMM,
  regexMMDD,
};

var isShift = false;
var seperator = "/";

function formatValidDate(input, keyCode, mode = "date") {
  let positionOfCharactor = input.selectionStart;
  let convertToArray = input.value.split("");

  if (keyCode == 16) {
    isShift = true;
  }
  // Allow only Numeric Keys.
  if (
    ((keyCode >= 48 && keyCode <= 57) ||
      keyCode == 8 ||
      keyCode <= 37 ||
      keyCode <= 39 ||
      (keyCode >= 96 && keyCode <= 105)) &&
    isShift == false
  ) {
    if (mode == "date") {
      if (
        (positionOfCharactor == 4 || positionOfCharactor == 7) &&
        convertToArray[positionOfCharactor + 1] == undefined &&
        keyCode != 8
      ) {
        input.value += seperator;
      }

      // if ((input.value.length == 4 || input.value.length == 7) && keyCode != 8) {

      //   input.value += seperator;
      // }
    }

    if (mode == "dateTime") {
      // for 1111/11/11
      if (
        (positionOfCharactor == 4 || positionOfCharactor == 7) &&
        convertToArray[positionOfCharactor + 1] == undefined &&
        keyCode != 8
      ) {
        input.value += seperator;
      }

      if (
        positionOfCharactor == 10 &&
        convertToArray[positionOfCharactor + 1] == undefined &&
        keyCode != 8
      ) {
        input.value += " ";
      }

      if (
        positionOfCharactor == 10 &&
        convertToArray[positionOfCharactor + 1] &&
        keyCode != 8
      ) {
        let sub = convertToArray.slice(
          positionOfCharactor,
          convertToArray.length
        );
        convertToArray[positionOfCharactor] = " ";
        convertToArray = convertToArray.slice(0, positionOfCharactor + 1);
        let combine = [].concat(convertToArray, sub);
        input.value = combine.join("");
        return;
      }

      // if (input.value.length == 10 && keyCode != 8) {
      //  input.value += ' ';
      // }

      if (
        positionOfCharactor == 13 &&
        convertToArray[positionOfCharactor + 1] == undefined &&
        keyCode != 8
      ) {
        input.value += ":";
      }
    }

    if (mode == "monthYear") {
      if (
        positionOfCharactor == 4 &&
        convertToArray[positionOfCharactor + 1] == undefined &&
        keyCode != 8
      ) {
        input.value += seperator;
      }
      // if (input.value.length == 4 && keyCode != 8) { // 2024/01/02 24
      //   input.value += seperator;
      // }
    }
    if (mode == "monthDate") {
      if (
        positionOfCharactor == 2 &&
        convertToArray[positionOfCharactor + 1] == undefined &&
        keyCode != 8
      ) {
        input.value += seperator;
      }
    }
    return true;
  } else {
    return false;
  }
}

export default {
  bind: function (el, binding) {
    let { mode = "date" } = binding.value;

    // maximum length for YYYY/MM/DD
    if (mode == "date") {
      el.setAttribute("maxlength", 10);
    } else if (mode == "dateTime") {
      el.setAttribute("maxlength", 16);
    } else if (mode == "monthYear") {
      el.setAttribute("maxlength", 7);
    } else if (mode == "monthDate") {
      el.setAttribute("maxlength", 5);
    }

    el.handlerInput = function (e) {
      return formatValidDate(this, e.keyCode, mode);
    };
    el.addEventListener("keyup", el.handlerInput);
  },
};
