// actions
export const ACT_LOGIN_MEMBER = "ACT_LOGIN_MEMBER";
export const ACT_LOGIN_ACCOUNT = "ACT_LOGIN_ACCOUNT";
export const ACT_LOGOUT = "ACT_LOGOUT";
export const ACT_GET_ACCOUNT_INFO = "ACT_GET_ACCOUNT_INFO";
export const ACT_GET_MANAGE_INFO = "ACT_GET_MANAGE_INFO";
export const ACT_FORCE_FRESH_AUTH = "ACT_FORCE_FRESH_AUTH";

// mutations
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_TOKEN = "SET_TOKEN";
export const SET_IS_AUTH = "SET_IS_AUTH";
export const SET_REFRESH_AUTH = "SET_REFRESH_AUTH";
export const SET_ACCOUNT_INFO = "SET_ACCOUNT_INFO";
export const SET_EXPIRED_AT = "SET_EXPIRED_AT";
export const SET_PERMISSION_MENU = "SET_PERMISSION_MENU";
export const SET_IS_SCREEN_MANAGE = "SET_IS_SCREEN_MANAGE";
export const SET_STATUS_ACCOUNT = "SET_STATUS_ACCOUNT";
export const SET_PERMISSION_TREE_MENU = "SET_PERMISSION_TREE_MENU";

// getters
export const GET_USER_NAME = "GET_USER_NAME";
export const GET_AUTH_TOKEN = "GET_AUTH_TOKEN";
export const GET_ACCOUNT_IS_AUTH = "GET_ACCOUNT_IS_AUTH";
export const GET_ACCOUNT_ID = "GET_ACCOUNT_ID";
export const GET_PERMISSION_MENU = "GET_PERMISSION_MENU";
export const GET_ACCOUNT_CODE = "GET_ACCOUNT_CODE";
export const GET_IS_SCREEN_MANAGE = "GET_IS_SCREEN_MANAGE";
export const GET_SYSTEM_ADMIN_FLAG = "GET_SYSTEM_ADMIN_FLAG";
export const GET_PERMISSION_TREE_MENU = "GET_PERMISSION_TREE_MENU";

export const GET_EVENT_MENU = "GET_EVENT_MENU";
