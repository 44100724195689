// import domain from '@/middlewares/manage/domain';
import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "mail-templates",
  name: "MailTemplateManage",
  component: () =>
    import(
      /* webpackChunkName: "mail-template-manage-layout-view" */ "@/views/Manage/MailTemplate/LayoutView.vue"
    ),
  redirect: { name: "MailTemplateSearch" },
  children: [
    {
      path: "", // /manage/mail-template
      name: "MailTemplateSearch",
      component: () =>
        import(
          /* webpackChunkName: "mail-template-manage-searching-view" */ "@/views/Manage/MailTemplate/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/mail-template/create
      name: "MailTemplateCreate",
      component: () =>
        import(
          /* webpackChunkName: "mail-template-manage-create-view" */ "@/views/Manage/MailTemplate/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/mail-template/edit/1
      name: "MailTemplateEdit",
      component: () =>
        import(
          /* webpackChunkName: "mail-template-manage-edit-view" */ "@/views/Manage/MailTemplate/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
