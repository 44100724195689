import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "roles",
  name: "RoleManage",
  component: () =>
    import(
      /* webpackChunkName: "role-manage-layout-view" */ "@/views/Manage/Role/LayoutView.vue"
    ),
  redirect: { name: "RoleSearch" },
  children: [
    {
      path: "",
      name: "RoleSearch",
      component: () =>
        import(
          /* webpackChunkName: "role-manage-searching-view" */ "@/views/Manage/Role/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/role/create
      name: "RoleCreate",
      component: () =>
        import(
          /* webpackChunkName: "role-manage-create-view" */ "@/views/Manage/Role/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/role/edit/1
      name: "RoleEdit",
      component: () =>
        import(
          /* webpackChunkName: "role-manage-edit-view" */ "@/views/Manage/Role/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
