// getters
export const GET_PROGRESSING_TRACKING_ITEM = "GET_PROGRESSING_TRACKING_ITEM";
export const GET_LIST_IMPORT_PROGRESS_MANAGEMENT_STACK =
  "GET_LIST_IMPORT_PROGRESS_MANAGEMENT_STACK";
export const GET_ALL_PROGRESS_DONE = "GET_ALL_PROGRESS_DONE";
// mutations
export const SET_ADD_PROGRESS_IMPORT_TO_STACK =
  "SET_ADD_PROGRESS_IMPORT_TO_STACK";
export const SET_PROGRESS_IMPORT = "SET_PROGRESS_IMPORT";
export const SET_FILTER_TASK_IMPORT = "SET_FILTER_TASK_IMPORT";
