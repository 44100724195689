// ACTION
export const ACT_CHANGE_LANGUAGE = "ACT_CHANGE_LANGUAGE";
export const ACT_CHANGE_TOKEN = "ACT_CHANGE_TOKEN";

// SET mutations
export const SET_SOMETHING = "SET_SOMETHING";
export const SET_INSTANCE_SEARCHING = "SET_INSTANCE_SEARCHING";
export const SET_VALIDATION = "SET_VALIDATION";
export const SET_TOKEN = "SET_TOKEN";

// GETS
export const GET_SOMETHING = "GET_SOMETHING";
export const GET_INSTANCE_SEARCHING = "GET_INSTANCE_SEARCHING";
export const GET_PATH_SEARCH = "GET_PATH_SEARCH";
export const GET_VALIDATION = "GET_VALIDATION";
export const GET_TOKEN = "GET_TOKEN";
