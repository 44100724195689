import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";
import { GET_EVENT_MENU } from "@/store/modules/AuthManage/types";
import Store from "@/store";

/* eslint-disable */
export default {
  path: 'events/:url/:event_cms_code/:event_cms_id',
  name: 'EventManage',
  component: () => import(/* webpackChunkName: "event-manage-layout-view" */'@/views/Manage/Event/LayoutView.vue'),
  beforeEnter: (to, from, next) => {
    // ...
    const permissionMenu = Store.getters[`AuthManage/${GET_EVENT_MENU}`];
    let find = permissionMenu.find(item => to.path.includes(item.url));
    to.meta.valid_event_url = find ? true : false;
    next()
  },
  redirect: { name: 'EventSearch' },
  children: [
    {
      path: '', // /manage/event
      name: 'EventSearch',
      component: () => import(/* webpackChunkName: "event-manage-searching-view" */'@/views/Manage/Event/FilterSearching.vue'),
      meta: {
        middleware: [authManage, selectOrganization]
      }
    },
    {
      path: 'create', // /manage/event/create
      name: 'EventCreate',
      component: () => import(/* webpackChunkName: "event-manage-create-view" */'@/views/Manage/Event/Create.vue'),
      meta: {
        middleware: [authManage, selectOrganization]
      }
    },
    {
      path: 'edit/:id', // /manage/event/edit/1
      name: 'EventEdit',
      component: () => import(/* webpackChunkName: "event-manage-edit-view" */'@/views/Manage/Event/Edit.vue'),
      meta: {
        middleware: [authManage, selectOrganization]
      }
    },
    {
      path: 'check-in', // /manage/event/check-in
      name: 'EventCheckIn',
      component: () => import(/* webpackChunkName: "event-manage-check-in-view" */'@/views/Manage/Event/Checkin'),
      meta: {
        middleware: [authManage, selectOrganization]
      }
    }
  ]
}