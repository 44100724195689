<template>
  <div class="http401__container">
    <!-- <button class="http401__container-back__btn" @click="back">
      Go back
    </button> -->
    <b-row>
      <b-col>
        <h1 class="text-jumbo text-ginormous">Oops!</h1>
        <h3 class="text-danger font-weight-bold">
          {{ $t("validation.access_denied") }}
        </h3>
      </b-col>
      <b-col>
        <img
          class="http401__container-image__present"
          :src="errGif"
          width="313"
          height="428"
          alt="Girl has dropped her ice cream."
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import errGif from "@/assets/images/errors-views/401/401.gif";

export default {
  name: "PageUnauthorized",
  data() {
    return {
      errGif: errGif + "?" + +new Date(),
      ewizardClap:
        "https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646",
      dialogVisible: false,
    };
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({ path: "/" });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.http401__container {
  width: 800px;
  max-width: 100%;
  margin: 100px auto;
  &-back__btn {
    background: #008489;
    color: #fff;
    border: none !important;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    border-color: #dcdfe6;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 400;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
  }
  .pan-gif {
    margin: 0 auto;
    display: block;
  }
  .pan-img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .text-jumbo {
    font-size: 60px;
    font-weight: 700;
    color: #484848;
  }
  &-image__present {
    mix-blend-mode: multiply;
  }
}
</style>
