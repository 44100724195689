import {
  bffServiceInstance,
  bffServiceInstanceClient,
} from "@/plugins/api_service/services";

export default {
  getManageTemporaryUrl: ({ file_url }) =>
    bffServiceInstance.post(
      "/management/storages/temporary-url",
      {},
      { file_url }
    ),
  getClientTemporaryUrl: ({ file_url }) =>
    bffServiceInstanceClient.post(
      "/member/storages/temporary-url",
      {},
      { file_url }
    ),
};
