import { GET_PROGRESSING_TRACKING_ITEM } from "@/store/modules/ImportProgressing/types";
import { computed } from "vue";
import Store from "@/store";
import i18n from "@/plugins/i18n";

export default function ({ task_name }) {
  //
  const trackingProgressInstance = computed(() => {
    return Store.getters[`ImportProgressing/${GET_PROGRESSING_TRACKING_ITEM}`](
      task_name
    );
  });

  const isError = computed(() => trackingProgressInstance.value?.is_error);
  const inProgress = computed(
    () => trackingProgressInstance.value?.is_progressing
  );
  const isCompleted = computed(
    () => trackingProgressInstance.value?.is_completed
  );
  const currentProgressPercent = computed(
    () => trackingProgressInstance.value?.progress + "%"
  );
  const errorMsg = computed(() => trackingProgressInstance.value?.error_msg);

  const currentStep = computed(() => {
    const modeStep = {
      validation: i18n.t("import-csv.validation"),
      import: i18n.t("import-csv.import"),
      done: i18n.t("import-csv.done"),
    };
    return modeStep[trackingProgressInstance.value.step] || "";
  });
  const positionSuccessRecord = computed(
    () =>
      trackingProgressInstance.value &&
      trackingProgressInstance.value.current_position_success
  );
  // color progress bar
  const bgColorProgressBar = computed(() => {
    if (isError.value) {
      return "#dc3545";
    }
    if (isCompleted.value) {
      return "#28a745";
    }

    if (inProgress.value) {
      return "#006fe6";
    }
    return "";
  });

  const data = computed(() => trackingProgressInstance.value?.data);
  const fields = computed(() => trackingProgressInstance.value?.fields);
  const totalInsertRecords = computed(
    () => trackingProgressInstance.value?.total_insert_records
  );
  const totalUpdateRecords = computed(
    () => trackingProgressInstance.value?.total_update_records
  );

  const validation = computed(() => {
    if (trackingProgressInstance.value) {
      return trackingProgressInstance.value.validation_msg;
    }
    return null;
  });

  const isHavingValidation = computed(() => {
    return validation.value && Object.keys(validation.value).length;
  });

  const backRoutePage = computed(() => {
    if (trackingProgressInstance.value) {
      return trackingProgressInstance.value.back_route;
    }
    return null;
  });

  const remove = () => {
    if (trackingProgressInstance.value) {
      trackingProgressInstance.value.remove();
    }
  };

  return {
    trackingProgressInstance,
    bgColorProgressBar,
    isError,
    currentStep,
    inProgress,
    isCompleted,
    currentProgressPercent,
    errorMsg,
    data,
    fields,
    positionSuccessRecord,
    remove,
    validation,
    totalInsertRecords,
    totalUpdateRecords,
    backRoutePage,
    isHavingValidation,
  };
}
