import {
  SET_TOKEN,
  SET_ACCOUNT_INFO,
  SET_EXPIRED_AT,
  SET_STATUS_ACCOUNT,
  SET_REFRESH_AUTH,
} from "@/store/modules/AuthManage/types";

export default [
  `AuthManage/${SET_TOKEN}`,
  `AuthManage/${SET_ACCOUNT_INFO}`,
  `AuthManage/${SET_EXPIRED_AT}`,
  `AuthManage/${SET_STATUS_ACCOUNT}`,
  `AuthManage/${SET_REFRESH_AUTH}`,
];
