import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "organizations",
  name: "OrganizationManage",
  component: () =>
    import(
      /* webpackChunkName: "manage-organization-layout-view" */ "@/views/Organization/LayoutView.vue"
    ),
  redirect: { name: "OrganizationSearch" },
  children: [
    {
      path: "",
      name: "OrganizationSearch",
      component: () =>
        import(
          /* webpackChunkName: "manage-organization-searching-view" */ "@/views/Organization/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create",
      name: "OrganizationCreate",
      component: () =>
        import(
          /* webpackChunkName: "manage-organization-create-view" */ "@/views/Organization/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id",
      name: "OrganizationEdit",
      component: () =>
        import(
          /* webpackChunkName: "manage-organization-edit-view" */ "@/views/Organization/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
