import {
  GET_USER_NAME,
  GET_AUTH_TOKEN,
  GET_ACCOUNT_IS_AUTH,
  GET_ACCOUNT_ID,
  GET_PERMISSION_MENU,
  GET_ACCOUNT_CODE,
  GET_IS_SCREEN_MANAGE,
  GET_SYSTEM_ADMIN_FLAG,
  GET_EVENT_MENU,
  GET_PERMISSION_TREE_MENU,
} from "./types";
import moment from "moment";
import _ from "lodash";
import { menuManageType } from "@/constants/commons";

export default {
  [GET_USER_NAME](state) {
    return state.profile.name;
  },
  [GET_AUTH_TOKEN](state) {
    return state.token;
  },
  [GET_ACCOUNT_IS_AUTH]: (state) => {
    let statusLogin = state.accountLogin.isAuth,
      token = state.token,
      validExpiredAt = true;

    let pointNowTime = moment(),
      expiredAt = moment(parseInt(state.expired_at)).subtract(5, "minutes");

    if (!state.expired_at || pointNowTime.isSameOrAfter(expiredAt)) {
      validExpiredAt = false;
    }

    if (token && statusLogin == "success" && validExpiredAt) {
      return true;
    }
    return false;
  },
  [GET_ACCOUNT_ID]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.accountLogin.info,
        "account_id"
      )
        ? state.accountLogin.info.account_id
        : "";
    }
    return "";
  },
  [GET_ACCOUNT_CODE]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.accountLogin.info,
        "account_code"
      )
        ? state.accountLogin.info.account_code
        : "";
    }
    return "";
  },
  [GET_PERMISSION_MENU]: (state) => state.permission_menu,
  [GET_IS_SCREEN_MANAGE]: (state) => state.is_screen_manage,
  [GET_SYSTEM_ADMIN_FLAG]: (state) => {
    if (!_.isEmpty(state.accountLogin.info)) {
      return Object.prototype.hasOwnProperty.call(
        state.accountLogin.info,
        "system_admin_flag"
      )
        ? state.accountLogin.info.system_admin_flag
        : 0;
    }
    return 0;
  },

  [GET_EVENT_MENU]: (state) => {
    return state.permission_menu.filter(
      (item) => item.type == menuManageType.event.value
    );
  },
  [GET_PERMISSION_TREE_MENU]: (state) => state.permission_tree_menu,
};
