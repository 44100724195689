import {
  SET_SEARCH_FORM,
  SET_SEARCH_FIELDS,
  SET_RESULT_FIELDS,
  SET_ATTR_FORM_SEARCH,
  SET_BASIC_INFO,
  SET_EXTEND_INFO,
  SET_DETAIL_EMPTY_INFO,
  SET_PROCESS_EXPORT_CSV_FILE,
  SET_REFRESH_IMPORT_CSV_FILE,
  SET_REFRESH_MEMBER_INFO_DATA,
} from "./types";

export default {
  [SET_SEARCH_FORM]: (state, payload) => {
    state.search.form = payload;
  },

  [SET_SEARCH_FIELDS]: (state, payload) => {
    state.search.search_fields = payload;
  },

  [SET_RESULT_FIELDS]: (state, payload) => {
    state.search.result_fields = payload;
  },

  [SET_ATTR_FORM_SEARCH]: (state, payload) => {
    let { key, value } = payload;
    state.search.form[key] = value;
  },

  [SET_BASIC_INFO]: (state, payload) => {
    state.detail.basic_info = payload;
  },

  [SET_EXTEND_INFO]: (state, payload) => {
    state.detail.extend_info = payload;
  },

  [SET_DETAIL_EMPTY_INFO]: (state) => {
    state.detail = {
      basic_info: {},
      extend_info: {},
    };
  },
  [SET_PROCESS_EXPORT_CSV_FILE]: (state, payload) => {
    state.exportCsv = {
      ...state.exportCsv,
      ...payload,
    };
  },

  [SET_REFRESH_IMPORT_CSV_FILE]: (state) => {
    state.importCsv = {
      file: null,
      loading: false,
      csv_content: {
        data: [],
        meta: {},
        errors: [],
      },
      validation: {},
      validation_source: null,
      error: false,
      success: false,
      total_insert_members: 0,
      total_updated_members: 0,
    };
  },
  [SET_REFRESH_MEMBER_INFO_DATA]: (state) => {
    state.detail.basic_info = {};
    state.detail.extend_info = {};
  },
};
